// src/components/LeadSources.js

import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import DataTable from "../../components/common/DataTable";
import { getLeadSource } from "../../services/CommonServices";
import CustomMaster from "../common/CustomMaster";

const title = "Lead Sources";

const LeadSources = () => {
    const [leadSources, setLeadSources] = useState([]);
    const columns = useMemo(
        () => [
            { accessorKey: "id", header: "SN.", size: 50, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: 'title', header: 'Lead Source', tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 200
            },
            {
                accessorKey: 'shortName', header: 'Short Name', tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 150
            },
        ],
        []
    );


    const fetchLeadSources = async () => {
        const leadSourcesData = await getLeadSource();
        setLeadSources(leadSourcesData);
    };

    useEffect(() => {
        fetchLeadSources();
    }, []);
    const defaultView = 'sourceslead';
    const mainContent = (
        <Box sx={{ padding: "10px" }}>
            <DataTable
                title={title}
                columns={columns}
                data={leadSources}
            />
        </Box>
    );

    return (
        <>
            <CustomMaster
                defaultView={defaultView}
                mainContent={mainContent} />
        </>
    );
};

export default LeadSources;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { GET_FREE_DAYS_REPORT } from "../../services/apiRoutes";
import CustomReport from "../common/CustomReport";

const title = "Free Days Report";

const FreeDaysReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'etaDate', header: 'Date', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'shipmentId', header: 'Shipment ID', size: 50, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'clientCompanyName', header: 'Supplier Name', size: 50, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'country', header: 'Country', size: 50, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'numberOfContainers', header: 'No. of Containers', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'freeDaysAtDestination', header: 'Free days', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'dueDate', header: 'Due Date', size: 50, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton >
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_FREE_DAYS_REPORT });
            if (response.data.success && response.data.shippingBooking) {
                const shippingBookingData = response.data.shippingBooking.map((booking, index) => {
                    const etaDate = new Date(booking.etaDate);
                    etaDate.setDate(etaDate.getDate() + 14);
                    const dueDate = etaDate.toISOString().split('T')[0];

                    return {
                        index: (index + 1).toString().padStart(2, '0'),
                        shippingBookingId: booking.shippingBookingId || '',
                        freeDaysAtDestination: booking.freeDaysAtDestination || '',
                        numberOfContainers: booking.numberOfContainers || '',
                        portOfLoading: booking.portOfLoading || '',
                        portOfDestination: booking.portOfDestination || '',
                        shipmentId: booking.shipmentId || '',
                        etaDate: booking.etaDate || '',
                        etdDate: booking.etdDate || '',
                        destinationCharges: booking.destinationCharges || '',
                        shippingLineCharges: booking.shippingLineCharges || '',
                        clientCompanyName: booking.clientCompany?.clientCompanyName || '',
                        country: booking.clientCompany?.mailingCountry?.name || '',
                        blCharges: booking.blCharges || '',
                        cifFobName: booking.cifFob?.cifFobName || '',
                        shippingLineName: booking.shippingLines?.shippingLineName || '',
                        agencyName: booking.freightForwardAgency?.agencyName || '',
                        chaName: booking.customClearingAgency?.chaName || '',
                        dueDate: dueDate, // Add the calculated dueDate
                    };
                });
                setData(shippingBookingData);
            } else {
                setError('No shipping bookings found');
            }
        } catch (error) {
            setError('Error fetching shipping bookings');
            console.error('Error fetching shipping bookings', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData()
    }, [token])
    const mainContent = (
        <DataTable
            title={title}
            columns={columns}
            data={data}
        />
    );
    const defaultView = 'freedaysreport';
    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>
    );
};
export default FreeDaysReport;

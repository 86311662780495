import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CustomModal from '../../../components/common/CustomModal';
import CreateExpShippingMark from '../CreateForm/CreateExpShippingMark';
import { GET_PRODUCTS_LIST, LIST_EXPORT_SHIPPING_MARKS, LIST_IMPORT_SHIPPING_MARKS } from '../../../services/apiRoutes';
import { ExportMenu } from '../../../constants/DetailsPage';
import { genericGet } from '../../../services/apiServices';
import { Main } from '../../../constants/DetailsMain';
import MessageBox from '../../common/CustomMessageBox';
import { genericPost } from '../../../services/authServices';
import { getCommanExportShipmentId } from '../../../services/CommonServices';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import UpdateExpShippingMark from '../UpdateForm/UpdateExpShippingMark';
import ShippingMarkView from '../ViewDetails/ShippingMarkView';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';

const ShippingMark = () => {
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [products, setProducts] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [updateModal, setUpdateModal] = useState(false);
    const [shippingMarkId, setShippingMarkId] = useState('');
    const [activeTab, setActiveTab] = useState('Shipping Mark');
    const navigate = useNavigate();
    const { exportShipmentId } = useParams();
    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);



    let customerId, cifFob;
    const hasFetched = useRef(false);
    useEffect(() => {
        if (shipmentDetails) {
            customerId = shipmentDetails.customerId;
            cifFob = shipmentDetails.cifFob.cifFobName;
            fetchData();
            fetchProductsList();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateModal, shippingMarkId, token, modal]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: customerId,
                commanShipmentId: getCommanExportShipmentId,
                shipmentId: shipmentId,

            };
            const response = await genericPost(LIST_IMPORT_SHIPPING_MARKS, payload, {
            });
            if (response.data.success && response.data.shippingmark) {
                const shippingMarkData = response.data.shippingmark.map((mark, index) => ({
                    shippingMarkId: mark.shippingMarkId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    companyId: mark.companyId || '',
                    BatchNo: mark.batchNo || '',
                    ProductId: mark.productsList.productName || '',
                    ShippingMark: mark.shippingMark || '',
                    createdAt: mark.createdAt || '',
                    updatedAt: mark.updatedAt || '',
                }));
                setData(shippingMarkData);
            } else {
                setError('No shipping marks found');
            }
        } catch (error) {
            setError('Error fetching shipping marks');
            console.error('Error fetching shipping marks', error);
        } finally {
            setLoading(false);
        }
    };




    const fetchProductsList = async () => {
        const payload = {
            clientCompanyId: customerId,
        };

        try {
            const response = await genericPost(GET_PRODUCTS_LIST, payload, {

            });

            if (response.status === 200 && response.data.success) {
                const productData = response.data.productsList.map(product => ({
                    id: product.productsId,
                    title: product.productName
                }));
                console.log(productData);
                setProducts(productData);
            } else {
                console.error('Failed to fetch products list:', response.data);
            }
        } catch (error) {
            console.error('Error fetching products list:', error);
        }
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpenModal = () => {
        setModal(true);
    };

    const handleCloseModal = () => {
        setModal(false);
    };

    const handleViewClick = (id) => {
        setShippingMarkId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setShippingMarkId('');
    };

    const handleUpdateClick = (call) => {
        handleOpenUpdate(call);
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleBackClick = () => {
        navigate("/app/export/shipement");
    };

    const handleOpenUpdate = (shippingMarkId) => {
        setShippingMarkId(shippingMarkId); // Update state here
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setShippingMarkId(''); // Reset state here
    };

    if (!shipmentDetails) {
        return <p>   <CustomLoader open={shipmentLoading} /> </p>;
    }


    const companyName = shipmentDetails.customer.clientCompanyName;
    const phoneNumber = shipmentDetails.customer.mobile;
    const customerName = shipmentDetails.customerId;
    const shipmentId = shipmentDetails.shipmentId;

    const initial = shipmentDetails.customer.clientCompanyName ? shipmentDetails.customer.clientCompanyName.charAt(0).toUpperCase() : '';

    const ShippingMarkCardColumns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        { accessor: 'ProductId', label: 'Product Name', id: 'ProductId' },
        { accessor: 'ShippingMark', label: 'Shipping Mark', id: 'ShippingMark' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.shippingMarkId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.shippingMarkId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.shippingMarkId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <div>
            {/* <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={ExportMenu(exportShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />
            <Main open={open}> */}
            <CustomLoader open={loading} />

            <DetailsPageCard
                Icon={BookmarkAddedOutlinedIcon}
                columns={ShippingMarkCardColumns}
                title={'Shipping Mark'}
                buttonTitle={'Create Shipping Mark'}
                handleOpen={handleOpenModal}
                data={data}
                loading={loading}
                error={error}
            />
            <CustomModal open={modal} handleClose={handleCloseModal}>
                <CreateExpShippingMark
                    fetchData={fetchData}
                    productsList={products}
                    commanShipmentId={getCommanExportShipmentId}
                    clientCompanyId={customerName}
                    importShipmentId={exportShipmentId}
                    shipmentId={shipmentId}
                    setMessage={setMessage}
                    handleClose={handleCloseModal}
                />
            </CustomModal>

            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateExpShippingMark
                    handleClose={handleCloseUpdate}
                    shippingMarkId={shippingMarkId}
                    productsList={products}
                    fetchData={fetchData}
                    setMessage={setMessage}

                />
            </CustomModal>
            <CustomModal
                modalStyles={{
                    maxHeight: '30vh',
                    top: "10%",
                }}
                open={viewModal} handleClose={handleCloseView}>
                <ShippingMarkView
                    handleClose={handleCloseView}
                    shippingMarkId={shippingMarkId}
                    productsList={products}
                    fetchData={fetchData}
                    setMessage={setMessage}
                />
            </CustomModal>

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            {/* </Main> */}
        </div>
    );
};

export default ShippingMark;

// src/components/Modules.js

import React, { useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import DataTable from "../../components/common/DataTable";
import { getModules } from "../../services/CommonServices";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CustomMaster from "../common/CustomMaster";


const title = "Modules";

const Modules = () => {
    const [modules, setModules] = useState([]);
    const columns = useMemo(
        () => [
            { accessorKey: 'id', header: 'SN', tableStyle: { textAlign: "center" }, size: 50 },
            {
                accessorKey: 'title', header: 'Module', tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 200
            },
            {
                accessorKey: 'shortName', header: 'Short Name', tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 150
            },
            {
                accessorKey: "Status",
                header: "Status",
                size: 50,
                Cell: ({ row }) => {
                    const [isActive, setIsActive] = useState(true);
                    const handleToggle = () => {
                        setIsActive((prevState) => !prevState);
                    };
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                            <IconButton onClick={handleToggle} color={isActive ? "primary" : "default"}>
                                {isActive ? 'Active' : 'Inactive'}
                            </IconButton>
                        </div>
                    );
                },
            },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                            <IconButton>
                                <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                            </IconButton>
                        </div>
                    </>
                ),
            },
        ],
        []
    );

    const fetchModules = async () => {
        const modulesData = await getModules();
        setModules(modulesData);
    };

    useEffect(() => {
        fetchModules();
    }, []);
    const defaultView = 'modules';
    const mainContent = (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={modules}
                />
            </Box>
        </>
    );

    return (
        <CustomMaster
            defaultView={defaultView}
            mainContent={mainContent} />
    );
};

export default Modules;

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Table,
  IconButton,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Card,
  Typography,
  Divider,
  Button,

} from "@mui/material";
import CustomModal from "../../common/CustomModal";
import CreateCompanyModules from "./CreateCompanyModules";
import MessageBox from "../../common/CustomMessageBox";
import { GET_PROFILE, GET_PERMISSIONS } from "../../../services/apiRoutes";
import { genericGet } from "../../../services/apiServices";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomMaster from "../../common/CustomMaster";

const Profiles = () => {
  const [open, setOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [formData, setFormData] = useState({
    profile_name: "",
    modules: [
      {
        module_name: "Report",
        permission_create: true,
        permission_update: false,
        permission_view: true,
        permission_delete: false,
      },
      {
        module_name: "Master",
        permission_create: false,
        permission_update: true,
        permission_view: false,
        permission_delete: true,
      },
    ],
  });
  const [viewData, setViewData] = useState([])
  const [profile, setProfile] = useState([]);

  const [message, setMessage] = useState({
    open: false,
    text: "",
    type: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form data:", formData);
    // You can handle form submission logic here
  };

  const handleClose = () => {
    try {
      setOpen(false);
    } catch (error) {
      console.error("Error in handleClose:", error);
    }
  };

  const getProfiles = async () => {
    try {
      const response = await genericGet({ url: GET_PROFILE });
      if (response.status == 200) {
        setProfile(response.data.companyProfiles);
      }
    } catch (error) {
    } finally {
    }
  };

  const handleViewOpen = (id) => {
    getPermissionsByProfileId(id)
    setViewModal(true)
  }
  const getPermissionsByProfileId = async (id) => {
    try {
      const response = await genericGet({ url: `${GET_PERMISSIONS}/${id}` });
      if (response.status == 200) {
        setViewData(response.data.profile);
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getProfiles();
  }, []);
  console.log(viewData)
  const defaultView = 'profiles';
  const mainContent = (
    <>
      <Grid container spacing={1} style={{ position: "sticky" }}>
        <Grid item xs={4} md={6} sx={{ my: 1 }}>
          <Typography
            sx={{ fontWeight: 600 }}
            style={{ color: "#212529", fontSize: "18px" }}
          >
            Profiles
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          md={6}
          sx={{ my: 1, display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            size="small"
            variant="contained"
            onClick={() => setOpen(true)}
          >
            Add Profile
          </Button>
        </Grid>
      </Grid>
      <Divider />

      <Box sx={{ pt: 2 }}>
        <Box sx={{ overflowX: "auto", overflowY: "auto" }}>
          <Table sx={{ width: "100%" }}>
            <TableHead>
              <TableRow sx={{ height: "40px", backgroundColor: "#e7f0f7" }}>
                <TableCell rowSpan={2}>Profile Name</TableCell>
                <TableCell rowSpan={2}>Profile Description</TableCell>
                <TableCell rowSpan={2}>Created By</TableCell>
                <TableCell rowSpan={2}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {profile &&
                profile.map((v) => (
                  <TableRow sx={{ borderBottom: "1px solid #e0e0e0" }}>
                    <TableCell>{v?.profileName}</TableCell>
                    <TableCell>{v?.profileDescription}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleViewOpen(v.profileId)}>
                        <VisibilityIcon style={{ color: "#059212" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Box>

      <CustomModal
        open={open}
        handleClose={handleClose}
        modalStyles={{
          maxHeight: "90vh",
          top: "5%",
        }}
      >
        <Box sx={{ p: 2 }}>
          <CreateCompanyModules
            handleClose={handleClose}
            setMessage={setMessage}
          />
        </Box>
      </CustomModal>
      <MessageBox
        open={message.open}
        onClose={() => setMessage({ ...message, open: false })}
        message={message.text}
        type={message.type}
      />

      <CustomModal
        open={viewModal}
        handleClose={() => setViewModal(false)}
        modalStyles={{
          maxHeight: "90vh",
          top: "5%",
        }}
      >

        <Grid container spacing={1} my={1}>
          <Grid item xs={12} sm={6}>
            {viewData && viewData[0]?.profile?.profileName}
          </Grid>


        </Grid>
        <Divider sx={{ my: 2 }} />

        <Box sx={{ pt: 2 }}>
          <Box sx={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow sx={{ height: '40px', backgroundColor: '#e7f0f7' }}>
                  <TableCell sx={{ ...styles.tableCell, width: '200px' }} rowSpan={2}>Module Name</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Create</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Update</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>View</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewData.map((module, index) => (
                  <TableRow key={index} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                    <TableCell sx={styles.tableCell}>{module.companyModule?.moduleName}</TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="create"
                        checked={module.creates == 1 ? true : false}
                        disabled
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="update"
                        checked={module.updates == 1 ? true : false}
                        disabled
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="view"
                        checked={module.views == 1 ? true : false}
                        disabled
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="delete"
                        checked={module.deletes == 1 ? true : false}
                        disabled
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </CustomModal>
    </>
  );

  return (
    <>
      <CustomMaster
        defaultView={defaultView}
        mainContent={mainContent} />

    </>
  );
};

export default Profiles;
const styles = {
  tableCell: {
    border: '1px solid #dee2e6',
    color: '#212529',
    fontWeight: 600,
    fontSize: '14px',
    textAlign: 'center',
    p: '4px',
  },
};
import React, { useEffect, useState, useContext } from "react";
import {
    Box,
    List,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Avatar,
    Divider,
    Stack,
    Button,
    Grid,
    Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import CustomAutocomplete from "../components/common/CustomAutoComplete";
import { genericGet, genericPut } from "../services/apiServices";
import { GET_COMPANY, SET_DEFAULT_COMPANY } from "../services/apiRoutes";
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from "react-router-dom";

let userData = JSON.parse(sessionStorage.getItem('userData'))
const UserProfile = ({ anchor, toggleDrawer, userDispatch, props,orgData }) => {
    const [formData, setFormData] = useState({ countries: null });
    const [getAllCompany, setGetAllCompany] = useState([]);
    const [loading, setLoading] = useState(false)
    const { login } = useContext(AuthContext)

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSetDefault = async (companyId) => {
        // console.log(`Set default for company ID: ${companyId}`);
        setLoading(true)
        try {
            const response = await genericPut(`${SET_DEFAULT_COMPANY}/${companyId}`, {});
            if (response.data.accessToken) {
                sessionStorage.setItem('accessToken', response.data.accessToken)
                login(response.data.accessToken)
                getCompanyList();
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            console.log("error", error);
            setLoading(false)

        }
    };

    const getCompanyList = async () => {
        try {
            const response = await genericGet({ url: GET_COMPANY });
            if (response.data.companies) {
                let companyData = response.data.companies.map((v) => ({
                    id: v.companyId,
                    title: v.companyName,
                    isDefault: v.isDefault,
                }));
                setGetAllCompany(companyData);
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    const navigate = useNavigate();
console.log(orgData)
    useEffect(() => {
        getCompanyList();
    }, []);

    return (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 350 }}
            role="presentation"
        >
            <List style={{ backgroundColor: "#065dff", color: "#fff" }}>
                <div
                    style={{
                        margin: "auto",
                        padding: "auto",
                        textAlign: "center",
                        paddingTop: "5%",
                    }}
                >
                    <Avatar
                        style={{ margin: "auto", padding: "auto", textAlign: "center" }}
                        alt="User Avatar"
                        src="https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?size=626&ext=jpg"
                        sx={{ width: 60, height: 60 }}
                    />
                    <h1
                        className="profile-name"
                        style={{
                            fontSize: "15px",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "200",
                            textTransform:'capitalize'
                        }}
                    >
                        {userData?.authUser?.firstName} {userData?.authUser?.lastName}
                    </h1>
                    <p
                        className="profile-email"
                        style={{
                            fontSize: "13px",
                            fontFamily: "Poppins, sans-serif",
                            // paddingBottom: "10px",
                            // paddingTop: "10px",
                            margin:0
                        }}
                    >
                     {/* {orgData?.}   */}
                     {userData?.role?.roleName}
                    </p>
                    <p
                        className="profile-email"
                        style={{ fontSize: "13px", fontFamily: "Poppins, sans-serif" }}
                    >
                        Email - {userData?.authUser?.email}
                    </p>
                </div>
                <Box sx={{ pl: 3, pr: 3 }}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel></InputLabel>
                        <Select
                            name="company"
                            value={getAllCompany.find((v) => v.isDefault)?.id || ''}
                            defaultValue={getAllCompany.find((v) => v.isDefault)?.id || ''}
                            onChange={(e) => handleSetDefault(e.target.value)}
                            sx={{
                                backgroundColor: "#FFF",
                                padding: '10px',
                                // Targeting the input inside the Select
                                '& .MuiOutlinedInput-input': {
                                    padding: '0px',
                                }
                            }}
                        >
                            {getAllCompany.map((company) => {
                                // console.log(getAllCompany);
                                return (
                                    <MenuItem key={company.id} value={company.id}>
                                        {company.title}
                                    </MenuItem>

                                )
                            })}
                        </Select>
                    </FormControl>{" "}
                </Box>
            </List>

            <Divider />

            <Stack direction="row" spacing={2} style={{ paddingTop: "3px" }}>
                <Button
                    component={Link}
                    style={{
                        textTransform: "capitalize",
                        margin: "auto",
                        padding: "auto",
                        textAlign: "center",
                        display: "flex",
                        fontWeight: "600",
                        fontFamily: "sans-serif",
                        fontSize: "12px",
                    }}
                    variant="outlined"
                    startIcon={<PersonIcon />}
                >
                    My Account
                </Button>

                <Button
                    style={{
                        fontSize: "12px",
                        margin: "auto",
                        padding: "auto",
                        textAlign: "center",
                        display: "flex",
                        fontWeight: "600",
                        fontFamily: "sans-serif",
                        backgroundColor: "#065DFF",
                        textTransform: "capitalize",
                    }}
                    variant="contained"
                    endIcon={<LogoutIcon />}
                    onClick={() => {
                        navigate("/login");
                        sessionStorage.clear();
                    }}
                >
                    Sign Out
                </Button>
            </Stack>

            <hr />

            <Grid container >
                <Grid item xs={9} justifyContent={'center'}>
                    <h3 style={{
                            fontSize: "15px",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "200",
                            textTransform:'capitalize',
                            margin:'0 0 10px 20px'
                        }}>
                        My Subscription 
                    </h3>
                   
                   
                    <h6 style={{
                            fontSize: "15px",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "200",
                            textTransform:'capitalize',
                            margin:'0 0 0 20px'
                        }}>
                            {orgData?.startDate} to {orgData?.endDate}
                    </h6>
                    <h6 style={{
                            fontSize: "12px",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "200",
                            textTransform:'capitalize',
                            margin:'0 0 0 20px'
                        }}>
                     ( {orgData?.isSubscription ? `Your subscription expire in ${orgData?.dayLefts} days` :`Your free trial expire in ${orgData?.dayLefts } days` })
                    </h6>
                </Grid>
               
               
            </Grid>

            {/* <div
                style={{ display: "block", marginTop: "-60px", paddingLeft: "10px" }}
            >
                
                <ul
                    style={{
                        listStyleType: "none",
                        marginLeft: "-40px",
                        marginTop: "-30px",
                    }}
                >
                    <li
                        style={{
                            color: "#555555",
                            fontFamily: "sans-serif",
                            fontWeight: "100",
                            fontSize: "12px",
                            paddingTop: "4px",
                        }}
                    >
                        <span>Organization ID</span>: <span>R20230401157</span>
                    </li>
                    <li
                        style={{
                            color: "#555555",
                            fontFamily: "sans-serif",
                            fontWeight: "100",
                            fontSize: "12px",
                            paddingTop: "4px",
                        }}
                    >
                        <span>Current Period</span>:{" "}
                        <span>
                            01/04/2023 <span>To </span> 31/03/2024
                        </span>
                    </li>
                    <li
                        style={{
                            color: "#555555",
                            fontFamily: "sans-serif",
                            fontWeight: "100",
                            fontSize: "12px",
                            paddingTop: "4px",
                        }}
                    >
                        <span>Subscription Date</span>:{" "}
                        <span>
                            01-04-2023 <span> To </span> 31-03-2024
                        </span>
                    </li>
                    <li
                        style={{
                            color: "#555555",
                            fontFamily: "sans-serif",
                            fontWeight: "100",
                            fontSize: "12px",
                            paddingTop: "4px",
                        }}
                    >
                        <span>Plan</span>: <span>Professional</span>
                    </li>
                </ul>
            </div> */}
            <Box sx={{ mt: 10 }}>
                <Box sx={containerStyle}>
                    <Typography component={Link} to="#" sx={linkStyle}>
                        Change Password
                    </Typography>
                    {" | "}
                    <Typography component={Link} sx={linkStyle}>
                        Activity Log
                    </Typography>
                    {" | "}
                </Box>
                <Box sx={containerStyle}>
                    <Typography component={Link} to="#" sx={linkStyle}>
                        Help
                    </Typography>
                    {" | "}
                    <Typography component={Link} sx={linkStyle}>
                        Privacy Policy
                    </Typography>
                    {" | "}
                </Box>
                <Box sx={containerStyle}>
                    <Typography component={Link} to="#" sx={linkStyle}>
                        Feedback
                    </Typography>
                    {" | "}
                    <Typography component={Link} sx={linkStyle}>
                        Version 1.5(24)
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default UserProfile;

const linkStyle = {
    color: "#555555",
    fontFamily: "sans-serif",
    fontWeight: "100",
    fontSize: "11px",
    textDecoration: "none",
    mx: 1,
};

const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
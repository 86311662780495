import React, { useMemo, useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import DataTable from "../../../components/common/DataTable";
import { genericGet } from "../../../services/apiServices";
import { READ_EXPORT_SHIPMENT_COSTING, READ_PRODUCT_WISE_EXPORT } from "../../../services/apiRoutes";
import { AuthContext } from "../../../context/AuthContext";
import CustomLoader from "../../common/CustomLoader";
import axios from "axios";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomReport from "../../common/CustomReport";

const title = "Product Wise Export Report Monthly";

const ProductWiseExportReportView = () => {
    const { particularName } = useParams();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);



    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'date', header: 'Date', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'commercialInvoiceNo', header: 'CI No.', size: 150, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'clientCompanyName', header: 'Customer Name', size: 200,
                tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'totalQty', header: 'Export Qty by Unit', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'importQtyByAlternateUnit', header: 'Export Qty by alternate unit', size: 300, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton  >
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },],
        []
    );

    const fetchData = async (particularName) => {
        try {
            setLoading(true);
            const accessToken = sessionStorage.getItem("accessToken");
            const response = await axios.get(READ_PRODUCT_WISE_EXPORT(particularName), {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.data.success && response.data.data) {
                const processedData = response.data.data.map((item, index) => {
                    const particulars = item.particulars.map((part) => ({
                        commercialInvoiceParticularsID: part.commercialInvoiceParticularsID,
                        commercialInvoiceId: part.commercialInvoiceId,
                        particularName: part.particularName,
                        qty: part.qty,
                        rate: part.rate,
                        unit: part.unit,
                        amount: part.amount,
                        description: part.description,
                        productName: part.products?.productName || "",
                        unitName: part.productsUnit?.unitName || "",
                    }));

                    const totalQty = item.particulars.reduce((sum, part) => sum + (part.qty || 0), 0);

                    return {
                        index: (index + 1).toString().padStart(2, '0'),
                        commercialInvoiceId: item.commercialInvExpId,
                        userId: item.userId,
                        companyId: item.companyId,
                        supplierNamesId: item.supplierNamesId,
                        reference: item.reference,
                        commercialInvoiceNo: item.commercialInvoiceNo,
                        date: item.date,
                        dueDate: item.dueDate,
                        note: item.note,
                        ciUpload: item.ciUpload,
                        importShipmentId: item.importShipmentId,
                        clientCompanyName: item.customer?.clientCompanyName || "",
                        clientEmail: item.clientCompany?.email || "",
                        clientMobile: item.clientCompany?.mobile || "",
                        particulars,
                        totalQty,
                    };
                });
                setData(processedData);
                setFilteredData(processedData);
            } else {
                setError("No data found.");
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    const handleMonthChange = (event, newValue) => {
        setSelectedMonth(newValue);
        if (newValue) {
            const filtered = data.filter((item) => {
                const itemDate = new Date(item.date);
                return itemDate.getMonth() + 1 === newValue.id;
            });
            setFilteredData(filtered);
        } else {
            setFilteredData(data);
        }
    };

    useEffect(() => {
        if (!particularName) return;
        setLoading(true);
        fetchData(particularName);
    }, [particularName, token]);

    const monthlyList = [
        { id: 1, title: 'January' },
        { id: 2, title: 'February' },
        { id: 3, title: 'March' },
        { id: 4, title: 'April' },
        { id: 5, title: 'May' },
        { id: 6, title: 'June' },
        { id: 7, title: 'July' },
        { id: 8, title: 'August' },
        { id: 9, title: 'September' },
        { id: 10, title: 'October' },
        { id: 11, title: 'November' },
        { id: 12, title: 'December' },
    ];


    const defaultView = 'productwiseexportreport';
    const mainContent = (
        <>
            <CustomLoader open={loading} />
            <CustomAutocomplete
                options={monthlyList}
                fieldLabel="Select Month"
                getOptionLabel={(option) => option.title}
                value={selectedMonth}
                onChange={handleMonthChange}
                placeholder="Select a Month"
                sx={{ width: "200px !important" }}
            />

            <DataTable
                title={title}
                columns={columns}
                data={filteredData}
            />
        </>
    );



    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>

    );
};

export default ProductWiseExportReportView;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DataTable from "../../components/common/DataTable";
import CustomModal from "../../components/common/CustomModal";
import { GET_SHIPPING_LINE } from "../../services/apiRoutes"
import { genericPost } from '../../services/authServices';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomLoader from "../../components/common/CustomLoader";
import MessageBox from "../../components/common/CustomMessageBox";
import { AuthContext } from "../../context/AuthContext";
import CreateManageProducts from "./CreateMasters/CreateManageProducts";
import { genericGet } from "../../services/apiServices";
import { CircularProgress } from "@mui/material";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
    SaveRounded,
} from "@mui/icons-material";
import { CREATE_SHIPPING_LINE } from "../../services/apiRoutes"
import CustomTextField from "../common/CustomTextField";
import CustomButton from "../common/CustomButton";
import CustomMaster from "../common/CustomMaster";
import UpdateShippingLineMaster from "./UpdateMaster/UpdateShippingLineMaster";

const title = "Shipping Line Master";

const ValidationSchema = Yup.object().shape({
    shippingLineName: Yup.string().required('shipping Line Name is required'),
    shortName: Yup.string().required('short Name is required'),
});

const ShippingLineMaster = () => {
    const { token } = useContext(AuthContext)
    const [modal, setModal] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })

    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: "shippingLineName", header: "Shipping Line Name",
                tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 50
            },
            {
                accessorKey: "shortName",
                tableHeader: {
                    justifyContent: 'flex-start',
                }, header: "Short Name", size: 50
            },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                            <IconButton onClick={() => handleUpdateClick(row.original.shippingLineId)}>
                                <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                            </IconButton>
                            <IconButton
                            >
                                <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                            </IconButton>
                        </div>
                    </>

                ),
            },

        ],
        []
    );

    const handleUpdateClick = (shippingLineId) => {
        setSelectedId(shippingLineId);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await genericGet({ url: GET_SHIPPING_LINE });
            if (response.data) {
                const CustumData = response.data.shippingLine.map((data, index) => ({
                    ...data,
                    chaId: data.chaId || '',
                    shippingLineId: data.shippingLineId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                }));
                setData(CustumData);
                setLoading(false)

            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error fetching ", error);
        }
    };
    useEffect(() => {
        fetchData()
    }, [token])

    const handleOpen = () => {
        setModal(true);
    };
    const handleClose = () => {
        setModal(false)
    }
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const payload = {
                shippingLineName: values.shippingLineName,
                shortName: values.shortName,
            };
            const response = await genericPost(CREATE_SHIPPING_LINE, payload);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Unit added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                }, 1000);
                resetForm();
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        shippingLineName: '',
        shortName: '',

    });
    const defaultView = 'shippinglinemaster';
    const mainContent = (
        <>
            <Box sx={{ padding: "10px" }}>
                <Box   >
                    <Formik
                        initialValues={formData}
                        validationSchema={ValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, errors, touched, setFieldValue, values, handleChange }) => (
                            <Form>
                                <Grid container spacing={2} sx={{ px: "0%" }}>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            placeholder="Enter Shipping Line Name"
                                            name="shippingLineName"
                                            fieldLabel="Shipping Line Name"
                                            required
                                            value={values.shippingLineName}
                                            onChange={handleChange}
                                            error={touched.shippingLineName && !!errors.shippingLineName}
                                            helperText={touched.shippingLineName && errors.shippingLineName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            placeholder="Enter Short Name"
                                            name="shortName"
                                            fieldLabel="Short Name"
                                            required
                                            value={values.shortName}
                                            onChange={handleChange}
                                            error={touched.shortName && !!errors.shortName}
                                            helperText={touched.shortName && errors.shortName}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={2}>
                                        <Box sx={{ pt: 3 }}>
                                            <CustomButton
                                                startIcon={<SaveRounded />}
                                                title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                                size="small"
                                                variant="contained"
                                                type="submit"
                                                disabled={isSubmitting}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
                <CustomLoader open={loading} />
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                    handleOpen={handleOpen}
                />
                <CustomModal
                    modalStyles={{
                        maxHeight: '35vh',
                        top: "10%",
                    }}
                    open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateShippingLineMaster
                        handleClose={handleCloseUpdate}
                        shippingLineId={selectedId}
                        fetchData={fetchData}
                        setMessage={setMessage}
                    />
                </CustomModal>
                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />

            </Box>
        </>
    );


    return (
        <>
            <CustomMaster
                defaultView={defaultView}
                mainContent={mainContent} />
        </>
    );
};
export default ShippingLineMaster;


import React, { useState } from 'react';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import DetailsPhotoCard from '../../common/DetailsPage/DetailsPhotoCard';
import { ExportMenu, } from '../../../constants/DetailsPage'
import { Main } from '../../../constants/DetailsMain';
import CustomModal from "../../../components/common/CustomModal";
import CreateDocument from "../CreateForm/CreateDocument";
import CreateLoadingPhoto from "../CreateForm/CreateLoadingPhoto";
import CreateProductPhoto from "../CreateForm/CreateProductPhoto";
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import CustomLoader from '../../common/CustomLoader';




export function AttatchDocument() {
    const [leadModal, setLeadModal] = useState(false);

    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false);
    };

    const AttachDocumentColumns = [
        { id: 'sn', label: 'SN', accessor: 'sn' },
        { id: 'documentName', label: 'Document Name', accessor: 'documentName' },
        { id: 'attach', label: 'Attach', accessor: 'attach' },
        { id: 'description', label: 'Description', accessor: 'description' },
    ];


    return (
        <>
            <DetailsPageCard
                columns={AttachDocumentColumns}
                title={'Attatch Document'}
                buttonTitle={'Attatch Document'}
                handleOpen={handleOpen}
            />
            <CustomModal open={leadModal} handleClose={handleClose}>
                <CreateDocument />
            </CustomModal>
        </>
    );
}




export function ProductPhotoCard() {
    const [leadModal, setLeadModal] = useState(false);

    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false);
    };
    return (
        <>
            <DetailsPhotoCard
                title="Product Photo"
                buttonTitle="Create Product Photo"
                imageData={images}
                handleOpen={handleOpen}

            />
            <CustomModal open={leadModal} handleClose={handleClose}>
                <CreateProductPhoto />
            </CustomModal>
        </>
    );
}

export function LoadingPhoto() {
    const [leadModal, setLeadModal] = useState(false);

    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false);
    };
    return (
        <>
            <DetailsPhotoCard
                title="Loading Photo"
                buttonTitle="Create Loading Photo"
                imageData={images}
                handleOpen={handleOpen}

            />
            <CustomModal open={leadModal} handleClose={handleClose}>
                <CreateLoadingPhoto />
            </CustomModal>
        </>
    );
}








export default function Document() {

    const [open, setOpen] = React.useState(true);
    const [leadModal, setLeadModal] = useState(false);

    const [activeTab, setActiveTab] = React.useState('Document');


    const navigate = useNavigate();
    const { exportShipmentId } = useParams();

    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);
    if (!shipmentDetails) {
        return <p> <CustomLoader open={shipmentLoading} /></p>;
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };


    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false)
    }

    const companyName = shipmentDetails.company.companyName;
    const phoneNumber = shipmentDetails.shipmentId;
    const initial = shipmentDetails.company.companyName ? shipmentDetails.company.companyName.charAt(0).toUpperCase() : '';

    const handleBackClick = () => {
        navigate("/app/export/shipement");
    };

    const AttachDocumentColumns = [
        { id: 'sn', label: 'SN', accessor: 'sn' },
        { id: 'documentName', label: 'Document Name', accessor: 'documentName' },
        { id: 'attach', label: 'Attach', accessor: 'attach' },
        { id: 'description', label: 'Description', accessor: 'description' },
    ];


    return (
        <>
            <div>
                {/* <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={ExportMenu(exportShipmentId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}

                />

                <Main open={open}> */}
                <Box>
                    <AttatchDocument />
                </Box>
                <br />
                <Box>
                    <ProductPhotoCard />
                </Box>
                <br />
                <Box>
                    <LoadingPhoto />
                </Box>

                {/* </Main> */}
            </div >
        </>
    );
}


const images = [
    {
        label: 'Account Statement',
        imgPath: 'https://i.calameoassets.com/230523125917-528a844974d315a22504d5565f39a286/large.jpg',
    },
    {
        label: 'Bank Statement',
        imgPath: 'https://i.calameoassets.com/140401170644-308f06c9ab46e599583c3aa5cfa7af52/large.jpg',
    },
    {
        label: 'Invoice Book',
        imgPath: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0OoaKRU-pjb8NcXbhRn6LQZEwjKmDAEpQ_Q&s',
    },
    {
        label: 'Transaction Details',
        imgPath: 'https://mi-blogs.s3.amazonaws.com/mi-live/blog/wp-content/uploads/2022/11/21055200/Statement-Example.webp',
    },
    {
        label: 'Payment Receipts',
        imgPath: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqRhR521amqhfofZln_pE_uS47aAH3Ss_3uA&s',
    },
    {
        label: 'Expense Reports',
        imgPath: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGKAn-hHWUZJgOZ1qAYRxARXkbDazjryKDPw&s',
    },
    {
        label: 'Payroll Records',
        imgPath: 'https://www.pdfgear.com/pdf-editor-reader/img/how-to-copy-pdf-link-1.png',
    },
    {
        label: 'Financial Statements',
        imgPath: 'https://i.calameoassets.com/230523125917-528a844974d315a22504d5565f39a286/large.jpg',
    },
    {
        label: 'Tax Documents',
        imgPath: 'https://i.calameoassets.com/230523125917-528a844974d315a22504d5565f39a286/large.jpg',
    },
    {
        label: 'Audit Reports',
        imgPath: 'https://i.calameoassets.com/230523125917-528a844974d315a22504d5565f39a286/large.jpg',
    }
];
import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import { ClearRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import { Get_task_taskId } from '../../../services/apiRoutes';
import CustomCardView from "../../common/CustomCardView";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const TaskView = ({ handleClose, taskId }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (!taskId) return;

            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(Get_task_taskId(taskId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
                setData(response.data.task);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [taskId]);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    const labels = [
        { key: 'taskOwner', label: "task Owner", sm: 12, md: 4, },
        { key: 'taskName', label: "task Name", sm: 12, md: 4, },
        { key: 'contactPerson', label: "contact Person", sm: 12, md: 4, },
        { key: 'subject', label: "subject", sm: 12, md: 4, },
        { key: 'taskOwner', label: "assign To", sm: 12, md: 4, },
        { key: 'dueDate', label: "due Date", sm: 12, md: 4, },
        { key: 'taskStatus', label: "task Status", sm: 12, md: 4, },
        { key: 'priority', label: "priority", sm: 12, md: 4, },
        { key: 'description', label: "description", sm: 12, md: 12, },
    ];
    const values = {
        taskId: data.taskId,
        companyId: data.companyId,
        userId: data.userId,
        assignToId: data.assignToId,
        taskName: data.taskName,
        subject: data.subject,
        dueDate: data.dueDate,
        description: data.description,
        taskStatus: data.taskStatus?.taskStatusName || "N/A",
        priority: data.priority?.priorityName || "N/A",
        taskOwner: `${data.taskOwner?.firstName || ""} ${data.taskOwner?.lastName || ""}`,
        companyName: data.company?.companyName || "N/A",
        clientCompanyName: data.clientCompany?.clientCompanyName || "N/A",
        contactPerson: `${data.contact?.firstName || ''} ${data.contact?.lastName || ''}`.trim(),
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={values}
                enableReinitialize
            >
                {() => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600 }} style={{ fontSize: "18px", paddingTop: "5px" }}>
                                    Task Details
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>

                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close">
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} onClick={handleClose} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item xs={12}>
                            <CustomCardView label={labels} data={values} />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default TaskView;

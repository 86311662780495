import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { ExportMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from '../../../components/common/CustomModal';
import CreateBLDraft from "../CreateForm/CreateBLDraft";
import UpdateBLDraft from '../UpdateForm/UpdateBLDraft';
import MessageBox from '../../common/CustomMessageBox';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import { LIST_BL_DRAFTS_EXPORT } from '../../../services/apiRoutes';
import { genericGet } from '../../../services/apiServices';
import { genericPost } from '../../../services/authServices';
import axios from 'axios';
import { formatDate } from '../../../services/CommonServices';
import BLDraftView from '../ViewDetails/BLDraftView';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';

const BLDraft = () => {
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('BL Draft');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState({ open: false, text: '', type: '' });
    const [blDraftId, setBLDraftId] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const navigate = useNavigate();
    const { exportShipmentId } = useParams();
    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);


    let customerId, cifFob;
    const hasFetched = useRef(false);
    useEffect(() => {
        if (shipmentDetails) {
            customerId = shipmentDetails.customerId;

            cifFob = shipmentDetails.cifFob.cifFobName;
            fetchData();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateModal, blDraftId, token, modal]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: customerId,
                shipmentId: shipmentId,
            };

            const response = await genericPost(LIST_BL_DRAFTS_EXPORT, payload);

            if (response.data.success) {
                const blDrafts = response.data.data.map((draft, index) => {
                    const particulars = draft.particulars.map((particular) => ({
                        blDraftParticularsId: particular.blDraftParticularsId || '',
                        sealNo: particular.sealNo || '',
                        type: particular.type || '',
                        noOfPkgs: particular.noOfPkgs || 0,
                        grossWeight: parseFloat(particular.grossWeight) || 0,
                        measurement: parseFloat(particular.measurement) || 0,
                    }));

                    return {
                        blDraftId: draft.blDraftId || '',
                        index: (index + 1).toString().padStart(2, '0'),
                        userId: draft.userId || '',
                        companyId: draft.companyId || '',
                        clientCompanyId: draft.clientCompanyId || '',
                        shipper: draft.shipper || '',
                        paymentMethod: draft.paymentMethod || '',
                        blNumber: draft.blNumber || '',
                        orderMethod: draft.orderMethod || '',
                        consignee: draft.consignee || '',
                        hsCode: draft.hsCode || '',
                        specialRequirement: draft.specialRequirement || '',
                        notifyParty: draft.notifyParty || '',
                        alsoNotify: draft.alsoNotify || '',
                        oceanVesselVoyNo: draft.oceanVesselVoyNo || '',
                        portOfLoading: draft.portOfLoading || '',
                        excessValueDeclaration: draft.excessValueDeclaration || '',
                        portOfDestination: draft.portOfDestination || '',
                        finalDestination: draft.finalDestination || '',
                        freightPayableAt: draft.freightPayableAt || '',
                        noOfOriginals: draft.noOfOriginals || 0,
                        marks: draft.marks || '',
                        noOfPkgs: draft.noOfPkgs || 0,
                        descriptionOfGoods: draft.descriptionOfGoods || '',
                        grossWeight: draft.grossWeight || '',
                        measurement: draft.measurement || '',
                        createdAt: formatDate(draft.createdAt),
                        updatedAt: draft.updatedAt || '',
                        particulars,
                        customerName: draft.customer?.clientCompanyName || '',
                        customerCountry: draft.customer?.mailingCountry?.name || '',
                        shipmentId: draft.shipmentId || '',
                        noOfContainers: noOfContainers || '',
                    };
                });
                setData(blDrafts);
            } else {
                setError('No BL drafts found');
            }
        } catch (error) {
            setError('Error fetching BL drafts');
            console.error('Error fetching BL drafts', error);
        } finally {
            setLoading(false);
        }
    };



    // Table columns
    const columns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        {
            id: 'shipmentId', label: 'Shipping Id', accessor: 'shipmentId', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'createdAt', label: 'Date', accessor: 'createdAt', styleAccessor: {
                textAlign: 'center',
            },
        },
        { id: 'customerName', label: 'Customer Name', accessor: 'customerName' },
        {
            id: 'noOfContainers', label: 'No. of Containers', accessor: 'noOfContainers', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.blDraftId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.blDraftId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.blDraftId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];


    const handleUpdateClick = (id) => {
        setBLDraftId(id);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setBLDraftId('');
    };
    const handleViewClick = (id) => {
        setBLDraftId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setBLDraftId('');
    };

    const handleBackClick = () => {
        navigate('/app/export/shipement');
    };

    if (!shipmentDetails) {
        return <p>  <CustomLoader open={shipmentLoading} />
        </p>;
    }

    const addressToClient = `${shipmentDetails.customer.billingStreet || ''}, ${shipmentDetails?.customer?.billingCity?.name || ''}, ${shipmentDetails?.customer?.billingState?.name || ''}, ${shipmentDetails?.customer?.billingCountry?.name || ''}, ${shipmentDetails?.customer?.billingPincode || ''}.`.trim();
    const consigneeName = shipmentDetails.customer.clientCompanyName;
    const consignee = `${consigneeName || ''}, ${addressToClient || ''}`.trim();
    const addressToCompany = `${shipmentDetails?.company?.address || ''}, ${shipmentDetails?.company?.addressLineOne || ''}, ${shipmentDetails?.company?.addressLineTwo || ''}, ${shipmentDetails?.company?.city?.name || ''}, ${shipmentDetails?.company?.state?.name || ''}, ${shipmentDetails?.company?.country?.name || ''}, ${shipmentDetails?.company?.pincode || ''}.`.trim();
    const shipperName = shipmentDetails?.company?.companyName;
    const shipper = `${shipperName || ''}, ${addressToCompany || ''}`.trim();
    const customerName = shipmentDetails?.customerId;

    const companyName = shipmentDetails.customer.clientCompanyName;
    const phoneNumber = shipmentDetails.customer.mobile;
    const shipmentId = shipmentDetails.shipmentId;
    const noOfContainers = shipmentDetails.noOfContainers;

    const initial = shipmentDetails.customer.clientCompanyName ? shipmentDetails.customer.clientCompanyName.charAt(0).toUpperCase() : '';

    // JSX Structure
    return (
        <div>
            {/* <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={ExportMenu(exportShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />

            <Main open={open}> */}
            <CustomLoader open={loading} />
            <DetailsPageCard
                Icon={DraftsOutlinedIcon}
                columns={columns}
                title="BL Draft"
                buttonTitle="Create BL Draft"
                handleOpen={handleOpen}
                data={data}
                loading={loading}
                error={error}
            />
            <CustomModal open={modal} handleClose={handleClose}>
                <CreateBLDraft
                    fetchData={fetchData}
                    customerName={customerName}
                    consignee={consignee}
                    shipper={shipper}
                    shipmentId={shipmentId}
                    setMessage={setMessage}
                    handleClose={handleClose}
                />
            </CustomModal>

            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateBLDraft
                    fetchData={fetchData}
                    setMessage={setMessage}
                    handleClose={handleCloseUpdate}
                    blDraftId={blDraftId}
                />
            </CustomModal>

            <CustomModal open={viewModal} handleClose={handleCloseView}>
                <BLDraftView
                    fetchData={fetchData}
                    setMessage={setMessage}
                    handleClose={handleCloseView}
                    blDraftId={blDraftId}
                />
            </CustomModal>

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            {/* </Main> */}
        </div >
    );
};

export default BLDraft;

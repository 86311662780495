// CompanyCard.js
import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  CardMedia,
  Grid,
  Box,
  CircularProgress,
  Avatar
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CustomModal from "../components/common/CustomModal";
import AddCompany from "../components/MyCompany/AddCompany";
import { getCountries, getStates } from "../services/CommonServices";
import { genericGet, genericPut } from "../services/apiServices";
import { GET_COMPANY, SET_DEFAULT_COMPANY } from "../services/apiRoutes";
import { AuthContext } from '../context/AuthContext';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import MobileScreenShareOutlinedIcon from '@mui/icons-material/MobileScreenShareOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CustomMaster from "../components/common/CustomMaster";

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  transition: 'transform 0.2s, box-shadow 0.2s',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.2s, box-shadow 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[1],
  },
}));
const ContentWrapper = styled('section')(({ theme }) => ({
  padding: theme.spacing(0),
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
}));


const CompanyCard = () => {
  const navigate = useNavigate();
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [getAllCompany, setGetAllCompany] = useState([]);
  const [loading, setLoading] = useState(false)
  const { login } = useContext(AuthContext)

  const handleClose = () => {
    setAddCompanyModal(false);
  };

  const handleSetDefault = async (companyId) => {
    console.log(`Set default for company ID: ${companyId}`);
    setLoading(true)
    try {
      const response = await genericPut(`${SET_DEFAULT_COMPANY}/${companyId}`, {});
      if (response.data.accessToken) {
        sessionStorage.setItem('accessToken', response.data.accessToken)
        login(response.data.accessToken)
        getCompanyList();
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false)

    }
  };

  const getCompanyList = async () => {
    try {
      const response = await genericGet({ url: GET_COMPANY });
      let CompanyData = response.data.companies;
      if (CompanyData) {
        setGetAllCompany(CompanyData);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCommonData = async () => {
    const getCountriesData = await getCountries();
    if (getCountriesData) {
      setCountries(getCountriesData);
    }
  };

  useEffect(() => {
    getCommonData();
    getCompanyList();
  }, []);

  const handleClick = async (companyId) => {
    // Custom logic here (e.g., tracking, analytics, etc.)
    console.log(`Company card clicked: ${companyId}`);

    // Navigate to the route
    navigate(`/app/companysetting/${companyId}`);
  };

  const defaultView = 'mycompany';
  const mainContent = (
    <>
      <ContentWrapper style={{ marginTop: '0px' }} variant="outlined">
        <CardContent>
          <Heading variant="h4" style={{ paddingBottom: '10px', textTransform: "capitalize" }}>
            registered company
          </Heading>
          <Grid container spacing={2}>
            {getAllCompany.map((company) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={company.companyId}>
                <StyledCard
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 1,
                  }}
                  onClick={() => handleClick(company.companyId)}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexShrink: 0,
                    }}
                  >
                    <Avatar
                      // src="https://www.hrvers.com/static/media/Manufacturing.a0466f55ca89b5362d99def653a87573.svg"
                      alt="Company Logo"
                      sx={{
                        width: 100,
                        height: 100,
                        margin: "0 auto",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      flexGrow: 1,
                    }}
                  >
                    <CardContent>
                      <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: '600', display: 'flex', alignItems: 'center' }}>
                        <BusinessOutlinedIcon sx={{ marginRight: 1 }} />
                        {company.companyName}
                      </Typography>
                      <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: '300', display: 'flex', alignItems: 'center', fontSize: "12px" }}>
                        <MobileScreenShareOutlinedIcon sx={{ marginRight: 1 }} />
                        {company.mobile}
                      </Typography>
                      <Typography variant="h5" component="div" gutterBottom sx={{ fontWeight: '300', display: 'flex', alignItems: 'center', fontSize: "12px" }}>
                        <EmailOutlinedIcon sx={{ marginRight: 1 }} />
                        {company.email}
                      </Typography>
                      <Button
                        variant="contained"
                        color={company.isDefault ? 'success' : 'primary'}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSetDefault(company.companyId);
                        }}
                      >
                        {company.isDefault ? "Default" : "Set Default"}
                      </Button>
                    </CardContent>
                  </Box>
                </StyledCard>
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <StyledCard variant="outlined" sx={{ display: "flex", justifyContent: "center" }}>
                <CardActions  >
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => setAddCompanyModal(true)}
                  >
                    Add Company
                  </Button>
                </CardActions>
              </StyledCard>
            </Grid>
          </Grid>
          <CustomModal open={addCompanyModal} handleClose={handleClose}>
            <AddCompany
              handleClose={handleClose}
              countries={countries}
              getStates={getStates}
            />
          </CustomModal>
        </CardContent>
      </ContentWrapper>
    </>
  );
  return (
    <CustomMaster
      defaultView={defaultView}
      mainContent={mainContent} />
  );
};

export default CompanyCard;
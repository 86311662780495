import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { ExportMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from '../../../components/common/CustomModal';
import CreateCOA from '../CreateForm/CreateCOA';
import UpdateCOA from '../UpdateForm/UpdateCOA';
import MessageBox from '../../common/CustomMessageBox';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import { GET_PRODUCTS_LIST, LIST_COAS_EXPORT, LIST_COMMERCIAL_INVOICES_EXPORT } from '../../../services/apiRoutes';
import { genericGet } from '../../../services/apiServices';
import { formatDate } from '../../../services/CommonServices';
import { genericPost } from '../../../services/authServices';
import COAView from '../ViewDetails/COAView';
import axios from 'axios';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';

const COA = () => {
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('COA');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState({ open: false, text: '', type: '' });
    const [selectedCOA, setSelectedCOA] = useState(null);
    const [viewModal, setViewModal] = useState(false);
    const navigate = useNavigate();
    const { exportShipmentId } = useParams();
    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);
    const [products, setProducts] = useState([]);
    const [newShippingId, setNewShippingId] = useState(null);
    const [invoiceList, setInvoiceList] = useState([]);



    let customerId, cifFob;
    const hasFetched = useRef(false);
    useEffect(() => {
        if (shipmentDetails) {
            customerId = shipmentDetails.customerId;

            cifFob = shipmentDetails.cifFob.cifFobName;
            fetchData();
            fetchDataCommercialInvoiceList();
            fetchProductsList();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateModal, selectedCOA, token, modal]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: customerId,
                shipmentId: shipmentId,
            };

            const response = await genericPost(LIST_COAS_EXPORT, payload);

            if (response.data.success) {
                const coaData = response.data.data.map((item, index) => {
                    const particulars = item.particulars.map((particular) => ({
                        coaParticularsId: particular.coaParticularsId || '',
                        items: particular.items || '',
                        standard: particular.standard || '',
                        result: particular.result || '',
                        levelId: particular.levelId || '',
                    }));

                    return {
                        coaId: item.coaId || '',
                        index: (index + 1).toString().padStart(2, '0'),
                        userId: item.userId || '',
                        companyId: item.companyId || '',
                        clientCompanyId: item.clientCompanyId || '',
                        toAddress: item.toAddress || '',
                        invoiceNo: item.invoiceNo || '',
                        date: item.date || '',
                        loadingPort: item.loadingPort || '',
                        destinationPort: item.destinationPort || '',
                        productName: item?.product?.productName || '',
                        conculation: item.conculation || '',
                        analyst: item.analyst || '',
                        examiner: item.examiner || '',
                        approved: item.approved || false,
                        casNo: item.casNo || '',
                        iupac: item.iupac || '',
                        createdAt: item.createdAt || '',
                        updatedAt: item.updatedAt || '',
                        particulars,
                        customerName: item.customer?.clientCompanyName || '',
                        customerCountry: item.customer?.mailingCountry?.name || '',
                        shipmentId: item.shipmentId || '',

                    };
                });
                const { newShippingId } = response.data;
                setNewShippingId(newShippingId);
                setData(coaData);
            } else {
                setError('No COA data found');
            }
        } catch (error) {
            setError('Error fetching COA data');
            console.error('Error fetching COA data', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchDataCommercialInvoiceList = async () => {
        try {
            const payload = {
                customerNamesId: customerId,
                shipmentId: shipmentId,
            };
            const response = await genericPost(LIST_COMMERCIAL_INVOICES_EXPORT, payload, {
            });
            if (response.data && response.data.success && response.data.data) {
                const customersData = response.data.data.map((customer) => ({
                    id: customer.commercialInvoiceNo,
                    title: customer.commercialInvoiceNo,
                }));
                setInvoiceList(customersData);
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching customer list:', error);
            return [];
        }
    };

    const fetchProductsList = async () => {
        const authToken = sessionStorage.getItem('accessToken');
        const payload = {
            clientCompanyId: customerId,
        };

        try {
            const response = await axios.post(GET_PRODUCTS_LIST, payload, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200 && response.data.success) {
                const productData = response.data.productsList;
                console.log(productData);
                setProducts(productData);
            } else {
                console.error('Failed to fetch products list:', response.data);
            }
        } catch (error) {
            console.error('Error fetching products list:', error);
        }
    };


    // Table columns
    const columns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        {
            id: 'shipmentId', label: 'Shipping Id', accessor: 'shipmentId', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'date', label: 'Date', accessor: 'date', styleAccessor: {
                textAlign: 'center',
            },
        },
        { id: 'productName', label: 'Product Name', accessor: 'productName' },
        {
            id: 'batchNo', label: 'Batch No.', accessor: 'batchNo', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.coaId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.coaId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.coaId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];



    const handleUpdateClick = (coa) => {
        setSelectedCOA(coa);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (coa) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setSelectedCOA(null);
    };
    const handleViewClick = (id) => {
        setSelectedCOA(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setSelectedCOA('');
    };

    const handleBackClick = () => {
        navigate('/app/export/shipment');
    };

    if (!shipmentDetails) {
        return <p>  <CustomLoader open={shipmentLoading} /> </p>;
    }

    const addressToClient = `${shipmentDetails.customer.billingStreet || ''}, ${shipmentDetails.customer.billingCity.name || ''}, ${shipmentDetails.customer.billingState.name || ''}, ${shipmentDetails.customer.billingCountry.name || ''}, ${shipmentDetails.customer.billingPincode || ''}.`.trim();
    const companyName = shipmentDetails.customer.clientCompanyName;
    const addressTo = `${companyName || ''}, ${addressToClient || ''}`.trim();

    const phoneNumber = shipmentDetails.customer.mobile;
    const customerName = shipmentDetails.customerId;
    const shipmentId = shipmentDetails.shipmentId;
    const initial = shipmentDetails.customer.clientCompanyName ? shipmentDetails.customer.clientCompanyName.charAt(0).toUpperCase() : '';


    // JSX Structure
    return (
        <div>
            {/* <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={ExportMenu(exportShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />

            <Main open={open}> */}
            <CustomLoader open={loading} />
            <DetailsPageCard
                columns={columns}
                title="COA"
                buttonTitle="Create COA"
                handleOpen={handleOpen}
                data={data}
                loading={loading}
                error={error}
            />
            <CustomModal open={modal} handleClose={handleClose}>
                <CreateCOA
                    fetchData={fetchData}
                    customerName={customerName}
                    addressTo={addressTo}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    productsList={products}
                    newShippingId={newShippingId}
                    shipmentId={shipmentId}
                    setMessage={setMessage}
                    invoiceList={invoiceList}
                    handleClose={handleClose} />
            </CustomModal>

            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateCOA
                    fetchData={fetchData}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    productsList={products}
                    setMessage={setMessage}
                    handleClose={handleCloseUpdate}
                    invoiceList={invoiceList}
                    coaId={selectedCOA}
                />
            </CustomModal>

            <CustomModal open={viewModal} handleClose={handleCloseView}>
                <COAView
                    fetchData={fetchData}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    setMessage={setMessage}
                    handleClose={handleCloseView}
                    coaId={selectedCOA}
                />
            </CustomModal>

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            {/* </Main> */}
        </div>
    );
};

export default COA;

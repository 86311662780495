// src/components/Ratings.js

import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DataTable from "../../components/common/DataTable";
import { getRating } from "../../services/CommonServices";
import CustomMaster from "../common/CustomMaster";

const title = "Ratings";

const Ratings = () => {
    const [ratings, setRatings] = useState([]);
    const columns = useMemo(
        () => [
            { accessorKey: "id", header: "SN.", size: 50, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: 'title', header: 'Rating', tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 200
            },
            {
                accessorKey: 'shortName', header: 'Short Name', tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 150
            },
        ],
        []
    );


    const fetchRatings = async () => {
        const ratingsData = await getRating();
        setRatings(ratingsData);
    };

    useEffect(() => {
        fetchRatings();
    }, []);
    const defaultView = 'ratings';
    const mainContent = (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={ratings}
                />
            </Box>
        </>
    );
    return (
        <CustomMaster
            defaultView={defaultView}
            mainContent={mainContent} />
    );
};

export default Ratings;

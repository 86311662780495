// src/components/LeadStatuses.js

import React, { useEffect, useMemo, useState } from "react";
import DataTable from "../../components/common/DataTable";
import { getLeadStatus } from "../../services/CommonServices";
import { Box } from "@mui/material";
import CustomMaster from "../common/CustomMaster";
const title = "Lead Statuses";
const LeadStatuses = () => {
    const [leadStatuses, setLeadStatuses] = useState([]);
    const columns = useMemo(
        () => [
            { accessorKey: "id", header: "SN.", size: 50, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: 'title', header: 'Lead Status', tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 200
            },
            {
                accessorKey: 'shortName', header: 'Short Name', tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 150
            },
        ],
        []
    );


    const fetchLeadStatuses = async () => {
        const leadStatusesData = await getLeadStatus();
        setLeadStatuses(leadStatusesData);
    };

    useEffect(() => {
        fetchLeadStatuses();
    }, []);
    const defaultView = 'statusesleads';
    const mainContent = (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={leadStatuses}
                />
            </Box>
        </>
    );
    return (
        <>
            <CustomMaster
                defaultView={defaultView}
                mainContent={mainContent} />
        </>
    );
};

export default LeadStatuses;

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CustomModal from '../../common/CustomModal';
import CreatePayment from '../CreateForm/CreatePayment';
import UpdatePayment from '../UpdateForm/UpdatePayment';
import { LIST_IMPORT_PAYMENT_VOUCHERS } from '../../../services/apiRoutes';
import { costColorPlate, ExportMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import { genericPost } from '../../../services/authServices';
import MessageBox from '../../common/CustomMessageBox';
import { getCommanExportShipmentId, getCurrencyList, getPaymentType } from '../../../services/CommonServices';
import PaymentVoucherView from '../ViewDetails/PaymentVoucherView';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import CustomStatus from '../../common/CustomStatus';

export default function PaymentVoucher() {
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Payment');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [paymentVoucherId, setPaymentVoucherId] = useState('');
    const [currencyList, setCurrencyList] = useState([]);
    const [paymentType, setPaymentType] = useState([]);
    const [newShippingId, setNewShippingId] = useState(null);

    const navigate = useNavigate();

    const { exportShipmentId } = useParams();
    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);

    let supplierId, cifFob;

    const hasFetched = useRef(false);

    useEffect(() => {
        if (shipmentDetails) {
            supplierId = shipmentDetails.customerId;

            cifFob = shipmentDetails.cifFob.cifFobName;
            fetchData();
            getCommonData();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateModal, paymentVoucherId, token, modal]);

    const getCommonData = async () => {
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
        const paymentType = await getPaymentType();
        setPaymentType(paymentType);
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: supplierId,
                commanShipmentId: getCommanExportShipmentId,
                shipmentId: shipmentId,

            };
            const response = await genericPost(LIST_IMPORT_PAYMENT_VOUCHERS, payload, {
            });
            if (response.data.success && response.data.paymentVoucher) {
                const { newShippingId } = response.data;
                const paymentVoucherData = response.data.paymentVoucher.map((voucher, index) => ({
                    sn: voucher.paymentVoucherId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    date: voucher.date || '',
                    voucherNo: voucher.paymentVoucherNo || '',
                    supplierName: voucher.clientCompany?.clientCompanyName || '',
                    paymentType: voucher.paymentType?.paymentName || '',
                    paymentTypeId: voucher.paymentType?.paymentTypeId || '',
                    currency: voucher.currency?.currency || '',
                    baseCurrency: voucher.baseCurrency?.currency || '',
                    amount1: `${voucher?.currency?.currency_symbol || ''} ${voucher.amount || ''}`,
                    amount2: `${voucher?.baseCurrency?.currency_symbol || ''} ${voucher.amountInBaseCurrency || ''}`,
                    bankCharges: `${voucher?.baseCurrency?.currency_symbol || ''} ${voucher.bankCharges || ''}`,
                    conversionRate: `${voucher?.baseCurrency?.currency_symbol || ''} ${voucher.conversionRate || ''}`,
                }));
                setData(paymentVoucherData);
                setNewShippingId(newShippingId);

            } else {
                setError('No payment vouchers found');
            }
        } catch (error) {
            setError('Error fetching payment vouchers');
            console.error('Error fetching payment vouchers', error);
        } finally {
            setLoading(false);
        }
    };
    if (!shipmentDetails) {
        return <p>  <CustomLoader open={shipmentLoading} /> </p>;
    }

    const PaymentVoucherCardColumns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        {
            id: 'date', label: 'Date', accessor: 'date', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'voucherNo', label: 'Voucher No.', accessor: 'voucherNo', styleAccessor: {
                textAlign: 'center',
            },
        },
        { id: 'supplierName', label: 'Supplier Name', accessor: 'supplierName' },
        {
            id: 'paymentType',
            label: 'Payment Type',
            accessor: 'paymentType',
            styleAccessor: {
                textAlign: 'center',
            },
            Cell: ({ row }) => {
                const colorConfig = costColorPlate.find(
                    (color) => color.colorId === row.paymentTypeId
                ) || {
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                };
                return (
                    <CustomStatus
                        title={row.paymentType}
                        sx={{
                            backgroundColor: colorConfig.backgroundColor,
                            color: colorConfig.color,
                            textAlign: 'center',
                            display: 'inline-block',
                        }}
                    />
                );
            },
        },
        { id: 'currency', label: 'Currency', accessor: 'currency' },
        {
            id: 'amount1', label: 'Amount', accessor: 'amount1', styleAccessor: {
                textAlign: 'right',
            },
        },
        { id: 'baseCurrency', label: 'Base Currency', accessor: 'baseCurrency' },
        {
            id: 'conversionRate', label: 'conversion Rate', accessor: 'conversionRate', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'amount2', label: 'Amount in Base Currency', accessor: 'amount2', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'bankCharges', label: 'Bank Charges', accessor: 'bankCharges', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.sn)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.sn)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.sn)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];


    const handleUpdateClick = (id) => {
        setPaymentVoucherId(id);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setPaymentVoucherId('');
    };


    const handleOpenView = (id) => {
        setPaymentVoucherId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setPaymentVoucherId('');
    };

    const handleViewClick = (id) => {
        handleOpenView(id);
    };

    const customerName = shipmentDetails.customerId;
    const shipmentId = shipmentDetails.shipmentId;

    const baseCurrencySymbol = shipmentDetails?.company?.baseCurrencyData?.currency_symbol ?? '';
    const currencySymbol = shipmentDetails?.currency?.currency_symbol ?? '';
    const baseCurrencyId = shipmentDetails?.company?.baseCurrencyData?.id ?? '';
    const currencyId = shipmentDetails.currencyId;


    const handleBackClick = () => {
        navigate("/app/export/shipement");
    };

    return (
        <>
            <div>
                {/* <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={ExportMenu(exportShipmentId)}
                    companyName={shipmentDetails?.customer.clientCompanyName || ''}
                    phoneNumber={shipmentDetails?.customer.mobile || ''}
                    initial={shipmentDetails?.customer.clientCompanyName?.charAt(0).toUpperCase() || ''}
                    onBackClick={handleBackClick}
                />

                <Main open={open}> */}
                <CustomLoader open={loading} />
                <DetailsPageCard
                    Icon={PaymentOutlinedIcon}
                    columns={PaymentVoucherCardColumns}
                    title={'Payment Voucher'}
                    buttonTitle={'Create Payment'}
                    handleOpen={handleOpen}
                    data={data}
                    loading={loading}
                    error={error}
                />
                <CustomModal open={modal} handleClose={handleClose}>
                    <CreatePayment
                        fetchData={fetchData}
                        clientCompanyId={customerName}
                        currencyList={currencyList}
                        paymentType={paymentType}
                        commanShipmentId={getCommanExportShipmentId}
                        setMessage={setMessage}
                        handleClose={handleClose}
                        newShippingId={newShippingId}
                        shipmentId={shipmentId}
                        baseCurrencySymbol={baseCurrencySymbol}
                        currencySymbol={currencySymbol}
                        baseCurrencyId={baseCurrencyId}
                        currencyId={currencyId}

                    />
                </CustomModal>

                <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdatePayment
                        fetchData={fetchData}
                        currencyList={currencyList}
                        paymentType={paymentType}
                        setMessage={setMessage}
                        handleClose={handleCloseUpdate}
                        paymentVoucherId={paymentVoucherId}

                    />
                </CustomModal>
                <CustomModal
                    open={viewModal}
                    modalStyles={{
                        maxHeight: '45vh',
                        top: "10%",
                    }}
                    handleClose={handleCloseView}>
                    <PaymentVoucherView
                        handleClose={handleCloseView}
                        fetchData={fetchData}
                        currencyList={currencyList}
                        paymentType={paymentType}
                        setMessage={setMessage}
                        paymentVoucherId={paymentVoucherId}
                    />
                </CustomModal>
                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
                {/* </Main> */}
            </div>
        </>
    );
}

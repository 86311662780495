import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DataTable from "../../components/common/DataTable";
import CustomModal from "../../components/common/CustomModal";
import { CREATE_FREIGHT_FORWARD_AGENCY, GET_FREIGHT_FORWARD_AGENCY_LIST } from "../../services/apiRoutes"
import { genericPost } from '../../services/authServices';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomLoader from "../../components/common/CustomLoader";
import MessageBox from "../../components/common/CustomMessageBox";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { CircularProgress } from "@mui/material";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
    SaveRounded,
} from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import CustomButton from "../common/CustomButton";
import CustomTextarea from "../common/CustomTextarea";
import CustomMaster from "../common/CustomMaster";
import UpdateFreightForwardMaster from "./UpdateMaster/UpdateFreightForwardMaster";

const title = "Freight Forward Master";

const ValidationSchema = Yup.object().shape({
    agencyName: Yup.string().required('required'),
    address: Yup.string().required('required'),
    contactPerson: Yup.string().required('required'),
    emailId: Yup.string().email('Invalid email').required('Email is required'),
    phoneNo: Yup.string()
        .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
        .required('Phone is required'),
    mobileNo: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Mobile is required'),
    note: Yup.string().required('required'),
});

const FreightForwardMaster = () => {
    const { token } = useContext(AuthContext)
    const [modal, setModal] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })

    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: "agencyName", header: "Agency Name", tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 50
            },
            {
                accessorKey: "contactPerson", header: "Contact Person", tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 50
            },
            { accessorKey: "phoneNo", header: "Phone No.", tableStyle: { textAlign: "center" }, size: 50 },
            { accessorKey: "mobileNo", header: "Mobile No.", tableStyle: { textAlign: "center" }, size: 50 },
            {
                accessorKey: "emailId", header: "Email", tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 50
            },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                            <IconButton onClick={() => handleUpdateClick(row.original.agencyId)}>
                                <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                            </IconButton>
                            <IconButton
                            >
                                <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                            </IconButton>
                        </div>
                    </>
                ),
            },
        ],
        []
    );

    const handleUpdateClick = (agencyId) => {
        setSelectedId(agencyId);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await genericGet({ url: GET_FREIGHT_FORWARD_AGENCY_LIST });
            if (response.data && response.data.freightForwardAgency) {
                const agencyData = response.data.freightForwardAgency.map((agency, index) => ({
                    ...agency,
                    agencyId: agency.agencyId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                }));
                setData(agencyData);
                setLoading(false)

            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error fetching leads", error);
        }
    };




    useEffect(() => {
        fetchData()
    }, [token])

    const handleOpen = () => {
        setModal(true);
    };
    const handleClose = () => {
        setModal(false)
    }

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const payload = {
                agencyName: values.agencyName,
                address: values.address,
                contactPerson: values.contactPerson,
                phoneNo: values.phoneNo,
                mobileNo: values.mobileNo,
                emailId: values.emailId,
                note: values.note,
            };

            const response = await genericPost(CREATE_FREIGHT_FORWARD_AGENCY, payload);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                }, 1000);
                resetForm();
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        agencyName: '',
        address: '',
        contactPerson: '',
        phoneNo: '',
        mobileNo: '',
        emailId: '',
        note: '',

    });

    const defaultView = 'freightforwardmaster';
    const mainContent = (
        <>
            <Box sx={{ padding: "10px" }}>
                <Box   >
                    <Formik
                        initialValues={formData}
                        validationSchema={ValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, errors, touched, setFieldValue, values, handleChange }) => (
                            <Form>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            placeholder="Enter Agency Name"
                                            name="agencyName"
                                            fieldLabel="Agency Name"
                                            required
                                            value={values.agencyName}
                                            onChange={handleChange}
                                            error={touched.agencyName && !!errors.agencyName}
                                            helperText={touched.agencyName && errors.agencyName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            placeholder="Enter Contact Person"
                                            name="contactPerson"
                                            fieldLabel="Contact Person"
                                            required
                                            value={values.contactPerson}
                                            onChange={handleChange}
                                            error={touched.contactPerson && !!errors.contactPerson}
                                            helperText={touched.contactPerson && errors.contactPerson}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            type="number"
                                            placeholder="Enter Mobile Number"
                                            name="mobileNo"
                                            fieldLabel="Mobile Number"
                                            required
                                            value={values.mobileNo}
                                            onChange={handleChange}
                                            error={touched.mobileNo && !!errors.mobileNo}
                                            helperText={touched.mobileNo && errors.mobileNo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            type="email"
                                            placeholder="Enter Email"
                                            name="emailId"
                                            fieldLabel="Email"
                                            required
                                            value={values.emailId}
                                            onChange={handleChange}
                                            error={touched.emailId && !!errors.emailId}
                                            helperText={touched.emailId && errors.emailId}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            type="number"
                                            placeholder="Enter Phone Number"
                                            name="phoneNo"
                                            fieldLabel="Phone Number"
                                            required
                                            value={values.phoneNo}
                                            onChange={handleChange}
                                            error={touched.phoneNo && !!errors.phoneNo}
                                            helperText={touched.phoneNo && errors.phoneNo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextField
                                            placeholder="Enter Address"
                                            name="address"
                                            fieldLabel="Address"
                                            required
                                            value={values.address}
                                            onChange={handleChange}
                                            error={touched.address && !!errors.address}
                                            helperText={touched.address && errors.address}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomTextarea
                                            multiline
                                            rows={4}
                                            placeholder="Enter Note"
                                            name="note"
                                            fieldLabel="Note"
                                            value={values.note}
                                            onChange={handleChange}
                                            error={touched.note && !!errors.note}
                                            helperText={touched.note && errors.note}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ pt: 3 }}>
                                            <CustomButton
                                                startIcon={<SaveRounded />}
                                                title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                                size="small"
                                                variant="contained"
                                                type="submit"
                                                disabled={isSubmitting}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
                <CustomLoader open={loading} />
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                    handleOpen={handleOpen}
                />
                <CustomModal
                    modalStyles={{
                        maxHeight: '70vh',
                        top: "10%",
                    }}
                    open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateFreightForwardMaster
                        handleClose={handleCloseUpdate}
                        agencyId={selectedId}
                        fetchData={fetchData}
                        setMessage={setMessage}
                    />
                </CustomModal>
                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
            </Box>
        </>
    );
    return (
        <CustomMaster
            defaultView={defaultView}
            mainContent={mainContent} />
    );
};
export default FreightForwardMaster;


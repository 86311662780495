import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { GET_RECEIPT_REGISTER } from "../../services/apiRoutes";
import CustomReport from "../common/CustomReport";
const title = "Receipt Register";

const Function = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);



    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'date', header: 'Date', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'receiptVoucherNo', header: 'Voucher No.', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'shipmentId', header: 'Shipment ID', size: 100, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'shipmentType', header: 'Type', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'supplierName', header: 'Supplier Name', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'amount', header: 'Amount', size: 50, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton  >
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_RECEIPT_REGISTER });
            if (response.data.success) {
                const receiptVouchers = response.data.receiptVouchers.map((voucher, index) => ({
                    receiptVoucherId: voucher.receiptVoucherId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    shipmentId: voucher.shipmentId || '',
                    userId: voucher.userId || '',
                    companyId: voucher.companyId || '',
                    commanShipmentId: voucher.commanShipmentId || '',
                    shipmentType: voucher.commanShipmentId === 1 ? 'Export Shipment' : 'Import Shipment',
                    supplierName: voucher.customer?.clientCompanyName || '',
                    date: voucher.date || '',
                    receiptVoucherNo: voucher.receiptVoucherNo || '',
                    paymentName: voucher.paymentType?.paymentName || '',
                    amount: parseFloat(voucher.amount).toFixed(2) || "",
                    currencyId: voucher.currencyId || '',
                    bankCharges: parseFloat(voucher.bankCharges) || 0,
                    baseCurrencyId: voucher.baseCurrencyId || '',
                    conversionRate: parseFloat(voucher.conversionRate) || 0,
                    baseAmount: parseFloat(voucher.baseAmount) || 0,
                    note: voucher.note || '',
                    createdAt: voucher.createdAt || '',
                    updatedAt: voucher.updatedAt || '',
                }));

                setData(receiptVouchers);
            } else {
                setError('No receipt vouchers found');
            }
        } catch (error) {
            setError('Error fetching receipt vouchers');
            console.error('Error fetching receipt vouchers', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData()
    }, [token])

    const defaultView = 'receiptregister';
    const mainContent = (
        <DataTable
            title={title}
            columns={columns}
            data={data}
        />
    );


    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>

    );
};
export default Function;

import React, { useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded, AddRounded, DeleteRounded } from "@mui/icons-material";
import { Card, } from "@mui/material";
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import { genericPost } from '../../../services/authServices';
import { CREATE_PROFORMA_INVOICE } from '../../../services/apiRoutes';
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomStyle from "../../common/CustomStyle";
import CustomPrefixSuffixField from "../../common/CustomPrefixSuffixField";

const ValidationSchema = Yup.object().shape({
    supplierNamesId: Yup.number().required('Supplier Name is required'),
    reference: Yup.string().required('Reference is required'),
    proformaInvoiceNo: Yup.string().required('Proforma Invoice No. is required'),
    date: Yup.string().required('Date is required'),
    dueDate: Yup.string().required('Due Date is required'),
    note: Yup.string().required('Note is required'),
    piUpload: Yup.string().required('PI Upload is required'),
    particulars: Yup.array().of(
        Yup.object().shape({
            particular: Yup.string().required('Particular is required'),
            qty: Yup.number().required('Quantity is required'),
            rate: Yup.number().required('Rate is required'),
            unit: Yup.string().required('Unit is required'),
            amount: Yup.number().required('amount is required'),

        })
    ).required('Particulars are required')
});

const CreateProformaInvoice = ({ handleClose, setMessage, currencyList, currencySymbol, currencyId, fetchData, suppliersList, productsList, supplierIdName, shipmentId }) => {
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                supplierNamesId: values.supplierNamesId,
                shipmentId: values.shipmentId,
                currencyId: values.currencyId,
                reference: values.reference,
                proformaInvoiceNo: values.proformaInvoiceNo,
                date: values.date,
                dueDate: values.dueDate,
                note: values.note,
                piUpload: values.piUpload,
                particulars: values.particulars
            };

            const response = await genericPost(CREATE_PROFORMA_INVOICE, payload);

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Proforma Invoice created successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };
    const currentDate = formatDate(new Date());

    const [formData] = useState({
        supplierNamesId: parseInt(supplierIdName),
        currencyId: currencyId,
        reference: '',
        shipmentId: shipmentId,
        proformaInvoiceNo: '',
        date: currentDate || "",
        dueDate: '',
        note: '',
        piUpload: '',
        particulars: [
            {
                particular: '',
                qty: '',
                rate: '',
                amount: '',
                unit: '',
                description: ''
            }
        ]
    });

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Create Proforma Invoice
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>


                        <Divider />
                        <Grid container spacing={1} sx={{ px: "0%" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <InsertDriveFileOutlinedIcon />
                                    <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                        Bill To
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    options={suppliersList}
                                    fieldLabel="Supplier Name"
                                    required={true}
                                    readOnly={true}
                                    getOptionLabel={(option) => option.title}
                                    value={suppliersList.find((option) => option.id === values.supplierNamesId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('supplierNamesId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Supplier Name"
                                    error={touched.supplierNamesId && !!errors.supplierNamesId}
                                    helperText={touched.supplierNamesId && errors.supplierNamesId}
                                    sx={{
                                        backgroundColor: "#E9ECEF"
                                    }}
                                />

                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    placeholder="Enter Reference"
                                    name="reference"
                                    fieldLabel="Reference"
                                    required={true}
                                    value={values.reference}
                                    onChange={handleChange}
                                    error={touched.reference && !!errors.reference}
                                    helperText={touched.reference && errors.reference}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    placeholder="Enter Proforma Invoice No."
                                    name="proformaInvoiceNo"
                                    fieldLabel="Proforma Invoice No."
                                    required={true}
                                    value={values.proformaInvoiceNo}
                                    onChange={handleChange}
                                    error={touched.proformaInvoiceNo && !!errors.proformaInvoiceNo}
                                    helperText={touched.proformaInvoiceNo && errors.proformaInvoiceNo}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    placeholder="Enter Date"
                                    type="date"
                                    name="date"
                                    fieldLabel="Date"
                                    readOnly={true}
                                    value={values.date}
                                    onChange={handleChange}
                                    sx={{
                                        backgroundColor: "#E9ECEF"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    placeholder="Enter Due Date"
                                    type="date"
                                    name="dueDate"
                                    fieldLabel="Due Date"
                                    required={true}
                                    value={values.dueDate}
                                    onChange={handleChange}
                                    error={touched.dueDate && !!errors.dueDate}
                                    helperText={touched.dueDate && errors.dueDate}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.currencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('currencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Currency"
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}
                                />
                            </Grid>
                        </Grid>
                        <FieldArray
                            name="particulars"
                            render={arrayHelpers => (
                                <>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={6}>
                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <AssignmentLateOutlinedIcon />
                                                <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                                    Particulars
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Box style={{
                                            overflowX: 'auto', overflowY: 'auto'
                                        }}>
                                            <Table style={{ width: "100%" }}>
                                                <TableHead>
                                                    <TableRow style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                                                        <TableCell style={{ ...styles.tablecell, width: "50px" }} rowSpan={2}>No.</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "300px" }} rowSpan={2}>Particular</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Qty</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Rate</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Unit</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Amount</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "50px" }} rowSpan={2}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {values.particulars.map((particular, index) => {
                                                        const handleQtyChange = (e) => {
                                                            const newQty = parseFloat(e.target.value) || 0;
                                                            const newRate = parseFloat(particular.rate) || 0;
                                                            const newAmount = (newQty * newRate).toFixed(2);
                                                            setFieldValue(`particulars.${index}.qty`, newQty);
                                                            setFieldValue(`particulars.${index}.amount`, newAmount);
                                                        };
                                                        const handleRateChange = (e) => {
                                                            const newRate = parseFloat(e.target.value) || 0;
                                                            const newQty = parseFloat(particular.qty) || 0;
                                                            const newAmount = (newQty * newRate).toFixed(2);
                                                            setFieldValue(`particulars.${index}.rate`, newRate);
                                                            setFieldValue(`particulars.${index}.amount`, newAmount);
                                                        };

                                                        return (


                                                            <TableRow key={index}>
                                                                <TableCell style={styles.tablecell}>{index + 1}</TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomAutocomplete
                                                                        options={productsList || []}
                                                                        required={true}
                                                                        getOptionLabel={(option) => option.productName || ''}
                                                                        value={productsList?.find((option) => option.productsId === particular?.particular) || null}
                                                                        onChange={(e, newValue) => {
                                                                            setFieldValue(`particulars.${index}.particular`, newValue ? newValue.productsId : '');
                                                                            setFieldValue(`particulars.${index}.unit`, newValue ? newValue.productsUnit?.productsUnitId : '');
                                                                        }}
                                                                        placeholder="Select Product Name"
                                                                    />
                                                                    <CustomTextarea
                                                                        type="text"
                                                                        placeholder="Write Product Description here..."
                                                                        name={`particulars.${index}.description`}
                                                                        value={particular.description || ''}
                                                                        onChange={handleChange}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        name={`particulars.${index}.qty`}
                                                                        type="number"
                                                                        value={particular.qty || ''}
                                                                        onChange={handleQtyChange}
                                                                        placeholder="Qty"
                                                                        error={touched.particulars?.[index]?.qty && !!errors.particulars?.[index]?.qty}
                                                                        helperText={touched.particulars?.[index]?.qty && errors.particulars?.[index]?.qty}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        name={`particulars.${index}.rate`}
                                                                        type="number"
                                                                        value={particular.rate || ''}
                                                                        onChange={handleRateChange}

                                                                        placeholder="Rate"
                                                                        error={touched.particulars?.[index]?.rate && !!errors.particulars?.[index]?.rate}
                                                                        helperText={touched.particulars?.[index]?.rate && errors.particulars?.[index]?.rate}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomAutocomplete
                                                                        options={productsList?.map((product) => product.productsUnit) || []}
                                                                        readOnly={true}
                                                                        getOptionLabel={(option) => option.unitName || ''}
                                                                        value={productsList
                                                                            ?.find((option) => option.productsId === particular?.particular)
                                                                            ?.productsUnit || null}
                                                                        onChange={(e, newValue) =>
                                                                            setFieldValue(`particulars.${index}.unit`, newValue ? newValue.productsUnitId : '')
                                                                        }
                                                                        placeholder="Unit"
                                                                        sx={{
                                                                            backgroundColor: "#E9ECEF"
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        name={`particulars.${index}.amount`}
                                                                        type="number"
                                                                        value={particular.amount || ''}
                                                                        onChange={handleChange}
                                                                        placeholder="Amount"
                                                                        sx={{
                                                                            backgroundColor: "#E9ECEF"
                                                                        }}
                                                                        readOnly={true}
                                                                    />
                                                                </TableCell>

                                                                <TableCell style={styles.tablecell}>
                                                                    <IconButton onClick={() => arrayHelpers.remove(index)} color="error">
                                                                        <DeleteRounded />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>

                                                <TableRow style={{ height: '40px' }}>
                                                    <TableCell style={styles.tablecell} colSpan={7}>
                                                        <div style={{ margin: "auto", textAlign: "center" }}>
                                                            <CustomButton
                                                                sx={Additemstyle}
                                                                startIcon={<AddRounded />}
                                                                title="Add Item"
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => arrayHelpers.push({
                                                                    particular: '',
                                                                    qty: '',
                                                                    rate: '',
                                                                    unit: ''
                                                                })}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ height: '40px' }}>
                                                    <TableCell style={styles.tablecellfooter} colSpan={2}  > Total</TableCell>
                                                    <TableCell style={styles.tablecellfooter}  >
                                                        {values.particulars
                                                            .reduce((total, particular) => total + (parseFloat(particular.qty) || 0), 0)
                                                        }
                                                    </TableCell>

                                                    <TableCell style={styles.tablecellfooter} colSpan={2}  > </TableCell>
                                                    <TableCell style={styles.tablecellfooter} >
                                                        {currencySymbol} &nbsp;
                                                        {values.particulars
                                                            .reduce((total, particular) => total + ((particular.qty || 0) * (particular.rate || 0)), 0)
                                                            .toFixed(2)}
                                                    </TableCell>
                                                    <TableCell style={styles.tablecellfooter} > </TableCell>
                                                </TableRow>
                                            </Table>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        />
                        <Grid container spacing={1} style={{ paddingBottom: "5px", paddingTop: "10px" }}>
                            <Grid item xs={12} md={7}>
                                <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                    In Words
                                </Typography>
                                <Typography style={{ color: "#212529", fontWeight: 400, fontSize: "14px" }}>
                                    Only
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <div>
                                    <Box display="flex" flexDirection="row">
                                        <Box display="inline-flex" alignItems="center" pr={1}>
                                            {currencySymbol}
                                        </Box>
                                        <Box display="inline-flex" alignItems="center">
                                            <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                                Summary
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Card variant="outlined" style={{ padding: "2px" }}>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Typography style={{ fontWeight: "600", fontSize: "15px", paddingLeft: "10px" }}>
                                                Grand Total:
                                            </Typography>
                                            <CustomPrefixSuffixField
                                                prefix={currencySymbol}
                                                name="totalAmount"
                                                value={values.particulars
                                                    .reduce((total, particular) => total + ((particular.qty || 0) * (particular.rate || 0)), 0)
                                                    .toFixed(2)}
                                                readOnly={true}
                                                placeholder="Total Amount"
                                                sx={CustomStyle.readOnly}
                                            />
                                        </Box>
                                    </Card>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <CustomTextarea
                                    name="note"
                                    fieldLabel="Note"
                                    placeholder="Enter Note"
                                    required={true}
                                    value={values.note}
                                    onChange={handleChange}
                                    sx={{ height: '50px !important' }}
                                    error={touched.note && !!errors.note}
                                    helperText={touched.note && errors.note}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    placeholder="Enter PI Upload Path"
                                    name="piUpload"
                                    fieldLabel="PI Upload"
                                    required={true}
                                    value={values.piUpload}
                                    onChange={handleChange}
                                    error={touched.piUpload && !!errors.piUpload}
                                    helperText={touched.piUpload && errors.piUpload}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateProformaInvoice;


const styles = {
    table: {
        minWidth: 650,
    },
    textMuted: {
        color: '#6c757d',
    },

    labelCell: {
        width: '180px',
    },
    tableContainer: {
        marginBottom: '16px',
    },
    header: {
        marginBottom: '16px',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    customTableRow: {
        height: '20px',
    },
    editButton: {
        textAlign: 'right',
    },
    showDetailsSpan: {
        padding: '8px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
        cursor: 'pointer',
    },
    expandIcon: {
        color: '#212529',
    },
    boxheading: {
        width: 70,
        height: 70,
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 14,
        boxShadow: 3,
    },
    headingname: {
        paddingTop: '10px',
        fontWeight: '600',
        fontSize: '20px',
        color: '#212529',
    },
    avatarcircle: {
        backgroundColor: '#007BFF',
        width: '30px',
        height: '30px',
        fontSize: '15px',
    },
    cardtittile: {
        padding: '8px',
        paddingLeft: '35px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
    },
    tablebutton: {
        backgroundColor: '#007bff',
        color: '#ffff',
        textTransform: 'capitalize',
        borderRadius: 4,
        border: '2px solid #007bff',
        height: 30,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tablecell: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    tablecellfooter: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'right',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },

};


const Additemstyle = {
    color: '#212529 !important',
    textTransform: 'capitalize !important',
    borderRadius: '4px !important',
    border: '1px dashed #aecce4 !important',
    height: '30px !important',
    display: 'inline-flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    backgroundColor: '#e7f0f7 !important',
    width: '100% !important',
};

import React, { useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import { genericPost } from '../../../services/authServices';
import { CREATE_EXPENSE_VOUCHER } from '../../../services/apiRoutes';
import CustomStyle from "../../common/CustomStyle";
import CustomPrefixSuffixField from "../../common/CustomPrefixSuffixField";

const ValidationSchema = Yup.object().shape({
    date: Yup.string().required('Date is required'),
    voucherNo: Yup.string().required('Payment Voucher Number is required'),
    expensesLedgerId: Yup.number().required('Expenses Ledger ID is required'),
    amount: Yup.number().required('Amount is required'),
    currencyId: Yup.number().required('Currency is required'),
    baseCurrencyId: Yup.number().required('Base Currency is required'),
    conversionRate: Yup.number().required('Conversion Rate is required'),
    amountInBaseCurrency: Yup.number().required('Amount in Base Currency is required'),
    note: Yup.string().required('Note is required'),
});

const CreateExpenseVoucher = ({ handleClose, setMessage, currencyId, baseCurrencyId, currencySymbol, baseCurrencySymbol, fetchData, currencyList, clientCompanyId, expenseLedger, commanShipmentId, newShippingId, shipmentId }) => {
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                shipmentId: values.shipmentId,
                commanShipmentId: values.commanShipmentId,
                clientCompanyId: values.clientCompanyId,
                date: values.date,
                voucherNo: values.voucherNo,
                expensesLedgerId: values.expensesLedgerId,
                amount: values.amount,
                currencyId: values.currencyId,
                baseCurrencyId: values.baseCurrencyId,
                conversionRate: values.conversionRate,
                amountInBaseCurrency: values.amountInBaseCurrency,
                note: values.note,
            };

            const response = await genericPost(CREATE_EXPENSE_VOUCHER, payload);

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Expense voucher created successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        clientCompanyId: clientCompanyId,
        commanShipmentId: commanShipmentId,
        shipmentId: shipmentId,
        currencyId: currencyId,
        voucherNo: newShippingId,
        baseCurrencyId: baseCurrencyId,
        date: '',
        amount: '',
        conversionRate: '',
        amountInBaseCurrency: '',
        expensesLedgerId: '',
        note: '',
    });

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Create Expense Voucher
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save And New"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>


                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Date"
                                    type="date"
                                    name="date"
                                    fieldLabel="Date"
                                    required={true}
                                    value={values.date}
                                    onChange={handleChange}
                                    error={touched.date && !!errors.date}
                                    helperText={touched.date && errors.date}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Enter Expense Voucher No."
                                    name="voucherNo"
                                    fieldLabel="Expense Voucher No."
                                    required={true}
                                    value={values.voucherNo}
                                    onChange={handleChange}
                                    error={touched.voucherNo && !!errors.voucherNo}
                                    helperText={touched.voucherNo && errors.voucherNo}
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={expenseLedger}
                                    fieldLabel="Select Expense Ledger"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={expenseLedger.find((option) => option.id === values.expensesLedgerId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('expensesLedgerId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Expenses Ledger"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Select Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.currencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('currencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Currency"
                                    error={touched.currencyId && !!errors.currencyId}
                                    helperText={touched.currencyId && errors.currencyId}
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <CustomPrefixSuffixField
                                    prefix={currencySymbol}
                                    placeholder="Enter Amount"
                                    name="amount"
                                    fieldLabel="Amount"
                                    required={true}
                                    type="number"
                                    value={values.amount}
                                    onChange={(e) => {
                                        const amount = e.target.value;
                                        setFieldValue('amount', amount);
                                        setFieldValue('amountInBaseCurrency', amount * values.conversionRate || 0);
                                    }}
                                    error={touched.amount && !!errors.amount}
                                    helperText={touched.amount && errors.amount}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Select Base Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.baseCurrencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('baseCurrencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Base Currency"
                                    error={touched.baseCurrencyId && !!errors.baseCurrencyId}
                                    helperText={touched.baseCurrencyId && errors.baseCurrencyId}
                                    sx={CustomStyle.readOnly}
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomPrefixSuffixField
                                    prefix={baseCurrencySymbol}
                                    tagLine="(In Base)"
                                    placeholder="Conversion Rate"
                                    name="conversionRate"
                                    fieldLabel="Conversion Rate"
                                    required={true}
                                    type="number"
                                    value={values.conversionRate}
                                    onChange={(e) => {
                                        const conversionRate = e.target.value;
                                        setFieldValue('conversionRate', conversionRate);
                                        setFieldValue('amountInBaseCurrency', values.amount * conversionRate || 0);
                                    }}
                                    error={touched.conversionRate && !!errors.conversionRate}
                                    helperText={touched.conversionRate && errors.conversionRate}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomPrefixSuffixField
                                    prefix={baseCurrencySymbol}
                                    tagLine="(In Base Currency)"
                                    placeholder="Enter Amount"
                                    name="amountInBaseCurrency"
                                    fieldLabel="Amount"
                                    required={true}
                                    type="number"
                                    value={values.amountInBaseCurrency}
                                    onChange={handleChange}
                                    error={touched.amountInBaseCurrency && !!errors.amountInBaseCurrency}
                                    helperText={touched.amountInBaseCurrency && errors.amountInBaseCurrency}
                                    readOnly={true}
                                    sx={CustomStyle.readOnly}

                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <CustomTextarea
                                    name="note"
                                    fieldLabel="Note"
                                    required={true}
                                    value={values.note}
                                    onChange={handleChange}
                                    sx={{ height: '100px !important' }}
                                    error={touched.note && !!errors.note}
                                    helperText={touched.note && errors.note}
                                    placeholder="Enter Note"

                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateExpenseVoucher;


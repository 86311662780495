import React from 'react'
import { useState, useEffect, useContext } from "react"
import CustomModal from '../common/CustomModal'
import AddCompany from '../MyCompany/AddCompany'
import { getCountries, getStates, } from "../../services/CommonServices";
import { SET_DEFAULT_COMPANY } from "../../services/apiRoutes"
import { genericPut } from "../../services/apiServices"
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import SubscriptionPlans from '../../Pages/Subscription';

const SubscriptionPlan = () => {
  const [addCompanyModal, setAddCompanyModal] = useState(true);
  const [subscriptionModal,setSubscriptionModal] = useState(true)
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false)

  const { login } = useContext(AuthContext)





  return (
    <div>
      <CustomModal open={subscriptionModal}>
        <SubscriptionPlans isFirstTimeLogin={true}/>
      </CustomModal>
    </div>
  )
}

export default SubscriptionPlan
import React, { useMemo, useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import DataTable from "../../../components/common/DataTable";
import { genericGet } from "../../../services/apiServices";
import { READ_IMPORT_SHIPMENT_COSTING } from "../../../services/apiRoutes";
import { AuthContext } from "../../../context/AuthContext";
import CustomLoader from "../../common/CustomLoader";
import axios from "axios";
import CustomReport from "../../common/CustomReport";

const title = "Import Shipment Costing Booking Wise";

const ImportShipmentCostingReportView = () => {
    const { supplierNamesId } = useParams();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'etaDate', header: 'Date', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'piNumber', header: 'PI No.', size: 150, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'clientCompanyName', header: 'Supplier Name', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'mailingCountry', header: 'Country', size: 100, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'numberOfContainers', header: 'No. Of Container', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'totalQty', header: 'Total Qty', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'piAmount', header: 'PI Amount', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'piAmountBaseCurrency', header: 'PI Amount in Base Currency', size: 200, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'advanceAmount', header: 'Advance Amount', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'advanceAmountBaseCurrency', header: 'Advance Amount in Base Currency', size: 200, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'finalAmount', header: 'Final Amount', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'finalAmountBaseCurrency', header: 'Final Amount in Base Currency', size: 200, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'bankCharges', header: 'Bank Charges', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'seaFreight', header: 'Sea Freight', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'seaFreightBaseCurrency', header: 'Sea Freight in Base Currency', size: 200, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'shippingLineCharges', header: 'Shipping Line Charges', size: 200, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'freightForwardAgency', header: 'Forward Agency Charges', size: 200, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'customClearingAgency', header: 'Clearing Agency Charges', size: 200, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'customDuty', header: 'Custom Duty', size: 150 },
            { accessorKey: 'tax', header: 'Tax', size: 100, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'miscellaneousExpenses', header: 'Miscellaneous Expenses', size: 200, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'totalExpenses', header: 'Total Expenses', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'costPerUnit', header: 'Cost per Unit', size: 150, tableStyle: { textAlign: "center" }, },
        ],
        []
    );

    const fetchData = async (supplierId) => {
        try {
            setLoading(true);
            const accessToken = sessionStorage.getItem("accessToken");
            const response = await axios.get(READ_IMPORT_SHIPMENT_COSTING(supplierId), {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.data.success && response.data.shippingBooking) {
                const shippingBookingData = response.data.shippingBooking.map((booking, index) => {
                    const etaDate = new Date(booking.etaDate);
                    etaDate.setDate(etaDate.getDate() + 14); // Calculate due date
                    const dueDate = etaDate.toISOString().split("T")[0];

                    return {
                        shippingBookingId: booking.shippingBookingId || "",
                        index: (index + 1).toString().padStart(2, '0'),
                        clientCompanyName: booking.clientCompany?.clientCompanyName || "",
                        mailingCountry: booking.clientCompany?.mailingCountry?.name || "",
                        freightForwardAgency: booking.freightForwardAgency?.agencyName || "",
                        customClearingAgency: booking.customClearingAgency?.chaName || "",
                        country: booking.clientCompany?.mailingCountry?.name || "",
                        portOfLoading: booking.portOfLoading || "",
                        portOfDestination: booking.portOfDestination || "",
                        numberOfContainers: booking.numberOfContainers || "",
                        etaDate: booking.etaDate || "",
                        etdDate: booking.etdDate || "",
                        seaFreight: booking.seaFreight || "",
                        dueDate, // Add calculated due date
                        destinationCharges: booking.destinationCharges || "",
                        shippingLineCharges: booking.shippingLineCharges || "",
                    };
                });
                setData(shippingBookingData);
            } else {
                setError("No shipping bookings found.");
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (!supplierNamesId) return;
        setLoading(true);
        fetchData(supplierNamesId);
    }, [supplierNamesId, token]);


    const defaultView = 'importshipmentcostingreport';
    const mainContent = (
        <>
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
            />
        </>
    );

    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>

    );
};

export default ImportShipmentCostingReportView;

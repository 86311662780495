import React, { useState, useEffect, useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import CustomModal from '../../common/CustomModal'; // Assuming you have a CustomModal component
import { genericGet } from '../../../services/apiServices';
import { Delete_call_id, GET_call, GET_call_LIST } from '../../../services/apiRoutes';
import { getIndustryType, getRating, getLeadSource, getLeadStatus, getCountries, getBusinessGroup, getCompanyTypes, getCallPurposeType, getCompanyOwnershipType, getCallOutcomeType, getPrioritiesLossReasons, getCompanyModules, getContactDepartments, getContactGroups, getContactTypes, getModules, getTaskStatuses, getLeadStages, getPriorities, getCompanies } from '../../../services/CommonServices'

import CustomDelete from '../../common/CustomDeleteAlert'

import axios from 'axios';
import MessageBox from '../../common/CustomMessageBox';
import CustomLoader from '../../common/CustomLoader';
import { genericPost } from '../../../services/authServices';
import CreateImportCall from '../CreateForm/CreateImportCall';
import UpdateImportCall from '../UpdateForm/UpdateImportCall';
import CallView from '../ViewDetails/CallView';

export function ImportCall({ data, importShipmentId }) {
    const [callData, setCallData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedCall, setSelectedCall] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [rowId, setRowId] = useState('')
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })
    const [viewModal, setViewModal] = useState(false);


    const [leadSource, setLeadSource] = useState([]);
    const [leadStatus, setLeadStatus] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [callPurposeTypes, setCallPurposeTypes] = useState([]);
    const [callOutcomeTypes, setCallOutcomeTypes] = useState([]);
    const [prioritiesLossReasons, setPrioritiesLossReasons] = useState([]);
    const [taskStatuses, setTaskStatuses] = useState([]);
    const [leadStages, setLeadStages] = useState([]);
    const [priorities, setPriorities] = useState([]);

    const getCommonData = async () => {
        const leadSourceData = await getLeadSource();
        setLeadSource(leadSourceData);
        const leadStatusData = await getLeadStatus();
        setLeadStatus(leadStatusData);
        const companiesData = await getCompanies();
        setCompanies(companiesData);
        const companyTypesData = await getCompanyTypes();
        setCompanyTypes(companyTypesData);
        const callPurposeData = await getCallPurposeType();
        setCallPurposeTypes(callPurposeData);
        const callOutcomeData = await getCallOutcomeType();
        setCallOutcomeTypes(callOutcomeData);
        const prioritiesLossReasonsData = await getPrioritiesLossReasons();
        setPrioritiesLossReasons(prioritiesLossReasonsData);
        const taskStatusesData = await getTaskStatuses();
        setTaskStatuses(taskStatusesData);
        const leadStagesData = await getLeadStages();
        setLeadStages(leadStagesData);
        const prioritiesData = await getPriorities();
        setPriorities(prioritiesData);
    };
    const handleOpenView = (callId) => {
        setSelectedCall(callId);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setSelectedCall('');
    };

    const handleViewClick = (callId) => {
        handleOpenView(callId);
        setSelectedCall(callId);
    };

    const handleOpenCreate = () => {
        setCreateModalOpen(true);
    };
    const handleCloseCreate = () => {
        setCreateModalOpen(false);
    };

    const handleOpenUpdate = (callId) => {
        setSelectedCall(callId);
        setUpdateModalOpen(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModalOpen(false);
        setSelectedCall(null);
    };

    const handleUpdateClick = (call) => {
        handleOpenUpdate(call);
    };



    const handleDelete = async (callId) => {
        setLoading(true)
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(Delete_call_id(callId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                fetchData()
                setDeleteAlert(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error deleting lead", error);
        }
    };

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const payload = {
                clientCompanyId: data.supplierId,
                importShipmentId: importShipmentId,
            };
            const response = await genericPost(GET_call_LIST, payload);
            if (response.data.success && response.data.calls) {
                const mappedCalls = response.data.calls.map((call, index) => ({
                    callId: call.callId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    companyId: call.companyId || '',
                    userId: call.userId || '',
                    contactId: call.contactId || '',
                    executiveId: call.executiveId || '',
                    callingType: call.callingType || '',
                    clientCompanyId: call.clientCompanyId || '',
                    subject: call.subject || '',
                    callType: call.callType || '',
                    callDetails: call.callDetails || '',
                    callStartTime: call.callStartTime || '',
                    callDuration: call.callDuration || '',
                    callPurpose: call.callPurpose?.callPurposeName || '',
                    callOutcome: call.callOutcome?.callOutcomeName || '',
                    stage: call.stage?.stageName || '',
                    priority: call.priority?.priorityName || '',
                    description: call.description || '',
                    leadStatus: call.leadStatus?.leadStatusName || '',
                    lossReason: call.lossReason?.lossReasonName || '',
                    executiveName: `${call.executive?.firstName || ''} ${call.executive?.lastName || ''}`,
                }));

                setCallData(mappedCalls);
            } else {
                setError('No calls found.');
            }
        } catch (err) {
            setError('Error fetching call data.');
            console.error('Error fetching call data:', err);
        } finally {
            setLoading(false);
        }
    };
    const handleOpenDeleteAlert = (callId) => {
        setDeleteAlert(true)
        setRowId(callId)
    }
    useEffect(() => {
        fetchData();
        getCommonData()
    }, []);


    if (loading) return <p>   <CustomLoader open={loading} /> </p>;
    if (error) return <p>{error}</p>;

    const CallColumn = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        { id: 'callTo', label: 'Call To', accessor: 'callTo' },
        { id: 'callType', label: 'Call Type', accessor: 'callType' },
        { id: 'subject', label: 'Subject', accessor: 'subject' },
        { id: 'details', label: 'Details', accessor: 'callDetails' },
        {
            id: 'startTime', label: 'Start Time', accessor: 'callStartTime', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'duration', label: 'Duration', accessor: 'callDuration', styleAccessor: {
                textAlign: 'center',
            },
        },
        { id: 'purpose', label: 'Purpose', accessor: 'callPurpose' },
        { id: 'outcome', label: 'Outcome', accessor: 'callOutcome' },
        { id: 'description', label: 'Description', accessor: 'description' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton onClick={() => handleViewClick(row.callId)} style={{ marginRight: '1px' }}>
                            <VisibilityIcon style={{ color: "#059212" }} />
                        </IconButton>
                        <IconButton onClick={() => handleUpdateClick(row.callId)} style={{ marginRight: '1px' }}>
                            <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteAlert(row.callId)}>
                            <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                        </IconButton>
                    </div>

                </>
            ),
        },
    ];

    return (
        <>
            <DetailsPageCard
                Icon={WifiCalling3OutlinedIcon}
                columns={CallColumn}
                data={callData}
                title={'Call'}
                buttonTitle={'Create Call'}
                handleOpen={handleOpenCreate}
            />
            <CustomModal open={createModalOpen} handleClose={handleCloseCreate}>
                <CreateImportCall
                    fetchData={fetchData}
                    handleClose={handleCloseCreate}
                    setMessage={setMessage}
                    leadSource={leadSource}
                    leadStatus={leadStatus}
                    companies={companies}
                    companyTypes={companyTypes}
                    callPurposeTypes={callPurposeTypes}
                    callOutcomeTypes={callOutcomeTypes}
                    prioritiesLossReasons={prioritiesLossReasons}
                    taskStatuses={taskStatuses}
                    leadStages={leadStages}
                    priorities={priorities}
                    data={data}
                    importShipmentId={importShipmentId}
                />
            </CustomModal>

            <CustomModal open={updateModalOpen} handleClose={handleCloseUpdate}>
                <UpdateImportCall
                    fetchData={fetchData}
                    handleClose={handleCloseUpdate}
                    callId={selectedCall}
                    setMessage={setMessage}
                    leadSource={leadSource}
                    leadStatus={leadStatus}
                    companies={companies}
                    companyTypes={companyTypes}
                    callPurposeTypes={callPurposeTypes}
                    callOutcomeTypes={callOutcomeTypes}
                    prioritiesLossReasons={prioritiesLossReasons}
                    taskStatuses={taskStatuses}
                    leadStages={leadStages}
                    priorities={priorities}
                    CleintCompanyData={data}
                />
            </CustomModal>
            <CustomModal
                open={viewModal}
                modalStyles={{
                    maxHeight: '50vh',
                    top: "10%",
                }}
                handleClose={handleCloseView}>
                <CallView
                    handleClose={handleCloseView}
                    fetchData={fetchData}
                    callId={selectedCall}
                    setMessage={setMessage}
                    CleintCompanyData={data}
                />
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}

            />
        </>
    );
}

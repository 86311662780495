import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider, Card, TableCell, TableRow, TableHead, Table, TableBody } from '@mui/material';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import { UPDATE_PACKING_LIST_EXPORT, READ_PACKING_LIST_EXPORT } from '../../../services/apiRoutes';
import CustomStyle from "../../common/CustomStyle";

// Validation schema
const ValidationSchema = Yup.object().shape({
    addressTo: Yup.string().required('Address is required'),
    invNo: Yup.string().required('Invoice No. is required'),
    date: Yup.string().required('Date is required'),
    loadingPort: Yup.string().required('Loading Port is required'),
    destinationPort: Yup.string().required('Destination Port is required'),
    mark: Yup.string().required('Mark is required'),
    description: Yup.string().required('Description is required'),
    quantity: Yup.number().required('Quantity is required').positive('Quantity must be positive'),
    pkg: Yup.string().required('Package information is required'),
    nw: Yup.number().required('Net weight is required').positive('Net weight must be positive'),
    tw: Yup.number().required('Tare weight is required').positive('Tare weight must be positive'),
    measurement: Yup.string().required('Measurement is required'),
    totalPackedIn: Yup.number().required('Total Packed In is required').positive('Total Packed In must be positive'),
    totalGrossWeight: Yup.number().required('Total Gross Weight is required').positive('Total Gross Weight must be positive'),
    totalNetWeight: Yup.number().required('Total Net Weight is required').positive('Total Net Weight must be positive'),
    casNo: Yup.string().required('CAS No. is required'),
    iupac: Yup.string().required('IUPAC name is required'),
});


const UpdatePackingList = ({ handleClose, setMessage, invoiceList, fetchData, packingListId, companyName, phoneNumber }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!packingListId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_PACKING_LIST_EXPORT(packingListId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [packingListId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_PACKING_LIST_EXPORT(packingListId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "updated successfully",
                    type: "success",
                });
                fetchData();
                setTimeout(() => {
                    handleClose();
                },);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    addressTo: data?.addressTo || '',
                    invNo: data?.invNo || '',
                    date: data?.date || '',
                    loadingPort: data?.loadingPort || '',
                    destinationPort: data?.destinationPort || '',
                    mark: data?.mark || '',
                    description: data?.description || '',
                    quantity: data?.quantity || '',
                    pkg: data?.pkg || '',
                    nw: data?.nw || '',
                    tw: data?.tw || '',
                    measurement: data?.measurement || '',
                    totalPackedIn: data?.totalPackedIn || '',
                    totalGrossWeight: data?.totalGrossWeight || '',
                    totalNetWeight: data?.totalNetWeight || '',
                    casNo: data?.casNo || '',
                    iupac: data?.iupac || '',

                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Packing List
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>


                        <Divider />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                                    <Typography style={{ color: "#212529", fontWeight: 500, fontSize: "15px", textTransform: "uppercase" }}>
                                        Packing List
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container alignItems="center" spacing={1} >
                                    <Grid xs={2} sm={2} md={2} lg={2}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", }}>
                                            To:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={8} sm={8} md={8} lg={8}>
                                        <CustomTextarea
                                            name="addressTo"
                                            value={values.addressTo}
                                            readOnly={true}
                                            onChange={handleChange}
                                            placeholder="Enter Means Of Transport And Route"
                                            error={touched.addressTo && !!errors.addressTo}
                                            helperText={touched.addressTo && errors.addressTo}
                                            sx={{ height: '85px !important', textTransform: 'capitalize', ...CustomStyle.readOnly }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
                                    <Box sx={{ width: '130px' }}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", marginRight: '16px' }}>
                                            Inv. No:
                                        </Typography>
                                    </Box>
                                    {/* <CustomTextField
                                        name="invNo"
                                        value={values.invNo}
                                        required={true}
                                        onChange={handleChange}
                                        placeholder="Enter Invoice No"
                                        error={touched.invNo && !!errors.invNo}
                                        helperText={touched.invNo && errors.invNo}
                                        sx={{ width: "200px !important", ...CustomStyle.readOnly }}
                                        readOnly={true}
                                    /> */}
                                    <CustomAutocomplete
                                        options={invoiceList}
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={invoiceList.find((option) => option.id === values.invNo) || null}
                                        onChange={(e, newValue) =>
                                            setFieldValue('invNo', newValue ? newValue.id : '')
                                        }
                                        placeholder="Select invNo"
                                        error={touched.invNo && !!errors.invNo}
                                        helperText={touched.invNo && errors.invNo}
                                        sx={{ width: "200px !important", }}
                                    />

                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
                                    <Box sx={{ width: '130px' }}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", marginRight: '16px' }}>
                                            Date:
                                        </Typography>
                                    </Box>
                                    <CustomTextField
                                        type="date"
                                        name="date"
                                        value={values.date}
                                        required={true}
                                        onChange={handleChange}
                                        placeholder="Enter Date"
                                        error={touched.date && !!errors.date}
                                        helperText={touched.date && errors.date}
                                        sx={{ width: "200px !important" }}

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container alignItems="center" spacing={1} >
                                    <Grid xs={4} sm={4} md={4} lg={4}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", }}>
                                            Loading Port:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={8} sm={8} md={8} lg={8}>
                                        <CustomTextField
                                            name="loadingPort"
                                            value={values.loadingPort}
                                            required={true}
                                            onChange={handleChange}
                                            placeholder="Enter Loading Port"
                                            error={touched.loadingPort && !!errors.loadingPort}
                                            helperText={touched.loadingPort && errors.loadingPort}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container alignItems="center" spacing={1} style={{ marginLeft: "5px" }}>
                                    <Grid xs={4} sm={4} md={4} lg={4}>
                                        <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", }}>
                                            Destination Port:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={8} sm={8} md={8} lg={8}>
                                        <CustomTextField
                                            name="destinationPort"
                                            value={values.destinationPort}
                                            required={true}
                                            onChange={handleChange}
                                            placeholder="Enter Destination Port"
                                            error={touched.destinationPort && !!errors.destinationPort}
                                            helperText={touched.destinationPort && errors.destinationPort}
                                            sx={{ width: "200px !important" }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box style={{ overflowX: 'auto', overflowY: 'auto' }}>
                                <Table style={{ width: "100%" }}>
                                    <TableHead>
                                        <TableRow style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                                            <TableCell style={{ ...styles.tablecell, ...styles.otherCells }}>Mark</TableCell>
                                            <TableCell style={{ ...styles.tablecell, ...styles.description }}>Description</TableCell>
                                            <TableCell style={{ ...styles.tablecell, ...styles.otherCells }}>Quantity</TableCell>
                                            <TableCell style={{ ...styles.tablecell, ...styles.otherCells }}>PKG</TableCell>
                                            <TableCell style={{ ...styles.tablecell, ...styles.otherCells }}>N.W.</TableCell>
                                            <TableCell style={{ ...styles.tablecell, ...styles.otherCells }}>T.W.</TableCell>
                                            <TableCell style={{ ...styles.tablecell, ...styles.otherCells }}>Measurement</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow >
                                            <TableCell style={styles.tablecell}>
                                                <CustomTextarea
                                                    name="mark"
                                                    value={values.mark}
                                                    required={true}
                                                    onChange={handleChange}
                                                    error={touched.mark && !!errors.mark}
                                                    helperText={touched.mark && errors.mark}
                                                    placeholder="Enter Mark"
                                                    sx={{
                                                        height: "250px !important"
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={styles.tablecell}>
                                                <CustomTextarea
                                                    name="description"
                                                    value={values.description}
                                                    required={true}
                                                    onChange={handleChange}
                                                    placeholder="Enter Description"
                                                    error={touched.description && !!errors.description}
                                                    helperText={touched.description && errors.description}
                                                    sx={{
                                                        height: "250px !important"
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={styles.tablecell}>
                                                <CustomTextarea
                                                    name="quantity"
                                                    value={values.quantity}
                                                    required={true}
                                                    onChange={handleChange}
                                                    placeholder="Enter Quantity"
                                                    error={touched.quantity && !!errors.quantity}
                                                    helperText={touched.quantity && errors.quantity}
                                                    sx={{
                                                        height: "250px !important"
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={styles.tablecell}>
                                                <CustomTextarea
                                                    name="pkg"
                                                    value={values.pkg}
                                                    required={true}
                                                    onChange={handleChange}
                                                    placeholder="Enter Pkg"
                                                    error={touched.pkg && !!errors.pkg}
                                                    helperText={touched.pkg && errors.pkg}
                                                    sx={{
                                                        height: "250px !important"
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={styles.tablecell}>
                                                <CustomTextarea
                                                    name="nw"
                                                    value={values.nw}
                                                    required={true}
                                                    onChange={handleChange}
                                                    placeholder="Enter Net Weight"
                                                    error={touched.nw && !!errors.nw}
                                                    helperText={touched.nw && errors.nw}
                                                    sx={{
                                                        height: "250px !important"
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={styles.tablecell}>
                                                <CustomTextarea
                                                    name="tw"
                                                    value={values.tw}
                                                    required={true}
                                                    onChange={handleChange}
                                                    placeholder="Enter Total Weight"
                                                    error={touched.tw && !!errors.tw}
                                                    helperText={touched.tw && errors.tw}
                                                    sx={{
                                                        height: "250px !important"
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell style={styles.tablecell}>
                                                <CustomTextarea
                                                    name="measurement"
                                                    value={values.measurement}
                                                    required={true}
                                                    onChange={handleChange}
                                                    placeholder="Enter Measurement"
                                                    error={touched.measurement && !!errors.measurement}
                                                    helperText={touched.measurement && errors.measurement}
                                                    sx={{
                                                        height: "250px !important"
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    <TableRow style={{ height: '40px' }}>
                                        <TableCell style={styles.tablecellfooter} colSpan={2}  > Total</TableCell>
                                        <TableCell style={styles.tablecellfooter}  > 00</TableCell>
                                        <TableCell style={styles.tablecellfooter}  >00 </TableCell>
                                        <TableCell style={styles.tablecellfooter} > 00</TableCell>
                                        <TableCell style={styles.tablecellfooter} > 00</TableCell>
                                        <TableCell style={styles.tablecellfooter} >00 </TableCell>
                                    </TableRow>
                                </Table>
                            </Box>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", marginRight: "10px", width: "200px" }}>
                                        Total Packed In:
                                    </Typography>
                                    <CustomTextField
                                        name="totalPackedIn"
                                        value={values.totalPackedIn}
                                        required={true}
                                        onChange={handleChange}
                                        placeholder="Enter Total Packed In"
                                        error={touched.totalPackedIn && !!errors.totalPackedIn}
                                        helperText={touched.totalPackedIn && errors.totalPackedIn}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", marginRight: "10px", width: "200px" }}>
                                        Total Gross Weight:
                                    </Typography>
                                    <CustomTextField
                                        name="totalGrossWeight"
                                        value={values.totalGrossWeight}
                                        required={true}
                                        onChange={handleChange}
                                        placeholder="Enter Total Gross Weight"
                                        error={touched.totalGrossWeight && !!errors.totalGrossWeight}
                                        helperText={touched.totalGrossWeight && errors.totalGrossWeight}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", marginRight: "10px", width: "200px" }}>
                                        Total Net Weight:
                                    </Typography>
                                    <CustomTextField
                                        name="totalNetWeight"
                                        value={values.totalNetWeight}
                                        required={true}
                                        onChange={handleChange}
                                        placeholder="Enter Total Net Weight"
                                        error={touched.totalNetWeight && !!errors.totalNetWeight}
                                        helperText={touched.totalNetWeight && errors.totalNetWeight}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", marginRight: "10px", width: "200px" }}>
                                        CAS No:
                                    </Typography>
                                    <CustomTextField
                                        name="casNo"
                                        value={values.casNo}
                                        required={true}
                                        onChange={handleChange}
                                        placeholder="Enter CAS No"
                                        error={touched.casNo && !!errors.casNo}
                                        helperText={touched.casNo && errors.casNo}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box display="flex" alignItems="center">
                                <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", marginRight: "10px", width: "200px" }}>
                                    IUPAC:
                                </Typography>
                                <CustomTextField
                                    name="iupac"
                                    value={values.iupac}
                                    required={true}
                                    onChange={handleChange}
                                    placeholder="Enter IUPAC"
                                    error={touched.iupac && !!errors.iupac}
                                    helperText={touched.iupac && errors.iupac}
                                />
                            </Box>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdatePackingList;


const styles = {
    table: {
        minWidth: 650,
    },
    textMuted: {
        color: '#6c757d',
    },

    labelCell: {
        width: '180px',
    },
    tableContainer: {
        marginBottom: '16px',
    },
    header: {
        marginBottom: '16px',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    customTableRow: {
        height: '20px',
    },
    editButton: {
        textAlign: 'right',
    },
    showDetailsSpan: {
        padding: '8px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
        cursor: 'pointer',
    },
    expandIcon: {
        color: '#212529',
    },
    boxheading: {
        width: 70,  // Width of the box
        height: 70, // Height of the box, making it square
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)', // Radial gradient background
        color: 'white', // Text color
        display: 'flex', // Center content horizontally
        alignItems: 'center', // Center content vertically
        justifyContent: 'center', // Center content horizontally
        borderRadius: 14, // Rounded corners
        boxShadow: 3, // Box shadow for depth
    },
    headingname: {
        paddingTop: '10px',
        fontWeight: '600',
        fontSize: '20px',
        color: '#212529',
    },
    avatarcircle: {
        backgroundColor: '#007BFF',
        width: '30px',
        height: '30px',
        fontSize: '15px',
    },
    cardtittile: {
        padding: '8px',
        paddingLeft: '35px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
    },
    tablebutton: {
        backgroundColor: '#007bff',
        color: '#ffff',
        textTransform: 'capitalize',
        borderRadius: 4,
        border: '2px solid #007bff',
        height: 30,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tablecell: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    tablecellfooter: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'right',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },

    description: {
        width: '30%',
    },
    otherCells: {
        width: `${70 / 6}%`,
    },
};

import React from 'react';
import CustomReport from '../common/CustomReport';
import { Typography } from '@mui/material';

const ShipmentCostingReport = () => {

    const mainContent = (
        <Typography>
            shipment costing report
        </Typography>
    );

    const defaultView = 'shipmentcostingreport';


    return <CustomReport
        defaultView={defaultView}
        mainContent={mainContent} />;

};

export default ShipmentCostingReport;

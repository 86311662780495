import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { Card, Typography, Divider } from "@mui/material";
import CustomButton from "../../common/CustomButton";
import {
    ClearRounded,
    SaveRounded,
} from "@mui/icons-material";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


export default function CreateNotes() {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const onEditorStateChange = (state) => {
        setEditorState(state);
    };
    const convertToHTMLContent = () => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = stateToHTML(contentState);
        return htmlContent;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const htmlContent = convertToHTMLContent();
        const formData = {
            notes: htmlContent,
        };
        console.log(formData);
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <Typography
                            sx={{ fontWeight: 600 }}
                            style={{ color: "#212529", fontSize: "18px" }}
                        >
                            Create Notes
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box style={{ display: "flex" }}>
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save"
                                size="small"
                                variant="contained"
                                type="submit"
                            />
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save and New"
                                size="small"
                                variant="contained"
                            />
                            <CustomButton
                                startIcon={<ClearRounded />}
                                title="Cancel"
                                size="small"
                                variant="contained"
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Divider />

                <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                    <Grid item xs={12}>
                        <Editor
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            wrapperStyle={{
                                border: '1px solid #CED4DA',
                                borderRadius: '4px',
                            }}
                            editorStyle={{ padding: "10px" }}
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

import React, { useEffect, useState } from 'react';
import DetailsPageAppbar from '../common/DetailsPage/DetailsPageAppbar';
import { Main } from '../../constants/DetailsMain';
import { Call } from './OverViewMenu/Call';
import { Tasks } from './OverViewMenu/Tasks';
import { Notes } from './OverViewMenu/Notes';
import { useParams } from 'react-router-dom';
import { GET_Lead_leadId } from '../../services/apiRoutes';
import axios from 'axios';
import LeadDetail from './OverViewMenu/LeadDetail';
import { useNavigate } from 'react-router-dom';
import CustomCollapse from '../common/CustomCollapse';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CustomLoader from '../common/CustomLoader';



export default function PersistentDrawerLeft() {
    const { leadId } = useParams();
    const navigate = useNavigate();  // Initialize useNavigate

    const [open, setOpen] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState('Overview');
    const [companyDetails, setCompanyDetails] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state



    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const AppBarMenu = [
        { menu: 'Overview', to: `/app/leads/${leadId}` },
    ];

    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');
        setLoading(true); // Start loading


        axios.get(GET_Lead_leadId(leadId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setCompanyDetails(response.data.lead);
            })
            .catch(error => {
                console.error('Error fetching company details:', error);
            })
            .finally(() => {
                setLoading(false); // Stop loading
            });
    }, [leadId]);


    if (loading) {
        return <CustomLoader open={true} />;
    }

    if (!companyDetails) {
        return <p>No company details available.</p>;
    }

    const {
        clientCompany,
        user,
    } = companyDetails;


    const clientCompanyName = clientCompany?.clientCompanyName;
    const phoneNumber = clientCompany?.mobile;
    const initial = clientCompanyName.charAt(0).toUpperCase();

    const handleBackClick = () => {
        navigate("/app/leads");
    };

    const adminData = [
        {
            title: "Lead Owner",
            defaultExpanded: false,
            details: [
                { label: 'Lead Owner', value: `${user.firstName || ''} ${user.lastName || ''}`.trim() },
                { label: 'Email', value: user.email, sm: 12, md: 12 },
                { label: 'Mobile', value: user.mobile, sm: 12, md: 12 },

            ],
        },
    ];

    return (
        <>
            <div>

                <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={AppBarMenu}
                    companyName={clientCompanyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}
                />

                <Main open={open}>
                    <LeadDetail />
                    <br />
                    <Call leadId={leadId} data={clientCompany} />
                    <br />
                    <Tasks leadId={leadId} data={clientCompany} />
                    <br />
                    <Notes />
                    <br />
                    <CustomCollapse Icon={PersonOutlineOutlinedIcon} data={adminData} />

                    <br />
                </Main>
            </div>
        </>
    );
}

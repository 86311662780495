import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  MRT_GlobalFilterTextField,
  MRT_FilterOptionMenu,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import { mkConfig, generateCsv, asBlob } from "export-to-csv";
import { Box, Button, IconButton, Typography, Card } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { AddCircle, Backup, AddCircleOutline } from "@mui/icons-material";
import CustomButton from "./CustomButton";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useLocation } from "react-router-dom";
import {hasCreatePermission,hasEditPermission,hasDeletePermission} from '../../services/CommonServices'
const DataTable = ({ title, buttonTitle, columns, data, handleOpen, handleFilterButton, fiterButtonTitile,tableStyle }) => {
  // CSV configuration
  const [permission,setPermissions] = useState()
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  const location = useLocation();
  const currentPath = location.pathname;

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    const blob = asBlob(csvConfig)(csv);
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "test.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };


  
  useEffect(()=>{
    let rolePermisionsData =  JSON.parse(sessionStorage.getItem('userData')) || []
   let createPermision = hasCreatePermission(currentPath,rolePermisionsData?.permissions)
   let editPermision = hasEditPermission(currentPath,rolePermisionsData?.permissions)
   let deletePermission = hasDeletePermission(currentPath,rolePermisionsData?.permissions)

   setPermissions({
    create:createPermision,
    edit:editPermision,
    delete:deletePermission
   })
  },[])

  const table = useMaterialReactTable({
    columns,
    data: data, // This will already be filtered on the server
    positionGlobalFilter: 'none',
    enableStickyHeader: true,
    enableColumnFilter: false,
    enableColumnActions: false,
    enableSorting: true,

    muiTableHeadCellProps: ({ column }) => ({
      sx: {
        fontSize: '14px',
        background: '#e1dddd69',
        textTransform: 'capitalize',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        padding: "5px",

        "& .css-1w86f15": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          width: "100%",
          ...column.columnDef.tableHeader,

        },

      },
    }),

    muiTableBodyProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example
      sx: {
        fontWeight: 'normal',
        fontSize: '14px',
        textTransform: 'capitalize',
      },
    },
    muiTableBodyCellProps: ({ column }) => ({
      sx: {
        ...column.columnDef.tableStyle,
        padding: "5px",
      },
    }),


    //move the search box to the left of the top toolbar
    renderTopToolbarCustomActions: () => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          justifyContent: "space-between",
          padding: "8px",
          flexWrap: "wrap",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
      </Box>
    ),
    renderToolbarInternalActions: ({ table }) => {
      return (
        <>
          <MRT_GlobalFilterTextField table={table} style={style} />
          <MRT_ToggleGlobalFilterButton table={table} sx={{ fontSize: '20px' }} />

          <MRT_ShowHideColumnsButton table={table} sx={{ fontSize: '20px' }} />

          <MRT_ToggleFiltersButton table={table} sx={{ fontSize: '20px' }} />
          <MRT_ToggleFullScreenButton table={table} sx={{ fontSize: '20px' }} />
          <IconButton
            onClick={() => {
              window.print();
            }}
          >
            <PrintOutlinedIcon sx={{ fontSize: '20px' }} />
          </IconButton>
          <IconButton onClick={handleExportData}>
            <FileDownloadOutlinedIcon sx={{ fontSize: '20px' }} />
          </IconButton>
          {fiterButtonTitile && (
            <CustomButton
              startIcon={<FilterAltOutlinedIcon />}
              title={fiterButtonTitile}
              size="small"
              variant="outlined"
              onClick={() => handleFilterButton(data)}
              sx={{ minWidth: 'auto', whiteSpace: 'nowrap' }}
            />
          )}
          {buttonTitle &&  permission?.create &&(
            <CustomButton
              startIcon={<AddOutlinedIcon />}
              title={buttonTitle}
              size="small"
              variant="contained"
              onClick={handleOpen}
              sx={{ minWidth: 'auto', whiteSpace: 'nowrap' }}
            />
          )}

        </>
      );
    },
    muiTablePaperProps: {
      elevation: 0
    }
  });
  return (
    <>
      <MaterialReactTable
        table={table}
        columnFilterDisplayMode="popover"
      />
    </>
  );
};

export default DataTable;

const style = {
  width: 200,
  // border: '1px solid #CED4DA',
  borderRadius: '4px',
  // paddingLeft: '10px',
  height: '35px',
  marginTop: '4px',
  fontFamily: 'Poppins, sans-serif !important',
  color: '#495057',
  fontSize: '14px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    height: '35px',
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    padding: '0 0',
  },
};

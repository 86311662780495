import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import CustomLoader from "../common/CustomLoader";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { GET_BL_REPORT } from "../../services/apiRoutes";
import { formatDate } from "../../services/CommonServices";
import CustomReport from "../common/CustomReport";
const title = "BL Report";

const BLReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            { accessorKey: 'createdAt', header: 'Date', size: 100, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'shipper', header: 'shipper', size: 100, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton >
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_BL_REPORT });
            if (response.data.success) {
                const blDrafts = response.data.data.map((draft, index) => {
                    const particulars = draft.particulars.map((particular) => ({
                        blDraftParticularsId: particular.blDraftParticularsId || '',
                        sealNo: particular.sealNo || '',
                        type: particular.type || '',
                        noOfPkgs: particular.noOfPkgs || 0,
                        grossWeight: parseFloat(particular.grossWeight) || 0,
                        measurement: parseFloat(particular.measurement) || 0,
                    }));

                    return {
                        blDraftId: draft.blDraftId || '',
                        index: (index + 1).toString().padStart(2, '0'),
                        userId: draft.userId || '',
                        companyId: draft.companyId || '',
                        clientCompanyId: draft.clientCompanyId || '',
                        shipper: draft.shipper || '',
                        paymentMethod: draft.paymentMethod || '',
                        blNumber: draft.blNumber || '',
                        orderMethod: draft.orderMethod || '',
                        consignee: draft.consignee || '',
                        hsCode: draft.hsCode || '',
                        specialRequirement: draft.specialRequirement || '',
                        notifyParty: draft.notifyParty || '',
                        alsoNotify: draft.alsoNotify || '',
                        oceanVesselVoyNo: draft.oceanVesselVoyNo || '',
                        portOfLoading: draft.portOfLoading || '',
                        excessValueDeclaration: draft.excessValueDeclaration || '',
                        portOfDestination: draft.portOfDestination || '',
                        finalDestination: draft.finalDestination || '',
                        freightPayableAt: draft.freightPayableAt || '',
                        noOfOriginals: draft.noOfOriginals || 0,
                        marks: draft.marks || '',
                        noOfPkgs: draft.noOfPkgs || 0,
                        descriptionOfGoods: draft.descriptionOfGoods || '',
                        grossWeight: draft.grossWeight || '',
                        measurement: draft.measurement || '',
                        createdAt: formatDate(draft.createdAt),
                        updatedAt: draft.updatedAt || '',
                        particulars,
                        customerName: draft.customer?.clientCompanyName || '',
                        customerCountry: draft.customer?.mailingCountry?.name || '',
                    };
                });
                setData(blDrafts);
            } else {
                setError('No BL drafts found');
            }
        } catch (error) {
            setError('Error fetching BL drafts');
            console.error('Error fetching BL drafts', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData()
    }, [token])

    const defaultView = 'blreport';
    const mainContent = (
        <>
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
            />
        </>
    );


    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>
    );
};
export default BLReport;

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CustomModal from '../../../components/common/CustomModal';
import { GET_PRODUCTS_LIST, GET_SUPPLIERS_LIST, LIST_PURCHASE_ORDERS, READ_IMPORT_SHIPMENT } from '../../../services/apiRoutes';
import { AppBarMenu, costColorPlate } from '../../../constants/DetailsPage';
import { useShipmentDetails } from './useShipmentDetails';
import { genericGet } from '../../../services/apiServices';
import { Main } from '../../../constants/DetailsMain';
import UpdatePurchaseOrder from '../UpdateForm/UpdatePurchaseOrder';
import MessageBox from '../../common/CustomMessageBox';
import CreatePurchaseOrder from "../CreateForm/CreatePurchaseOrder";
import { getContactPersonList, getCurrencyList } from '../../../services/CommonServices';
import axios from 'axios';
import { genericPost } from '../../../services/authServices';
import PurchaseOrderView from '../ViewDetails/PurchaseOrderView';
import CustomLoader from '../../common/CustomLoader';
import { Box } from '@mui/material';
import { AuthContext } from '../../../context/AuthContext';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CustomStatus from '../../common/CustomStatus';

const PurchaseOrder = () => {
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false); // New loading flag for DetailsPageCard

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const { importShipmentId } = useParams();
    const [suppliersList, setSuppliersList] = useState([]);
    const [contactPersonList, setContactPersonList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [products, setProducts] = useState([]);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [updateModal, setUpdateModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [purchaseOrderId, setPurchaseOrderId] = useState('');
    const [activeTab, setActiveTab] = useState('Purchase Order');
    const [updateCompleted, setUpdateCompleted] = useState(false); // New state to track modal closure
    const [newShippingId, setNewShippingId] = useState(null);

    const navigate = useNavigate();

    const { shipmentDetails, loading: shipmentLoading } = useShipmentDetails(importShipmentId);

    let supplierId, cifFob;

    const hasFetched = useRef(false);

    useEffect(() => {
        if (shipmentDetails) {
            supplierId = shipmentDetails.supplierId;
            cifFob = shipmentDetails.cifFob.cifFobName;
            fetchData();
            getCommonData();
            fetchProductsList();
            getSuppliersList();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateCompleted, purchaseOrderId, modal, token]);


    const fetchProductsList = async () => {
        const authToken = sessionStorage.getItem('accessToken');
        const payload = {
            clientCompanyId: supplierId,
        };

        try {
            const response = await axios.post(GET_PRODUCTS_LIST, payload, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200 && response.data.success) {
                const productData = response.data.productsList;
                console.log(productData);
                setProducts(productData);
            } else {
                console.error('Failed to fetch products list:', response.data);
            }
        } catch (error) {
            console.error('Error fetching products list:', error);
        }
    };


    const getSuppliersList = async () => {
        try {
            const payload = {
                clientCompanyId: supplierId,
            };
            const response = await genericPost(GET_SUPPLIERS_LIST, payload, {
            });
            if (response.data && response.data.success && response.data.clientCompanies) {
                const suppliersData = response.data.clientCompanies.map((supplier) => ({
                    id: supplier.clientCompanyId,
                    title: supplier.clientCompanyName,
                }));
                setSuppliersList(suppliersData);

            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching suppliers list:', error);
            return [];
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                supplierNamesId: supplierId,
                shipmentId: shipmentId
            };
            const response = await genericPost(LIST_PURCHASE_ORDERS, payload, {
            });

            if (response.data.success) {
                const { newShippingId } = response.data;
                const purchaseOrders = response.data.purchaseOrders.map((order, index) => {
                    const particulars = order.particulars.map(particular => ({
                        qty: particular.qty || 0,
                        rate: parseFloat(particular.rate) || 0,
                        amount: (particular.qty || 0) * (parseFloat(particular.rate) || 0),
                    }));
                    const totalAmount = particulars.reduce((total, { amount }) => total + amount, 0);
                    return {
                        purchaseOrderId: order.purchaseOrderId || '',
                        index: (index + 1).toString().padStart(2, '0'),
                        userId: order.userId || '',
                        companyId: order.companyId || '',
                        purchaseOrderNo: order.purchaseOrderNo || '',
                        supplier: order.supplier.clientCompanyName || '',
                        country: order.supplier.mailingCountry.name || '',
                        reference: order.reference || '',
                        contactPersonId: order.contactPersonId || '',
                        quotationNo: order.quotationNo || '',
                        date: order.date || '',
                        shipmentId: order.shipmentId || '',
                        cifFob: cifFob || '',
                        cifFobId: cifFobId || '',
                        dueDate: order.dueDate || '',
                        createdAt: order.createdAt || '',
                        updatedAt: order.updatedAt || '',
                        particulars,
                        // totalAmount: totalAmount ? totalAmount.toFixed(2) : "",
                        currencySym: order.currency?.currency_symbol || '',
                        totalAmount: `${order.currency?.currency_symbol || ''} ${order.currency?.currency || ''} ${totalAmount ? totalAmount.toFixed(2) : ""}`,

                    };
                });
                setData(purchaseOrders);
                setNewShippingId(newShippingId);
            }
            else {
                setError('No purchase orders found');
            }
        } catch (error) {
            setError('Error fetching purchase orders');
            console.error('Error fetching purchase orders', error);
        } finally {
            setLoading(false);
            setDataLoaded(true);

        }
    };
    const getCommonData = async () => {
        const contactPersonList = await getContactPersonList();
        setContactPersonList(contactPersonList);
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpenModal = () => {
        setModal(true);
    };

    const handleCloseModal = () => {
        setModal(false);
    };

    const handleViewClick = (id) => {
        handleOpenView(id);
    };

    const handleUpdateClick = (id) => {
        handleOpenUpdate(id);
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleBackClick = () => {
        navigate("/app/import/shipement");
    };

    const handleOpenView = (id) => {
        setPurchaseOrderId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setPurchaseOrderId('');
        setUpdateCompleted(true);
    };

    const handleOpenUpdate = (id) => {
        setPurchaseOrderId(id);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setPurchaseOrderId('');
        setUpdateCompleted(true);
    };

    if (!shipmentDetails) {
        return (
            <Box>
                <CustomLoader open={shipmentLoading} />
            </Box>
        );
    }

    const purchasePrefixActivity = shipmentDetails.company.purchasePrefixActivity ?? '';
    const purchasePrefix = (purchasePrefixActivity === 1)
        ? shipmentDetails.company.purchasePrefix
        : (purchasePrefixActivity === 0 ? '' : '');

    const purchaseSuffixActivity = shipmentDetails.company.purchaseSuffixActivity ?? '';
    const purchaseSuffix = (purchaseSuffixActivity === 1)
        ? shipmentDetails.company.purchaseSuffix
        : (purchaseSuffixActivity === 0 ? '' : '');
    const companyName = shipmentDetails.supplier.clientCompanyName;
    const phoneNumber = shipmentDetails.supplier.mobile;
    const supplierIdName = shipmentDetails.supplierId;
    const shipmentId = shipmentDetails.shipmentId;
    const cifFobId = shipmentDetails.cifFobId && shipmentDetails.cifFobId !== '' ? shipmentDetails.cifFobId : '';

    // const baseCurrency = shipmentDetails?.company?.baseCurrencyData?.currency ?? '';
    const countryName = shipmentDetails?.currency?.name ?? '';
    const currencySymbol = shipmentDetails?.currency?.currency_symbol ?? '';
    const currencyShort = shipmentDetails?.currency?.currency ?? '';
    const currency = `${currencySymbol} ${currencyShort} ${countryName}`;
    const currencyId = shipmentDetails?.currency?.id ?? '';


    const PurchaseOrderCardColumns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        { id: 'date', label: 'Date', accessor: 'date', styleAccessor: { textAlign: 'center' }, },
        { id: 'shipmentId', label: 'Shipment ID', accessor: 'shipmentId', styleAccessor: { textAlign: 'center' }, },
        { id: 'purchaseOrderNo', label: 'purchase Order No', accessor: 'purchaseOrderNo', styleAccessor: { textAlign: 'center' }, },
        { id: 'supplier', label: 'Supplier Name', accessor: 'supplier' },
        { id: 'country', label: 'Country', accessor: 'country' },
        {
            id: 'cifFob',
            label: 'CIF/FOB',
            accessor: 'cifFob',
            styleAccessor: { textAlign: 'center' },
            Cell: ({ row }) => {
                const colorConfig = costColorPlate.find(
                    (color) => color.colorId === row.cifFobId
                ) || {
                    backgroundColor: "#ffffff",
                    color: "#000000",
                };
                return (
                    <CustomStatus
                        title={row.cifFob}
                        sx={{
                            backgroundColor: colorConfig.backgroundColor,
                            color: colorConfig.color,
                            width: "50px",
                            textAlign: 'center',
                            display: 'inline-block',
                        }}
                    />
                );
            },
        },
        {
            id: 'totalAmount', label: 'Amount', accessor: 'totalAmount', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => {
                // const [hover, setHover] = useState(false);
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}
                    // style={{
                    //     display: 'flex',
                    //     alignItems: 'center',
                    //     justifyContent: 'center',
                    //     opacity: hover ? 1 : 0,
                    //     transition: 'opacity 0.3s ease-in-out',
                    // }}
                    // onMouseEnter={() => setHover(true)}
                    // onMouseLeave={() => setHover(false)}
                    >
                        <IconButton
                            onClick={() => handleViewClick(row.purchaseOrderId)}
                            style={{ marginRight: '1px' }}
                        >
                            <VisibilityIcon style={{ color: '#059212' }} />
                        </IconButton>
                        <IconButton
                            onClick={() => handleUpdateClick(row.purchaseOrderId)}
                            style={{ marginRight: '1px' }}
                        >
                            <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteAlert(row.purchaseOrderId)}>
                            <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            {/* <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={AppBarMenu(importShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />
            {dataLoaded ? (
                <Main open={open}> */}
            <DetailsPageCard
                Icon={ShoppingCartOutlinedIcon}
                columns={PurchaseOrderCardColumns}
                title={'Purchase Order'}
                buttonTitle={'Create Purchase Order'}
                handleOpen={handleOpenModal}
                data={data}
                loading={loading}
                error={error}
            />

            <CustomModal open={modal} handleClose={handleCloseModal}>
                <CreatePurchaseOrder
                    fetchData={fetchData}
                    suppliersList={suppliersList}
                    contactPersonList={contactPersonList}
                    productsList={products}
                    supplierIdName={supplierIdName}
                    setMessage={setMessage}
                    handleClose={handleCloseModal}
                    purchasePrefix={purchasePrefix}
                    purchaseSuffix={purchaseSuffix}
                    shipmentId={shipmentId}
                    purchaseOrderNo={newShippingId}
                    currency={currency}
                    currencyList={currencyList}
                    currencyId={currencyId}
                    currencySymbol={currencySymbol}
                />
            </CustomModal>

            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdatePurchaseOrder
                    handleClose={handleCloseUpdate}
                    purchaseOrderId={purchaseOrderId}
                    suppliersList={suppliersList}
                    contactPersonList={contactPersonList}
                    productsList={products}
                    fetchData={fetchData}
                    setMessage={setMessage}
                    currency={currency}
                    currencyList={currencyList}
                    currencySymbol={currencySymbol}

                />
            </CustomModal>
            <CustomModal open={viewModal} handleClose={handleCloseView}>
                <PurchaseOrderView
                    handleClose={handleCloseView}
                    purchaseOrderId={purchaseOrderId}
                    suppliersList={suppliersList}
                    contactPersonList={contactPersonList}
                    productsList={products}
                    fetchData={fetchData}
                    setMessage={setMessage}
                />
            </CustomModal>

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            {/* </Main>
            ) : (
                <CustomLoader />
            )}

 */}

        </div>
    );
};

export default PurchaseOrder;




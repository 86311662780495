import React, { useMemo, useState, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from "react-i18next";
import DataTable from "../../../components/common/DataTable";
import { genericGet } from "../../../services/apiServices";
import { READ_SUPPLIER_LEDGER } from "../../../services/apiRoutes";
import { AuthContext } from "../../../context/AuthContext";
import CustomLoader from "../../common/CustomLoader";
import axios from "axios";
import CustomReport from "../../common/CustomReport";

const title = "Supplier Ledger Wise Invoice";

const SupplierledgerView = () => {
    const { clientCompanyId } = useParams();
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const columns = useMemo(
        () => [
            { accessorKey: 'commercialInvoiceId', header: 'SN', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'date', header: 'Date', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'shipmentId', header: 'Shipment I’d', size: 150, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'commercialInvoiceNo', header: 'Commercial Invoice', size: 100, tableStyle: { textAlign: "center" }, },
            {
                accessorKey: 'productName', header: 'Particular', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'invoiceAmount', header: 'Invoice Amount', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'receipt', header: 'Receipt', size: 100, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'balance', header: 'Balance', size: 100, tableStyle: { textAlign: "center" }, },
        ],
        []
    );

    const fetchData = async (clientCompanyId) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.get(READ_SUPPLIER_LEDGER(clientCompanyId), {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            const mappedData = response.data.commercialInvExps.map(item => {
                const totalAmount = item.particulars.reduce((sum, particular) => {
                    return sum + parseFloat(particular.amount);
                }, 0);
                const productNames = item.particulars.slice(0, 5)
                    .map(particular => particular.products.productName)
                    .join(", ");


                return {
                    commercialInvoiceId: item.commercialInvoiceId,
                    commercialInvoiceNo: item.commercialInvoiceNo,
                    productName: productNames,
                    date: item.date,
                    ciNo: item.ciNo,
                    particulars: item.particulars,
                    invoiceAmount: totalAmount.toFixed(2),
                    receipt: item.receipt,
                    balance: item.balance,
                    shipmentId: item.importShipment?.shipmentId,
                };
            });

            setData(mappedData);
        } catch (err) {
            setError(err.message);
            setData([]);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (!clientCompanyId) return;
        setLoading(true);
        fetchData(clientCompanyId);
    }, [clientCompanyId, token]);


    const defaultView = 'supplierledger';
    const mainContent = (
        <>
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
            />
        </>
    );

    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />
        </>
    );
};

export default SupplierledgerView;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from "react-i18next";
import DataTable from '../components/common/DataTable';
import MessageBox from "../components/common/CustomMessageBox";
import CustomDelete from '../components/common/CustomDeleteAlert';
import CustomModal from "../components/common/CustomModal";
import Createexportshipement from "../components/exportshipement/createexportshipement";
import UpdateExportshipement from "../components/exportshipement/UpdateExportshipement";
import { DELETE_EXPORT_SHIPMENT, LIST_ALL_EXPORT_SHIPMENT } from "../services/apiRoutes";
import { genericGet } from "../services/apiServices";
import axios from "axios";
import { formatDate, getCIFFOBList, getCountries, getCurrencyList, getCustumersList, getShipmentTypes, getSuppliersList } from "../services/CommonServices";
import { AuthContext } from "../context/AuthContext";
import CustomLoader from "../components/common/CustomLoader";
import CustomFilter from "../components/common/CustomFilter";
import CustomTextField from "../components/common/CustomTextField";
import CustomAutocomplete from "../components/common/CustomAutoComplete";
import CustomButton from "../components/common/CustomButton";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import FindReplaceOutlinedIcon from '@mui/icons-material/FindReplaceOutlined';
import CustomStatus from "../components/common/CustomStatus";
import { genericPost } from "../services/authServices";
import { costColorPlate } from "../constants/DetailsPage";
import { hasEditPermission, hasDeletePermission } from '../services/CommonServices'
import { useLocation } from "react-router-dom";


const title = 'Export Shipment';

const ExportShipment = () => {
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null); // State to hold selected ID
  const [exportShipments, setExportShipments] = useState([]);
  const [cifFobList, setCIFFOBList] = useState([]);
  const [shipmentTypes, setShipmentTypes] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [custumersList, setCustumersList] = useState([]);
  const [conutries, setConutries] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [newShippingId, setNewShippingId] = useState(null);
  const [permission, setPermissions] = useState()
  const [filterPayload, setFilterPayload] = useState({
    customerId: '',
    shipmentId: '',
    cifFobId: '',
    currencyId: '',
    shipmentTypeId: '',
    fromDate: '',
    toDate: '',
  });
  const { token } = useContext(AuthContext)
  const [activeButton, setActiveButton] = useState(null);
  const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  });
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowId, setRowId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const filterOpen = () => setOpenDrawer(true);
  const filterClose = () => setOpenDrawer(false);



  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleUpdateClick = (exportShipmentId) => {
    setSelectedId(exportShipmentId);
    setUpdateModal(true);
  };

  const handleOpenDeleteAlert = (rowId) => {
    setDeleteAlert(true);
    setRowId(rowId);
  };

  const handleCloseUpdate = () => {
    setUpdateModal(false);
  };
  const handleFilterChange = (field, value) => {
    setFilterPayload((prev) => ({
      ...prev,
      [field]: value || '',
    }));
  };
  const handleFOBClick = () => {
    setActiveButton("2");
    setFilterPayload((prev) => ({
      ...prev,
      cifFobId: "1",
    }));
    fetchExportShipments();
  };
  const handleCIFClick = () => {
    setActiveButton("1");
    setFilterPayload((prev) => ({
      ...prev,
      cifFobId: "2",
    }));
    fetchExportShipments();
  };


  const handleClearFilters = () => {
    setFilterPayload({
      customerId: '',
      shipmentId: '',
      cifFobId: '',
      currencyId: '',
      shipmentTypeId: '',
      fromDate: '',
      toDate: '',
    });
    fetchExportShipments();
    setActiveButton(null);

  };

  const handleDelete = async (exportShipmentId) => {
    setLoading(true);
    const authToken = sessionStorage.getItem('accessToken');
    try {
      const response = await axios.delete(DELETE_EXPORT_SHIPMENT(exportShipmentId), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        fetchExportShipments();
        setDeleteAlert(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error deleting export shipment", error);
    }
  };
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    let rolePermisionsData = JSON.parse(sessionStorage.getItem('userData')) || []
    let editPermision = hasEditPermission(currentPath, rolePermisionsData?.permissions)
    let deletePermission = hasDeletePermission(currentPath, rolePermisionsData?.permissions)
    setPermissions({
      edit: editPermision,
      delete: deletePermission
    })
  }, [])

  useEffect(() => {
    fetchExportShipments()
  }, [token, filterPayload])


  useEffect(() => {
    getCommonData();
  }, []);

  const getCommonData = async () => {

    const cifFobListData = await getCIFFOBList();
    setCIFFOBList(cifFobListData);

    const shipmentTypesData = await getShipmentTypes();
    setShipmentTypes(shipmentTypesData);

    const currencyListData = await getCurrencyList();
    setCurrencyList(currencyListData);

    const custumersList = await getCustumersList();
    setCustumersList(custumersList);
    const getConutriesData = await getCountries()
    setConutries(getConutriesData)

  }


  const columns = useMemo(
    () => [
      { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
      { accessorKey: "createdAt", header: "Date", size: 50, tableStyle: { textAlign: "center" }, enableSorting: false, },
      { accessorKey: "shipmentId", header: "Shipment ID", size: 50, tableStyle: { textAlign: "center" }, enableSorting: false, },
      {
        accessorKey: "customerName", header: "Customer Name", size: 50, enableSorting: false, tableHeader: {
          justifyContent: 'flex-start',
        },
      },
      {
        accessorKey: "country", header: "Country", size: 50, enableSorting: false, tableHeader: {
          justifyContent: 'flex-start',
        },
      },
      { accessorKey: "noOfContainer", header: "No. of Container", size: 50, tableStyle: { textAlign: "center" }, enableSorting: false, },
      {
        accessorKey: "currency", header: "Currency", size: 50, enableSorting: false, tableHeader: {
          justifyContent: 'flex-start',
        },
      },
      {
        accessorKey: "cifFobName",
        header: "CIF/FOB",
        size: 50,
        enableSorting: false,
        tableStyle: { textAlign: "center" },
        Cell: ({ row }) => {
          const { cifFobId, cifFobName } = row.original;
          const colorConfig = costColorPlate.find((color) => color.colorId === cifFobId) || {};
          return (
            <CustomStatus
              title={cifFobName}
              sx={{
                backgroundColor: colorConfig.backgroundColor || "#e3dcff",
                color: colorConfig.color || "#0c003d",
                textAlign: 'center',
                display: 'inline-block',
              }}
            />
          );
        },
      },

      {
        accessorKey: "action",
        header: "Action",
        size: 50,
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
              <IconButton onClick={() => handleViewClickView(row.original.exportShipmentId)}>
                <VisibilityIcon style={{ color: "#059212" }} />
              </IconButton>
              {permission?.edit && <IconButton onClick={() => handleUpdateClick(row.original.exportShipmentId)}>
                <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
              </IconButton>}
              {permission?.delete && <IconButton onClick={() => handleOpenDeleteAlert(row.original.exportShipmentId)}>
                <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
              </IconButton>}
            </div>
          </>
        ),
      },
    ],
    []
  );

  const fetchExportShipments = async () => {
    setLoading(true);
    try {
      const response = await genericPost(LIST_ALL_EXPORT_SHIPMENT, filterPayload, {});

      if (response.data.success && response.data.shipments) {
        const { newShippingId } = response.data;
        const shipmentData = response.data.shipments.map((shipment, index) => ({
          index: (index + 1).toString().padStart(2, '0'),
          exportShipmentId: shipment.exportShipmentId || '',
          createdAt: formatDate(shipment.createdAt) || '',
          shipmentId: shipment.shipmentId || '',
          customerName: shipment.customer.clientCompanyName || '',
          country: shipment.customer.mailingCountry.name || '',
          noOfContainer: shipment.noOfContainers || '',
          cifFob: shipment.cifFob.cifFobName || '',
          cifFobName: shipment.cifFob.cifFobName || '',
          cifFobId: shipment.cifFob.cifFobId || '',
          currency: shipment.currency.currency || '',
        }));
        setExportShipments(shipmentData);
        setNewShippingId(newShippingId);

      } else {
        setError('No export shipments found');
      }
    } catch (error) {
      setError('Error fetching export shipments');
      console.error('Error fetching export shipments', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleViewClick = (exportShipmentId) => {
    navigate(`/app/export/shipement/${exportShipmentId}`);
  };
  const handleViewClickView = (exportShipmentId) => {
    navigate(`/app/export/shipementview/${exportShipmentId}`);
  };

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <CustomLoader open={loading} />
        <DataTable
          title={title}
          columns={columns}
          data={exportShipments}
          handleOpen={handleOpen}
          buttonTitle="Create"
          handleFilterButton={filterOpen}
          fiterButtonTitile="Fiter"
        />
        <CustomModal open={modal} handleClose={handleClose}>
          <Createexportshipement
            handleClose={handleClose}
            fetchExportShipments={fetchExportShipments}
            setMessage={setMessage}
            cifFobList={cifFobList}
            shipmentTypes={shipmentTypes}
            currencyList={currencyList}
            custumersList={custumersList}
            newShippingId={newShippingId}

          />
        </CustomModal>

        <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
          <UpdateExportshipement
            handleClose={handleCloseUpdate}
            exportShipmentId={selectedId}
            fetchExportShipments={fetchExportShipments}
            setMessage={setMessage}
            cifFobList={cifFobList}
            shipmentTypes={shipmentTypes}
            currencyList={currencyList}
            custumersList={custumersList}

          />
        </CustomModal>

        <MessageBox
          open={message.open}
          onClose={() => setMessage({ ...message, open: false })}
          message={message.text}
          handleClose={() => setMessage({ ...message, open: false })}
          type={message.type}
        />
        <CustomDelete
          handleDelete={handleDelete}
          open={deleteAlert}
          rowId={rowId}
          handleClose={() => setDeleteAlert(false)}
        />
        <CustomFilter
          drawerTitle="Filter Options"
          drawerWidth={350}
          openDrawer={openDrawer}
          onClose={filterClose}
          drawerContent={
            <>
              <Box>
                <Grid container spacing={1} sx={{ padding: 0, }}>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Shipment</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomTextField
                      type="number"
                      placeholder="Enter Shipment ID"
                      value={filterPayload.shipmentId}
                      onChange={(e) => handleFilterChange('shipmentId', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle} >customer </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomAutocomplete
                      options={custumersList}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Customer"
                      onChange={(event, newValue) => handleFilterChange("customerId", newValue?.id)}

                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Currency</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomAutocomplete
                      options={currencyList}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Currency"
                      onChange={(event, newValue) => handleFilterChange("currencyId", newValue?.id)}

                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>From Date</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomTextField
                      type="date"
                      value={filterPayload.fromDate}
                      onChange={(e) => handleFilterChange('fromDate', e.target.value)}
                      placeholder="From Date"
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>To Date</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomTextField
                      type="date"
                      value={filterPayload.toDate}
                      onChange={(e) => handleFilterChange('toDate', e.target.value)}
                      placeholder="To Date"
                    />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>CIF/FOB</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <CustomButton
                      onClick={handleCIFClick}
                      title="CIF"
                      size="small"
                      variant="outlined"
                      style={{
                        ...cif_fob_style,
                        ...(activeButton === "1" ? activeStyle : {}),
                      }}

                    />
                    <CustomButton
                      onClick={handleFOBClick}
                      title="FOB"
                      size="small"
                      variant="outlined"
                      style={{
                        ...cif_fob_style,
                        ...(activeButton === "2" ? activeStyle : {}),
                      }}

                    />                  </Grid>
                  <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'center', marginTop: "130px" }}>
                    <CustomButton
                      startIcon={<FindReplaceOutlinedIcon />}
                      title="Apply"
                      size="small"
                      variant="contained"
                      onClick={fetchExportShipments}
                    />
                    <CustomButton
                      variant="outlined"
                      title="Clear"
                      startIcon={<ClearRounded />}
                      style={cif_fob_style}
                      onClick={handleClearFilters}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          }
        />
      </Box>
    </>
  );
};

export default ExportShipment;

const filterLabelStyle = {
  fontSize: '14px',
  color: "#212529",
  paddingTop: "10px",
  textTransform: "capitalize"
}
const cif_fob_style = {
  backgroundColor: '#ffff',
  color: '#007bff',
  textTransform: "capitalize",
  borderRadius: 4,
  border: '1px solid #007bff',
  height: 30,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: "100px"

}
const activeStyle = {
  backgroundColor: "#007BFF",
  color: "#ffffff",
  border: "1px solid #007BFF",
};

import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { West, PhoneEnabledOutlined } from '@mui/icons-material';

// Define the drawerWidth constant (replace with actual value as needed)
// const drawerWidth = 240; 

// Create the Main styled component
export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        marginTop: '140px',
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            // marginLeft: "14%",
            marginLeft: "200px",
        }),
    })
);


// const stylesHeading = {
//     boxHeading: {
//         display: 'flex',
//         alignItems: 'center',
//         fontSize: '45px',
//         color: '#212529',
//     },
//     headingName: {
//         fontSize: '20px',
//         fontWeight: 'bold',
//         color: '#212529',
//     },
// };

const stylesHeading = {
    boxHeading: {
        width: 50,
        height: 50,
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 14,
        boxShadow: 3,
    },
    headingName: {
        paddingTop: "0px",
        fontWeight: "600",
        fontSize: "16px",
        color: "#212529",
        textTransform: 'capitalize',
    },
};


export const CustomTitile = ({ initial, companyName, phoneNumber ,handleBackClick }) => {


    return (
        <Card
            variant="outlined"
            style={{
                position: 'fixed',
                top: '40px',
                width: '100%',
                height: '85px',
                zIndex: 1,
            }}
        >
            <CardContent style={{ display: 'flex' }}>
                <div style={{ paddingTop: '0px', paddingRight: '10px', display: 'flex' }}>
                    <Box
                        onClick={handleBackClick}
                        style={{ paddingTop: '15px', paddingRight: '3px' }}
                    >
                        <West style={{ color: 'rgb(33, 37, 41)' }} />
                    </Box>
                    <Box style={stylesHeading.boxHeading}>
                        <span style={{ fontSize: "45px" }} >{initial}</span>
                    </Box>
                </div>
                <div style={{ paddingTop: '1px' }}>
                    <Typography
                        variant="h5"
                        component="div"
                        style={stylesHeading.headingName}
                    >
                        {companyName}
                    </Typography>
                    <Typography variant="body2">
                        <span style={{ padding: '1px' }}>
                            <PhoneEnabledOutlined style={{ fontSize: '15px' }} />
                        </span>
                        <a style={{ color: '#343a40' }} href={`tel:${phoneNumber}`}>
                            {phoneNumber}
                        </a>
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

import React, { useState, useEffect } from 'react';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CustomModal from '../../common/CustomModal'; // Assuming you have a CustomModal component
import { Delete_task, GET_task, GET_task_LIST } from '../../../services/apiRoutes';
import { genericGet } from '../../../services/apiServices';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomDelete from '../../../components/common/CustomDeleteAlert'
import axios from 'axios';
import MessageBox from '../../common/CustomMessageBox';
import { getCompanies, getLeadSource, getModules, getPriorities, getPrioritiesLossReasons, getTaskStatuses } from '../../../services/CommonServices';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CustomLoader from '../../common/CustomLoader';
import { genericPost } from '../../../services/authServices';
import CreateImportTask from '../CreateForm/CreateImportTask';
import UpdateImportTask from '../UpdateForm/UpdateImportTask';
import TaskView from '../ViewDetails/TaskView';

export function ImportTask({ importShipmentId, data }) {
    const [taskData, setTaskData] = useState([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [rowId, setRowId] = useState('')
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })
    const [viewModal, setViewModal] = useState(false);

    const [leadSource, setLeadSource] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [modules, setModules] = useState([]);
    const [taskStatuses, setTaskStatuses] = useState([]);
    const [priorities, setPriorities] = useState([]);


    const getCommonData = async () => {
        const leadSourceData = await getLeadSource();
        setLeadSource(leadSourceData);
        const companiesData = await getCompanies();
        setCompanies(companiesData);
        const modulesData = await getModules();
        setModules(modulesData);
        const taskStatusesData = await getTaskStatuses();
        setTaskStatuses(taskStatusesData);
        const prioritiesData = await getPriorities();
        setPriorities(prioritiesData);
    };

    const handleOpenView = (taskId) => {
        setSelectedTask(taskId);
        setViewModal(true);
    };
    const handleCloseView = () => {
        setViewModal(false);
        setSelectedTask('');
    };
    const handleViewClick = (taskId) => {
        handleOpenView(taskId);
        setSelectedTask(taskId);
    };
    const handleOpenCreate = () => {
        setCreateModalOpen(true);
    };

    const handleCloseCreate = () => {
        setCreateModalOpen(false);
    };

    const handleOpenUpdate = (taskId) => {
        setSelectedTask(taskId);
        setUpdateModalOpen(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModalOpen(false);
        setSelectedTask(null);
    };

    const fetchTaskData = async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: data.supplierId,
                importShipmentId: importShipmentId
            };
            const response = await genericPost(GET_task_LIST, payload, {
            });
            if (response.data.success && response.data.tasks) {
                const mappedTask = response.data.tasks.map((task, index) => ({
                    taskId: task.taskId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    userId: task.userId || '',
                    companyId: task.companyId || '',
                    taskOwnerId: task.taskOwnerId || '',
                    assignToId: task.assignToId || '',
                    contactId: task.contactId || '',
                    clientCompanyId: task.clientCompanyId || '',
                    taskName: task.taskName || '',
                    subject: task.subject || '',
                    dueDate: task.dueDate || '',
                    taskStatusId: task.taskStatusId || '',
                    priorityId: task.priorityId || '',
                    description: task.description || '',
                    createdAt: task.createdAt || '',
                    updatedAt: task.updatedAt || '',
                    contactPerson: `${task.contact?.firstName || ''} ${task.contact?.lastName || ''}`.trim(),
                    taskOwnerName: `${task.taskOwner?.firstName || ''} ${task.taskOwner?.lastName || ''}`.trim(),
                    assignedToName: `${task.assignedTo?.firstName || ''} ${task.assignedTo?.lastName || ''}`.trim(),
                    companyName: task.company?.companyName || '',
                    clientCompanyName: task.clientCompany?.clientCompanyName || '',
                    taskStatusName: task.taskStatus?.taskStatusName || '',
                    priorityName: task.priority?.priorityName || '',
                }));
                setTaskData(mappedTask);
            } else {
                setError('No shipping bookings found');
            }
        } catch (error) {
            setError('Error fetching shipping bookings');
            console.error('Error fetching shipping bookings', error);
        } finally {
            setLoading(false);
        }
    };
    const handleDelete = async (taskId) => {
        setLoading(true)
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(Delete_task(taskId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                fetchTaskData()
                setDeleteAlert(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error deleting lead", error);
        }
    };



    useEffect(() => {
        fetchTaskData();
        getCommonData();
    }, []);

    const handleOpenDeleteAlert = (taskId) => {
        setDeleteAlert(true)
        setRowId(taskId)
    }

    const handleUpdateClick = (taskId) => {
        // Handle update click logic here
        handleOpenUpdate(taskId);
    };



    if (loading) return <p>  <CustomLoader open={loading} />
    </p>;
    if (error) return <p>{error}</p>;

    const TasksColumn = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        { id: 'taskOwnerName', label: 'Task Owner', accessor: 'taskOwnerName' },
        { id: 'assignTo', label: 'Assign To', accessor: 'taskOwnerName' },
        { id: 'contactPerson', label: 'Contact Person', accessor: 'contactPerson' },
        { id: 'taskName', label: 'Task Name', accessor: 'taskName' },
        { id: 'subject', label: 'Subject', accessor: 'subject' },
        {
            id: 'dueDate', label: 'Due Date', accessor: 'dueDate', styleAccessor: {
                textAlign: 'center',
            },
        },
        { id: 'status', label: 'Status', accessor: 'taskStatusName' },
        { id: 'priority', label: 'Priority', accessor: 'priorityName' },
        { id: 'description', label: 'Description', accessor: 'description' },

        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton onClick={() => handleViewClick(row.taskId)} style={{ marginRight: '1px' }}>
                            <VisibilityIcon style={{ color: "#059212" }} />
                        </IconButton>
                        <IconButton onClick={() => handleUpdateClick(row.taskId)} style={{ marginRight: '1px' }}>
                            <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteAlert(row.taskId)}>
                            <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                        </IconButton>
                    </div>

                </>
            ),
        },
    ];

    const userId = data.userId
    const supplierId = data.supplierId


    return (
        <>
            <DetailsPageCard
                Icon={TaskAltOutlinedIcon}
                data={taskData}
                columns={TasksColumn}
                title={'Tasks'}
                buttonTitle={'Create Tasks'}
                handleOpen={handleOpenCreate}
                handleOpenUpdate={handleOpenUpdate}
            />
            <CustomModal open={createModalOpen} handleClose={handleCloseCreate}>
                <CreateImportTask
                    fetchTaskData={fetchTaskData}
                    handleClose={handleCloseCreate}
                    setMessage={setMessage}
                    leadSource={leadSource}
                    companies={companies}
                    modules={modules}
                    taskStatuses={taskStatuses}
                    priorities={priorities}
                    userId={userId}
                    supplierId={supplierId}
                    data={data}
                    importShipmentId={importShipmentId}

                />
            </CustomModal>
            <CustomModal open={updateModalOpen} handleClose={handleCloseUpdate}>
                <UpdateImportTask
                    fetchTaskData={fetchTaskData}
                    handleClose={handleCloseUpdate}
                    taskId={selectedTask}
                    setMessage={setMessage}
                    leadSource={leadSource}
                    companies={companies}
                    modules={modules}
                    taskStatuses={taskStatuses}
                    priorities={priorities}
                    userId={userId}
                    supplierId={supplierId}
                    dataUpdate={data}

                />
            </CustomModal>
            <CustomModal
                open={viewModal}
                modalStyles={{
                    maxHeight: '50vh',
                    top: "10%",
                }}
                handleClose={handleCloseView}>
                <TaskView
                    handleClose={handleCloseView}
                    fetchTaskData={fetchTaskData}
                    taskId={selectedTask}
                    setMessage={setMessage}
                    CleintCompanyData={data}
                />
            </CustomModal>

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}

            />
        </>
    );
}



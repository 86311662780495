import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Popover,
  TextField,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  useMediaQuery,
  useTheme,
  Select,
  FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import Logo from "../assets/images/logo.png";
import Logo from "../assets/images/SPIRNEXS Logo.png";
import styles from "../assets/css/Header.module.css"; // Import the CSS module
import { Search } from "@mui/icons-material";
import Userprofile from "../Pages/userprofile";
import { GET_ORGANIZATION_LIST } from "../services/apiRoutes";
import { genericGet } from "../services/apiServices";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import WifiCalling3OutlinedIcon from "@mui/icons-material/WifiCalling3Outlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";

const Headers = ({ styles }) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation(); // Get current location
  const [orgData, setOrgData] = useState([]);
  const [loading, setLoading] = useState();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handlePopoverOpen = (event) => setPopoverAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setPopoverAnchorEl(null);

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  const openPopover = Boolean(popoverAnchorEl);
  const popoverId = openPopover ? "add-popover" : undefined;

  const menuItems = [
    { text: t("leads"), icon: <LeaderboardOutlinedIcon />, Link: "/app/leads" },
    {
      text: t("contacts"),
      icon: <ContactPhoneOutlinedIcon />,
      Link: "/app/contacts",
    },
    {
      text: t("company"),
      icon: <BusinessOutlinedIcon />,
      Link: "/app/company",
    },
    {
      text: t("importShipment"),
      icon: <DirectionsBoatFilledOutlinedIcon />,
      Link: "/app/import/shipement",
    },
    {
      text: t("exportShipment"),
      icon: <RocketLaunchOutlinedIcon />,
      Link: "/app/export/shipement",
    },
    { text: t("reports"), icon: <ReportOutlinedIcon />, Link: "/app/reports" },
  ];

  const menuItemsdropdown = [
    { text: t("leads"), icon: <LeaderboardOutlinedIcon />, Link: "/app/leads" },
    {
      text: t("contacts"),
      icon: <ContactPhoneOutlinedIcon />,
      Link: "/app/contacts",
    },
    {
      text: t("company"),
      icon: <BusinessOutlinedIcon />,
      Link: "/app/company",
    },
    {
      text: t("importShipment"),
      icon: <DirectionsBoatFilledOutlinedIcon />,
      Link: "/app/import/shipement",
    },
    {
      text: t("exportShipment"),
      icon: <RocketLaunchOutlinedIcon />,
      Link: "/app/export/shipement",
    },
    { text: t("Call"), icon: <WifiCalling3OutlinedIcon />, Link: "" },
    { text: t("Task"), icon: <TaskAltOutlinedIcon />, Link: "" },
  ];

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const isActive = (link) => {
    const currentPath = location.pathname;
    if (link.includes("/app/leads") && currentPath.startsWith("/app/leads")) {
      return true;
    }
    if (
      link.includes("/app/contacts/") &&
      currentPath.startsWith("/app/contacts/")
    ) {
      return true;
    }
    if (
      link.includes("/app/company") &&
      currentPath.startsWith("/app/company")
    ) {
      return true;
    }
    if (
      link.includes("/app/import/") &&
      currentPath.startsWith("/app/import/")
    ) {
      return true;
    }
    if (
      link.includes("/app/export/") &&
      currentPath.startsWith("/app/export/")
    ) {
      return true;
    }
    if (
      link.includes("/app/reports") &&
      currentPath.startsWith("/app/reports")
    ) {
      return true;
    }
    return currentPath === link;
  };

  const isActivePath = (path) => {
    const currentPath = location.pathname;
    // Check for /app/company or /app/companyusers with suffix match

    if (path === "/app/mycompany" && currentPath.startsWith(path)) {
      return (
        /^\/app\/mycompany\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/companysetting" && currentPath.startsWith(path)) {
      return (
        /^\/app\/companysetting\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/companyusers" && currentPath.startsWith(path)) {
      return (
        /^\/app\/companyusers\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/securitycontrols" && currentPath.startsWith(path)) {
      return (
        /^\/app\/securitycontrols\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/adminusers" && currentPath.startsWith(path)) {
      return (
        /^\/app\/adminusers\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/usermanagement" && currentPath.startsWith(path)) {
      return (
        /^\/app\/usermanagement\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/expenseledgergroup" && currentPath.startsWith(path)) {
      return (
        /^\/app\/expenseledgergroup\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/shippinglinemaster" && currentPath.startsWith(path)) {
      return (
        /^\/app\/shippinglinemaster\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/freightforwardmaster" && currentPath.startsWith(path)) {
      return (
        /^\/app\/freightforwardmaster\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/customclearingagency" && currentPath.startsWith(path)) {
      return (
        /^\/app\/customclearingagency\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/manageproducts" && currentPath.startsWith(path)) {
      return (
        /^\/app\/manageproducts\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/unitmaster" && currentPath.startsWith(path)) {
      return (
        /^\/app\/unitmaster\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/businessgroup" && currentPath.startsWith(path)) {
      return (
        /^\/app\/businessgroup\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/contactgroup" && currentPath.startsWith(path)) {
      return (
        /^\/app\/contactgroup\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/lossreasons" && currentPath.startsWith(path)) {
      return (
        /^\/app\/lossreasons\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/priorities" && currentPath.startsWith(path)) {
      return (
        /^\/app\/priorities\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/taskstatuses" && currentPath.startsWith(path)) {
      return (
        /^\/app\/taskstatuses\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/modules" && currentPath.startsWith(path)) {
      return (
        /^\/app\/modules\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/contacttypes" && currentPath.startsWith(path)) {
      return (
        /^\/app\/contacttypes\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/ratings" && currentPath.startsWith(path)) {
      return (
        /^\/app\/ratings\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/statusesleads" && currentPath.startsWith(path)) {
      return (
        /^\/app\/statusesleads\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/sourceslead" && currentPath.startsWith(path)) {
      return (
        /^\/app\/sourceslead\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/companymodules" && currentPath.startsWith(path)) {
      return (
        /^\/app\/companymodules\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/systemsetting" && currentPath.startsWith(path)) {
      return (
        /^\/app\/systemsetting\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/subscription" && currentPath.startsWith(path)) {
      return (
        /^\/app\/subscription\/[1-4]$/.test(currentPath) || currentPath === path
      );
    }
    if (path === "/app/subscriptionHistory" && currentPath.startsWith(path)) {
      return (
        /^\/app\/subscriptionHistory\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }
    if (path === "/app/personalsettings" && currentPath.startsWith(path)) {
      return (
        /^\/app\/personalsettings\/[1-4]$/.test(currentPath) ||
        currentPath === path
      );
    }

    return currentPath.startsWith(path);
  };

  const getOrganization = async () => {
    setLoading(true);
    try {
      const response = await genericGet({ url: GET_ORGANIZATION_LIST });
      if (response.status == 200) {
        if (response?.data?.organization?.subscriptionStartedOn) {
          const startDate = new Date(
            response?.data?.organization?.subscriptionStartedOn
          )
            .toISOString()
            .split("T")[0];
          const date = new Date(
            response?.data?.organization?.subscriptionStartedOn
          );
          date.setDate(date.getDate() + 365);
          const endDate = date.toISOString().split("T")[0];
          let dayLefts = countDays(startDate, endDate);
          let responseData = {
            ...response?.data?.organization,
            startDate: startDate,
            endDate: endDate,
            dayLefts: dayLefts,
            isSubscription:true
          };

          setOrgData(responseData);
        } else {
          const startDate = new Date(
            response?.data?.organization?.freeTrialStartedOn
          )
            .toISOString()
            .split("T")[0];
          const date = new Date(
            response?.data?.organization?.freeTrialStartedOn
          );
          date.setDate(date.getDate() + 15);
          const endDate = date.toISOString().split("T")[0];
          let dayLefts = countDays(startDate, endDate);
          let responseData = {
            ...response?.data?.organization,
            startDate: startDate,
            endDate: endDate,
            dayLefts: dayLefts,
            isSubscription:false

          };
          setOrgData(responseData);
        }
      }
    } catch (error) {
    } finally {
    }
  };
  const countDays = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Ensure the dates are valid
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      throw new Error("Invalid date format. Please use YYYY-MM-DD.");
    }

    // Calculate the difference in milliseconds and convert to days
    const differenceInMilliseconds = end.getTime() - start.getTime();
    const daysDifference = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return Math.ceil(daysDifference); // Rounded up to include any partial days
  };
  useEffect(() => {
    getOrganization();
  }, []);
  console.log("responseData", orgData);
  return (
    <div>
      <AppBar
        position="fixed"
        className={styles.appBarRoot}
        color="inherit"
        sx={{
          height: "45px",
        }}
      >
        <Toolbar className={styles.toolbar}>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              paddingBottom: "20px",
            }}
          >
            <Typography variant="h6" component="div">
              <Link to={"/app/dashboard"}>
                {" "}
                <img
                  src={Logo}
                  alt="Logo"
                  className={styles.logo}
                  style={{ width: "120px", height: "30px", marginTop: "10px" }}
                />{" "}
              </Link>
            </Typography>
            {!isMobile && (
              <Box
                className={styles.menuContainer}
                sx={{
                  paddingLeft: "5%",
                }}
              >
                {menuItems.map((item) => (
                  <Typography
                    variant="body1"
                    component="p"
                    key={item.text}
                    onClick={() => navigate(item?.Link)}
                    className={styles.menuItem}
                    style={{
                      textDecoration: "none",
                      color: isActive(item.Link) ? "#007BFF" : "#333333",
                      fontFamily: "Poppins",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "1.5",
                      borderBottom: isActive(item.Link)
                        ? "3px solid #007BFF"
                        : "none", // Optional: Add a bottom border for active tab
                    }}
                  >
                    {item.text}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.fontSize,
              paddingBottom: "20px",
            }}
          >
            {searchFocused ? (
              <TextField
                variant="outlined"
                placeholder={t("searchPlaceholder")}
                size="small"
                className={styles.searchField}
                InputProps={{
                  classes: {
                    input: styles.searchInput,
                    inputLabel: styles.searchLabel,
                    formLabel: styles.searchFormLabel,
                    placeholder: styles.searchPlaceholder,
                  },
                }}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
              />
            ) : (
              <Search
                onClick={() => setSearchFocused(true)}
                className={styles.iconButton}
              />
            )}
            <FormControl
              variant="outlined"
              size="small"
              className={styles.languageSelect}
            >
              <select
                style={{
                  border: "1px solid #CED4DA",
                  borderRadius: "4px",
                  height: "30px",
                  padding: "0 0 0 2px",
                }}
                native
                onChange={handleLanguageChange}
                defaultValue={i18n.language}
                inputProps={{
                  name: "language",
                  id: "language-selector",
                }}
              >
                <option value="en">English</option>
                <option value="hi">हिंदी</option>
                <option value="zh">中文</option>
              </select>
            </FormControl>

            {/* <IconButton className={styles.iconButton}>
              <CalendarTodayIcon />
            </IconButton> */}
            <IconButton
              className={styles.iconButton}
              aria-describedby={popoverId}
              onClick={handlePopoverOpen}
            >
              <AddIcon />
            </IconButton>
            <Popover
              id={popoverId}
              open={openPopover}
              anchorEl={popoverAnchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{ marginTop: "11px" }}
            >
              {menuItemsdropdown.map((item) => (
                <ListItem
                  key={item.text}
                  onClick={() => navigate(item?.Link)}
                  className={styles.drawerListItem}
                  style={{ padding: "5px", cursor: "pointer" }}
                >
                  <ListItemIcon style={{ minWidth: "25px" }}>
                    {item.icon}{" "}
                  </ListItemIcon>
                  <ListItemText
                    style={{ margin: "0px", paddingRight: "10px" }}
                    primary={item.text}
                  />
                </ListItem>
              ))}
            </Popover>
            <IconButton
              style={{
                color:
                  isActivePath("/app/mycompany", location.pathname) ||
                  isActivePath("/app/companyusers", location.pathname) ||
                  isActivePath("/app/securitycontrols", location.pathname) ||
                  isActivePath("/app/adminusers", location.pathname) ||
                  isActivePath("/app/usermanagement", location.pathname) ||
                  isActivePath("/app/expenseledgergroup", location.pathname) ||
                  isActivePath("/app/shippinglinemaster", location.pathname) ||
                  isActivePath(
                    "/app/freightforwardmaster",
                    location.pathname
                  ) ||
                  isActivePath(
                    "/app/customclearingagency",
                    location.pathname
                  ) ||
                  isActivePath("/app/manageproducts", location.pathname) ||
                  isActivePath("/app/unitmaster", location.pathname) ||
                  isActivePath("/app/businessgroup", location.pathname) ||
                  isActivePath("/app/contactgroup", location.pathname) ||
                  isActivePath("/app/lossreasons", location.pathname) ||
                  isActivePath("/app/priorities", location.pathname) ||
                  isActivePath("/app/taskstatuses", location.pathname) ||
                  isActivePath("/app/modules", location.pathname) ||
                  isActivePath("/app/contacttypes", location.pathname) ||
                  isActivePath("/app/ratings", location.pathname) ||
                  isActivePath("/app/statusesleads", location.pathname) ||
                  isActivePath("/app/sourceslead", location.pathname) ||
                  isActivePath("/app/companymodules", location.pathname) ||
                  isActivePath("/app/systemsetting", location.pathname) ||
                  isActivePath("/app/subscription", location.pathname) ||
                  isActivePath("/app/subscriptionHistory", location.pathname) ||
                  isActivePath("/app/securitycontrols", location.pathname) ||
                  isActivePath("/app/personalsettings", location.pathname) ||
                  isActivePath("/app/settingmaster", location.pathname)
                    ? "#007BFF"
                    : " ",
                // borderBottom: isActivePath('/app/companyusers', location.pathname) ||
                //   isActivePath('/app/mycompany', location.pathname) ||
                //   isActivePath('/app/securitycontrols', location.pathname) ? '3px solid #007BFF' : 'none',
              }}
              className={styles.iconButton}
              onClick={() => navigate("/app/settingmaster")}
            >
              <SettingsIcon />
            </IconButton>

            <IconButton
              className={styles.iconButton}
              onClick={handleDrawerToggle}
            >
              <AccountCircleIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        {/* <List>
          {menuItems.map((item) => (
            <ListItem button key={item.text} className={styles.drawerListItem}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List> */}
        <Userprofile orgData={orgData} />
      </Drawer>
    </div>
  );
};

export default Headers;

import React, { useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomButton from "../common/CustomButton";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from '../common/CustomAutoComplete';
import CustomTextarea from "../common/CustomTextarea";
import { genericPost } from '../../services/authServices';
import { CREATE_EXPORT_SHIPMENT } from '../../services/apiRoutes';
import CustomStyle from "../common/CustomStyle";

const shipmentValidationSchema = Yup.object().shape({
    shipmentId: Yup.number().required('Shipment ID is required'),
    customerId: Yup.number().required('Customer ID is required'),
    cifFobId: Yup.number().required('CIF/FOB ID is required'),
    noOfContainers: Yup.number().required('Number of Containers is required'),
    currencyId: Yup.number().required('Currency ID is required'),
    shipmentTypeId: Yup.number().required('Shipment Type ID is required'),
    note: Yup.string().required('Note is required'),
});

const Createexportshipement = ({ handleClose, setMessage, fetchExportShipments, cifFobList, shipmentTypes, currencyList, custumersList, newShippingId }) => {

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await genericPost(CREATE_EXPORT_SHIPMENT, values);

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Shipment added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchExportShipments();
                    handleClose();
                },);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        shipmentId: newShippingId,
        customerId: '',
        cifFobId: '',
        expectedEtd: '',
        noOfContainers: '',
        currencyId: '',
        shipmentTypeId: '',
        note: '',
    });

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={shipmentValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={12} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Create Export Shipment
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>


                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Select Shipment ID"
                                    name="shipmentId"
                                    fieldLabel="Shipment ID"
                                    required={true}
                                    value={values.shipmentId}
                                    onChange={handleChange}
                                    error={touched.shipmentId && !!errors.shipmentId}
                                    helperText={touched.shipmentId && errors.shipmentId}
                                    readOnly={true}
                                    sx={CustomStyle.readOnly}

                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={custumersList}
                                    fieldLabel="Customer"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={custumersList.find((option) => option.id === values.customerId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('customerId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Customer"
                                    error={touched.customerId && !!errors.customerId}
                                    helperText={touched.customerId && errors.customerId}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={cifFobList}
                                    fieldLabel="CIF/FOB"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={cifFobList.find((option) => option.id === values.cifFobId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('cifFobId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select CIF/FOB"
                                    error={touched.cifFobId && !!errors.cifFobId}
                                    helperText={touched.cifFobId && errors.cifFobId}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <CustomTextField
                                    name="expectedEtd"
                                    fieldLabel="Expected ETD"
                                    required={true}
                                    type="date"
                                    value={values.expectedEtd}
                                    onChange={handleChange}
                                    error={touched.expectedEtd && !!errors.expectedEtd}
                                    helperText={touched.expectedEtd && errors.expectedEtd}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <CustomTextField
                                    type="number"
                                    name="noOfContainers"
                                    fieldLabel="Number of Containers"
                                    placeholder="Enter No. of Containers"
                                    required={true}
                                    value={values.noOfContainers}
                                    onChange={handleChange}
                                    error={touched.noOfContainers && !!errors.noOfContainers}
                                    helperText={touched.noOfContainers && errors.noOfContainers}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.currencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('currencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Currency"
                                    error={touched.currencyId && !!errors.currencyId}
                                    helperText={touched.currencyId && errors.currencyId}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={shipmentTypes}
                                    fieldLabel="Shipment Type "
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={shipmentTypes.find((option) => option.id === values.shipmentTypeId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('shipmentTypeId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Shipment Type "
                                    error={touched.shipmentTypeId && !!errors.shipmentTypeId}
                                    helperText={touched.shipmentTypeId && errors.shipmentTypeId}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <CustomTextarea
                                    placeholder="Write a Note.. "
                                    name="note"
                                    fieldLabel="Note"
                                    required={true}
                                    value={values.note}
                                    onChange={handleChange}
                                    error={touched.note && !!errors.note}
                                    helperText={touched.note && errors.note}
                                />
                            </Grid>
                        </Grid>


                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default Createexportshipement;



const CustomerOption = [
    { id: 1, title: 'customer 1' },
    { id: 2, title: 'customer 2' },
    { id: 3, title: 'customer 3' },
];

import React from "react";
import { Box, Grid, Divider, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomButton from "../common/CustomButton";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from "../common/CustomAutoComplete";
import CustomTextarea from "../common/CustomTextarea";
import { POST_ROLE } from "../../services/apiRoutes";
import {genericPost} from '../../services/authServices'

const RolesAdd = ({ handleClose,companyRoles,setMessage,getRoles}) => {
    const validationSchema = Yup.object({
        roleName: Yup.string().required("Name is required"),
        roleDescription: Yup.string().required("Description is required"),

     
    });
    const initialValues = {
        roleName: "",
        parentRoleId: 0,
        roleDescription:'',
        level:0
      
    };
   const handleSubmit = async (values) => {
     try {
       const response = await genericPost(POST_ROLE, values);
 
       if (response.status == 201) {
         setMessage({
           open: true,
           text: "Role added successfully",
           type: "success",
         });
            getRoles()
           handleClose();
       } else {
        setMessage({
            open: true,
            text: response?.data?.message || "Company added successfully",
            type: "error",
          });
       }
     } catch (error) {
        setMessage({
            open: true,
            text: 'Something went wrong',
            type: "error",
          });
     } 
   };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                <Form>
                    <Grid container spacing={1} style={{ position: "sticky" }}>
                        <Grid item xs={6}>
                            <Typography
                                sx={{ fontWeight: 600 }}
                                style={{ color: "#212529", fontSize: "18px" }}
                            >
                                Create Role
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{ display: "flex", justifyContent: "flex-end", marginBottom: 5 }}
                        >
                            <Box style={{ display: "flex" }}>
                                <CustomButton
                                    startIcon={<SaveRounded />}
                                    title="Save"
                                    size="small"
                                    variant="contained"
                                    type="submit"
                                />
                                <CustomButton
                                    startIcon={<ClearRounded />}
                                    title="Cancel"
                                    size="small"
                                    variant="contained"
                                    onClick={handleClose}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 1 }} />
                    <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField
                                name="roleName"
                                type="text"
                                fieldLabel="Role"
                                value={values.roleName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.roleName && Boolean(errors.roleName)}
                                helperText={touched.roleName && errors.roleName}
                                placeholder="Enter Role "
                            />
                        </Grid>
                       
                        
                       
                        <Grid item xs={12} sm={6}>
                            <CustomAutocomplete
                                options={companyRoles}
                                fieldLabel="Report to"
                                getOptionLabel={(option) => option.title}
                                value={values.userLevel}
                                onChange={(e, newValue) => 
                                    setFieldValue("parentRoleId", newValue.id)
                                }
                                onBlur={handleBlur}
                                error={touched.parentRoleId && Boolean(errors.parentRoleId)}
                                helperText={touched.parentRoleId && errors.parentRoleId}
                                placeholder="Select"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CustomTextarea 
                             fieldLabel="Role Description"
                            rows={4}
                            name='roleDescription'
                            value={values.roleDescription}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.roleDescription && Boolean(errors.roleDescription)}
                            helperText={touched.roleDescription && errors.roleDescription}
                            />
                        </Grid>

                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default RolesAdd;

import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import { ClearRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import { READ_RECEIPT_VOUCHER_EXPORT } from '../../../services/apiRoutes';
import CustomCardView from "../../common/CustomCardView";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';


const ReceiptVoucherView = ({ handleClose, setMessage, fetchData, receiptVoucherId, currencyList, receiptType, customerName, customerList }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!receiptVoucherId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_RECEIPT_VOUCHER_EXPORT(receiptVoucherId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.receiptVoucher);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [receiptVoucherId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const values = {
        receiptVoucherNo: data?.receiptVoucherNo || '',
        date: data?.date || '',
        customerName: data?.customerName || '',
        companyId: data?.companyId || '',
        receiptTypeId: data?.receiptType?.receiptTypeName || '',
        currencyId: data?.currency?.currency || '',
        baseCurrencyId: data?.baseCurrency?.currency || '',
        note: data?.note || '',
        conversionRate: `${data?.baseCurrency?.currency_symbol || ''} ${data?.conversionRate}`,
        baseAmount: `${data?.baseCurrency?.currency_symbol || ''} ${data?.baseAmount}`,
        bankCharges: `${data?.baseCurrency?.currency_symbol || ''} ${data?.bankCharges}`,
        amount: `${data?.currency?.currency_symbol || ''} ${data?.amount}`,
    };

    const labels = [
        { key: 'receiptVoucherNo', label: 'Receipt Voucher No', sm: 12, md: 8, },
        { key: 'date', label: 'Date', sm: 12, md: 4, },
        { key: 'receiptTypeId', label: 'Receipt Type', sm: 12, md: 4, },
        { key: 'currencyId', label: 'Currency', sm: 12, md: 4, },
        { key: 'amount', label: 'Amount', sm: 12, md: 4, },
        { key: 'baseCurrencyId', label: 'Base Currency', sm: 12, md: 4, },
        { key: 'conversionRate', label: 'Conversion Rate', sm: 12, md: 4, },
        { key: 'baseAmount', label: 'Base Amount', sm: 12, md: 4, },
        { key: 'bankCharges', label: 'Bank Charges', sm: 12, md: 4, },
        { key: 'note', label: 'Note', sm: 12, md: 12, },];
    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik initialValues={values} enableReinitialize>
                {() => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600, fontSize: "18px", color: "#212529", pt: "5px" }}>
                                    Receipt Voucher
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>
                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close" onClick={handleClose} >
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomCardView label={labels} data={values} />
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ReceiptVoucherView;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useTranslation } from "react-i18next";
import DataTable from "../components/common/DataTable";
import CustomModal from "../components/common/CustomModal";
import CreateLead from "../components/Leads/CreateLead";
import { Delete_Lead_leadId, GET_LEADS } from "../services/apiRoutes"
import { genericGet } from "../services/apiServices"
import { getIndustryType, getRating, getLeadSource, getLeadStatus, getCountries, getBusinessGroup, getClientCompany, formatDate, getOwner, getTitles } from '../services/CommonServices'
import UpdateLeads from "../components/Leads/UpdateLeads";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from 'axios';
import CustomLoader from "../components/common/CustomLoader";
import MessageBox from "../components/common/CustomMessageBox";
import CustomDelete from '../components/common/CustomDeleteAlert'
import { AuthContext } from "../context/AuthContext";
import CustomFilter from "../components/common/CustomFilter";
import CustomTextField from "../components/common/CustomTextField";
import CustomAutocomplete from "../components/common/CustomAutoComplete";
import { ClearRounded } from "@mui/icons-material";
import CustomButton from "../components/common/CustomButton";
import FindReplaceOutlinedIcon from '@mui/icons-material/FindReplaceOutlined';
import { hasEditPermission, hasDeletePermission } from '../services/CommonServices'
import { useLocation } from "react-router-dom";
import CustomStatus from "../components/common/CustomStatus";
import { genericPost } from "../services/authServices";
import { red } from "@mui/material/colors";
import { costColorPlate } from "../constants/DetailsPage";


const title = "Leads Management";

const Lead = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { token } = useContext(AuthContext)
  const [leadModal, setLeadModal] = useState(false);
  const [updateLeadModal, setUpdateLeadModal] = useState(false);

  const [getAllLead, setGetAllLead] = useState([]);
  const [industryType, setIndustryType] = useState([]);
  const [rating, setRating] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [conutries, setConutries] = useState([]);
  const [businessGroup, setBusinessGroup] = useState([]);
  const [clientCompany, setClientCompany] = useState([]);
  const [leadOwner, setLeadOwner] = useState([]);
  const [titles, setTitles] = useState([]);
  const [loading, setLoading] = useState(false)
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [rowId, setRowId] = useState('')
  const [openDrawer, setOpenDrawer] = useState(false);
  const [permission, setPermissions] = useState()
  const [filterPayload, setFilterPayload] = useState({
    clientCompanyId: '',
    leadSourceId: '',
    leadStatusId: '',
    industry: '',
    businessGroup: '',
    countryId: '',
    fromDate: '',
    toDate: '',

  });


  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate);
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);
  const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  })

  const filterOpen = () => setOpenDrawer(true);
  const filterClose = () => setOpenDrawer(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" },
        enableSorting: true,
        enableColumnFilter: true
      },
      { accessorKey: "createdAt", header: "Date", size: 100, tableStyle: { textAlign: "center" }, enableSorting: false, },
      {
        accessorKey: "company", header: "Company", size: 150, enableSorting: false, tableHeader: {
          justifyContent: 'flex-start',
        },
      },
      {
        accessorKey: "country", header: "Country", size: 50, enableSorting: false, tableHeader: {
          justifyContent: 'flex-start',
        },
      },
      {
        accessorKey: "contactPerson", header: "Contact Person", size: 50, enableSorting: false, tableHeader: {
          justifyContent: 'flex-start',
        },
      },
      { accessorKey: "contactNo", header: "Contact No.", size: 50, tableStyle: { textAlign: "center" }, enableSorting: false },
      {
        accessorKey: "leadStatus",
        header: "Lead Status",
        tableStyle: { textAlign: "center" },
        enableSorting: false,
        size: 100,
        Cell: ({ row }) => {
          const leadStatusId = row.original.leadStatusId;
          const colorConfig = costColorPlate.find(config => config.colorId === leadStatusId) || {
            backgroundColor: "#E0E0E0",
            color: "#000000"
          };
          return (
            <CustomStatus
              title={row.original.leadStatus}
              sx={{
                backgroundColor: colorConfig.backgroundColor,
                color: colorConfig.color,
                textAlign: "center",
                display: "inline-block",
              }}
            />
          );
        },
      },
      {
        accessorKey: "source",
        header: "Source",
        tableStyle: { textAlign: "center" },
        size: 50,
        Cell: ({ row }) => {
          const leadSourceId = row.original.leadSourceId;
          const colorConfig = costColorPlate.find(config => config.colorId === leadSourceId) || {
            backgroundColor: "#E0E0E0",
            color: "#000000",
          };
          return (
            <CustomStatus
              title={row.original.source}
              sx={{
                backgroundColor: colorConfig.backgroundColor,
                color: colorConfig.color,
                textAlign: "center",
                display: "inline-block",
              }}
            />
          );
        },
        enableSorting: false,
      },

      {
        accessorKey: "expectedPurchaseDate",
        header: "Purchase Date",
        size: 50,
        tableStyle: { textAlign: "center", },
        enableSorting: false
      },
      {
        accessorKey: "action",
        header: "Action",
        size: 100,
        Cell: ({ row }) => (
          <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
              <IconButton onClick={() => handleViewClick(row.original.leadId)}>
                <VisibilityIcon style={{ color: "#059212" }} />
              </IconButton>
              {permission?.edit && <IconButton onClick={() => handleUpdateClick(row.original.leadId)}>
                <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
              </IconButton>}
              {permission?.delete && <IconButton onClick={() => handleOpenDeleteAlert(row.original.leadId)}>
                <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
              </IconButton>}
            </div>
          </>

        ),
        enableSorting: false
      },

    ],
    []
  );

  const [selectedLeadId, setSelectedLeadId] = useState(null); // State to hold selected lead ID

  const handleUpdateClick = (leadId) => {
    setSelectedLeadId(leadId);
    setUpdateLeadModal(true);
  };


  const handleCloseUpdateLead = () => {
    setUpdateLeadModal(false);
  };
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    let rolePermisionsData = JSON.parse(sessionStorage.getItem('userData')) || []
    let editPermision = hasEditPermission(currentPath, rolePermisionsData?.permissions)
    let deletePermission = hasDeletePermission(currentPath, rolePermisionsData?.permissions)
    setPermissions({
      edit: editPermision,
      delete: deletePermission
    })
  }, [])


  const handleDelete = async (leadId) => {
    setLoading(true)
    const authToken = sessionStorage.getItem('accessToken');
    try {
      const response = await axios.delete(Delete_Lead_leadId(leadId), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        getLeadList()
        setDeleteAlert(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error("Error deleting lead", error);
    }
  };
  // const handleFilterChange = (newValue) => {
  //   setFilterPayload((prev) => ({
  //     ...prev,
  //     clientCompanyId: newValue ? newValue.id : '',
  //     leadSourceId: newValue ? newValue.id : '',
  //   }));
  // };
  const handleFilterChange = (field, value) => {
    setFilterPayload((prev) => ({
      ...prev,
      [field]: value || '',
    }));
  };
  const handleClearFilters = () => {
    setFilterPayload({
      clientCompanyId: '',
      leadSourceId: '',
      leadStatusId: '',
      industry: '',
      businessGroup: '',
      countryId: '',
      fromDate: '',
      toDate: '',

    });
  };
  const getLeadList = async () => {
    setLoading(true)
    try {
      const response = await genericPost(GET_LEADS, filterPayload, {});
      if (response.data) {
        const LeadData = response.data.leads.map((lead, index) => ({
          ...lead,
          leadId: lead.leadId || '',
          index: (index + 1).toString().padStart(2, '0'),
          createdAt: formatDate(lead.createdAt),
          expectedDate: lead.expectedDate || '',
          company: lead.clientCompany?.clientCompanyName || '',
          country: lead.country?.name || lead.name || '',
          contactPerson: `${lead.firstName || ''} ${lead.lastName || ''}`,
          contactNo: lead.mobile || lead.phone || '',
          leadStatus: lead.leadStatus?.leadStatusName || lead.leadStatusName || '',
          source: lead.leadSource?.leadSourceName || lead.leadSourceName || '',
          expectedPurchaseDate: lead.expectedDate || '',
        }));
        setGetAllLead(LeadData);
        setLoading(false)

      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error("Error fetching leads", error);
    }
  };

  const handleOpenDeleteAlert = (rowId) => {
    setDeleteAlert(true)
    setRowId(rowId)
  }

  useEffect(() => {
    getLeadList()
  }, [token,])

  useEffect(() => {
    getCommonData()
  }, [])

  const getCommonData = async () => {
    const industryTypeData = await getIndustryType()
    setIndustryType(industryTypeData);
    const ratingData = await getRating()
    setRating(ratingData);
    const leadSourceData = await getLeadSource()
    setLeadSource(leadSourceData)
    const leadStatusData = await getLeadStatus()
    setLeadStatus(leadStatusData)
    const getConutriesData = await getCountries()
    setConutries(getConutriesData)
    const businessGroupData = await getBusinessGroup()
    setBusinessGroup(businessGroupData)
    const clientCompany = await getClientCompany()
    setClientCompany(clientCompany)
    const leadOwner = await getOwner()
    setLeadOwner(leadOwner)
    const titlesData = await getTitles()
    setTitles(titlesData)
  }

  const handleOpen = () => {
    setLeadModal(true);
  };
  const handleClose = () => {
    setLeadModal(false)
  }

  const handleViewClick = (leadId) => {
    navigate(`/app/leads/${leadId}`);
  };
  return (
    <>
      <Box sx={{ padding: "10px" }}>
        <CustomLoader open={loading} />
        <DataTable
          title={title}
          columns={columns}
          data={getAllLead}
          handleOpen={handleOpen}
          buttonTitle="Create"
          handleFilterButton={filterOpen}
          fiterButtonTitile="Fiter"

        />
        <CustomModal open={leadModal} handleClose={handleClose}>
          <CreateLead
            handleClose={handleClose}
            industryType={industryType}
            clientCompany={clientCompany}
            leadOwner={leadOwner}
            rating={rating}
            leadSource={leadSource}
            titles={titles}
            leadStatus={leadStatus}
            conutries={conutries}
            businessGroup={businessGroup}
            getLeadList={getLeadList} setMessage={setMessage}
          />
        </CustomModal>

        <CustomModal open={updateLeadModal} handleClose={handleCloseUpdateLead}>
          <UpdateLeads
            handleClose={handleCloseUpdateLead}
            industryType={industryType}
            titles={titles}
            rating={rating}
            clientCompany={clientCompany}
            leadSource={leadSource}
            leadStatus={leadStatus}
            leadOwner={leadOwner}
            getAllLead={getAllLead.find((v) => v.leadId == selectedLeadId)}
            conutries={conutries}
            getLeadList={getLeadList}
            setMessage={setMessage}
            leadId={selectedLeadId}
            businessGroup={businessGroup} />
        </CustomModal>
        <MessageBox
          open={message.open}
          onClose={() => setMessage({ ...message, open: false })}
          message={message.text}
          handleClose={() => setMessage({ ...message, open: false })}
          type={message.type}
        />
        <CustomDelete
          handleDelete={handleDelete}
          open={deleteAlert}
          rowId={rowId}
          handleClose={() => setDeleteAlert(false)}
        />
        <CustomFilter
          drawerTitle="Filter Options"
          drawerWidth={350}
          openDrawer={openDrawer}
          onClose={filterClose}
          drawerContent={
            <>
              <Box>
                <Grid container spacing={1} sx={{ padding: 0, }}>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Company</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomAutocomplete
                      options={clientCompany}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Company"
                      onChange={(event, newValue) => handleFilterChange("clientCompanyId", newValue?.id)}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Country</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomAutocomplete
                      options={conutries}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Country"
                      onChange={(event, newValue) => handleFilterChange("countryId", newValue?.id)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Lead Status</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomAutocomplete
                      options={leadStatus}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Lead Status"
                      onChange={(event, newValue) => handleFilterChange("leadStatusId", newValue?.id)}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Lead Source</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomAutocomplete
                      options={leadSource}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Lead Source"
                      onChange={(event, newValue) => handleFilterChange("leadSourceId", newValue?.id)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>Industry</Typography>

                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomAutocomplete
                      options={industryType}
                      getOptionLabel={(option) => option.title}
                      placeholder="Select Industry"
                      onChange={(event, newValue) => handleFilterChange("industry", newValue?.id)}
                    />
                  </Grid>
                  < Grid item xs={5} sm={5} md={5}>
                    <Typography sx={filterLabelStyle}>Business Group</Typography>
                  </Grid>
                  <Grid item xs={7} sm={7} md={7}>
                    <CustomAutocomplete
                      options={businessGroup}
                      getOptionLabel={(option) => option.title}
                      placeholder="Business Group"
                      onChange={(event, newValue) => handleFilterChange("businessGroup", newValue?.id)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>From Date</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomTextField
                      type="date"
                      value={filterPayload.fromDate}
                      onChange={(e) => handleFilterChange('fromDate', e.target.value)}
                      placeholder="From Date"
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={4}>
                    <Typography sx={filterLabelStyle}>To Date</Typography>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <CustomTextField
                      type="date"
                      value={filterPayload.toDate}
                      onChange={(e) => handleFilterChange('toDate', e.target.value)}
                      placeholder="To Date"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'center', marginTop: "100px" }}>
                    <CustomButton
                      startIcon={<FindReplaceOutlinedIcon />}
                      title="Apply"
                      size="small"
                      variant="contained"
                      onClick={getLeadList}
                    />
                    <CustomButton
                      variant="outlined"
                      title="Clear"
                      startIcon={<ClearRounded />}
                      style={cif_fob_style}
                      onClick={handleClearFilters}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          }
        />

      </Box>
    </>
  );
};
export default Lead;

const filterLabelStyle = {
  fontSize: '14px',
  color: "#212529",
  paddingTop: "10px",
  textTransform: "capitalize"
}
const cif_fob_style = {
  backgroundColor: '#ffff',
  color: '#007bff',
  textTransform: "capitalize",
  borderRadius: 4,
  border: '1px solid #007bff',
  height: 30,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: "100px"

}

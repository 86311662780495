import React, { useContext, useEffect, useState } from "react";
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { AppBarCompany, } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MessageBox from "../../common/CustomMessageBox";
import CustomDelete from '../../../components/common/CustomDeleteAlert';
import axios from "axios";
import CustomModal from "../../../components/common/CustomModal";
import { getBusinessGroup, getCIFFOBList, getClientCompany, getCountries, getCurrencyList, getIndustryType, getLeadSource, getLeadStatus, getOwner, getRating, getShipmentTypes, getSuppliersList, getTitles } from '../../../services/CommonServices';
import { genericGet } from "../../../services/apiServices";
import { Box, IconButton } from "@mui/material";
import { DELETE_IMPORT_SHIPMENT, LIST_ALL_IMPORT_SHIPMENT } from "../../../services/apiRoutes";
import Createimportshipement from "../../importshipement/createimportshipement";
import UpdateImportshipement from "../../importshipement/UpdateImportshipement";
import { useNavigate, useParams } from "react-router-dom";
import { useClientCompanyDetails } from "./UseClientCompanyDetails";
import { genericPost } from "../../../services/authServices";
import LeadForm from "../../Leads/CreateLead";
import { AuthContext } from "../../../context/AuthContext";
import DirectionsBoatOutlinedIcon from '@mui/icons-material/DirectionsBoatOutlined';
import CustomLoader from "../../common/CustomLoader";

export default function ImportMenu() {
    const { token } = useContext(AuthContext)
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();  // Initialize useNavigate
    const { clientCompanyId } = useParams();
    const { companyDetail, loading: loadingClient } = useClientCompanyDetails(clientCompanyId);
    const [open, setOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('Import Shipment');
    const [leadModal, setLeadModal] = useState(false);
    const [importShipments, setImportShipments] = useState([]);
    const [cifFobList, setCIFFOBList] = useState([]);
    const [shipmentTypes, setShipmentTypes] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [suppliersList, setSuppliersList] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [rowId, setRowId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null); // State to hold selected ID
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [newShippingId, setNewShippingId] = useState(null);


    useEffect(() => {
        fetchImportShipments();
        getCommonData();
    }, [token]);

    const handleUpdateClick = (importShipmentId) => {
        setSelectedId(importShipmentId);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };


    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true);
        setRowId(rowId);
    };


    const handleDelete = async (importShipmentId) => {
        setLoading(true);
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(DELETE_IMPORT_SHIPMENT(importShipmentId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                fetchImportShipments();
                setDeleteAlert(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error deleting import shipment", error);
        }
    };

    const fetchImportShipments = async () => {
        setLoading(true);
        try {
            const payload = {
                supplierId: clientCompanyId,
            };
            const response = await genericPost(LIST_ALL_IMPORT_SHIPMENT, payload, {});
            if (response.data.success && response.data.shipments) {
                const { newShippingId, lastShipmentId, shipments } = response.data;

                const shipmentData = response.data.shipments.map((shipment, index) => ({
                    importShipmentId: shipment.importShipmentId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    supplierName: shipment.supplier.clientCompanyName || '',
                    mailingCountry: shipment.supplier.mailingCountry.name || '',
                    shipmentId: shipment.shipmentId || '',
                    expectedEtd: shipment.expectedEtd || '',
                    noOfContainers: shipment.noOfContainers || '',
                    cifFob: shipment.cifFob.cifFobName || '',
                    currency: shipment.currency.currency || '',
                }));
                setImportShipments(shipmentData);
                setNewShippingId(newShippingId);

            } else {
                setError('No import shipments found');
            }
        } catch (error) {
            setError('Error fetching import shipments');
            console.error('Error fetching import shipments', error);
        } finally {
            setLoading(false);
        }
    };

    const getCommonData = async () => {
        const cifFobListData = await getCIFFOBList();
        setCIFFOBList(cifFobListData);
        const shipmentTypesData = await getShipmentTypes();
        setShipmentTypes(shipmentTypesData);
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
        const suppliersListData = await getSuppliersList();
        setSuppliersList(suppliersListData);
    }
    const ImportShipmentColumn = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        {
            accessor: "expectedEtd", label: "Date", id: "expectedEtd", styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            accessor: "shipmentId", label: "Shipment ID", id: "shipmentId", styleAccessor: {
                textAlign: 'center',
            },
        },
        { accessor: "supplierName", label: "Supplier Name", id: "supplierName" },
        { accessor: "mailingCountry", label: "Country", id: "mailingCountry" },
        {
            accessor: "noOfContainers", label: "No. Of Container", id: "noOfContainers", styleAccessor: {
                textAlign: 'center',
            },
        },
        { accessor: "cifFob", label: "CIF/FOB", id: "cifFob" },
        { accessor: "currency", label: "Currency", id: "currency" },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.importShipmentId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: "#059212" }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.importShipmentId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.importShipmentId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                    </IconButton>
                </div>
            ),
        },
    ];
    const handleViewClick = (importShipmentId) => {
        // navigate(`/app/import/shipement/${importShipmentId}`);
        navigate(`/app/import/shipementview/${importShipmentId}`);
    };

    if (!companyDetail) {
        return <Box> <CustomLoader open={loadingClient} />
        </Box>;
    }

    const clientCompany = [
        {
            id: Number(clientCompanyId),
            title: companyDetail?.clientCompanyName || 'N/A',
        },
    ];
    const companyName = companyDetail.clientCompanyName;
    const phoneNumber = companyDetail.mobile;
    const initial = companyDetail.clientCompanyName ? companyDetail.clientCompanyName.charAt(0).toUpperCase() : '';
    const handleBackClick = () => {
        navigate("/app/company");
    };


    return (
        <>
            <div>
                {/* <DetailsPageAppbar
                    handleScrollTo={setActiveTab}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={() => setOpen(true)}
                    handleDrawerClose={() => setOpen(false)}
                    AppBarMenu={AppBarCompany(clientCompanyId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}
                />
                <Main open={open}> */}

                <DetailsPageCard
                    Icon={DirectionsBoatOutlinedIcon}
                    columns={ImportShipmentColumn}
                    title={'Import Shipment'}
                    buttonTitle={'Create Import Shipment'}
                    handleOpen={handleOpen}
                    data={importShipments}
                />

                <CustomModal open={modal} handleClose={handleClose}>
                    <Createimportshipement
                        handleClose={handleClose}
                        fetchImportShipments={fetchImportShipments}
                        setMessage={setMessage}
                        cifFobList={cifFobList}
                        shipmentTypes={shipmentTypes}
                        currencyList={currencyList}
                        suppliersList={clientCompany}
                        newShippingId={newShippingId}

                    />
                </CustomModal>
                <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateImportshipement
                        handleClose={handleCloseUpdate}
                        importShipmentId={selectedId}
                        fetchImportShipments={fetchImportShipments}
                        setMessage={setMessage}
                        cifFobList={cifFobList}
                        shipmentTypes={shipmentTypes}
                        currencyList={currencyList}
                        suppliersList={clientCompany}
                    />
                </CustomModal>

                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />

                <CustomDelete
                    handleDelete={handleDelete}
                    open={deleteAlert}
                    rowId={rowId}
                    handleClose={() => setDeleteAlert(false)}
                />
                {/* </Main> */}
            </div>
        </>
    );
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Grid, Divider, Typography, FormLabel } from "@mui/material";
import CustomButton from "../common/CustomButton";
import { ClearRounded, SaveRounded, Replay10Rounded } from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { genericPost, genericGet } from "../../services/apiServices";
import { CreateMyCompany, GET_COMPANY_id, UPDATE_COMPANY } from "../../services/apiRoutes";
import MessageBox from "../common/CustomMessageBox";
import axios from "axios";
import CustomStyle from "../common/CustomStyle";
import CustomAutocomplete from "../common/CustomAutoComplete";

const ValidationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    mobile: Yup.string().required("Mobile is required"),
    email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
    website: Yup.string()
        .url("Invalid URL format")
        .required("Website is required"),
    companyUrl: Yup.string().url("Invalid URL format"),
    defaultCompany: Yup.boolean(),
    telephone: Yup.string(),
    fax: Yup.string(),
    address: Yup.string().required("Address is required"),
    addressLineOne: Yup.string().required("Address Line One is required"),
    addressLineTwo: Yup.string(),
    countryId: Yup.number().required("Country is required"),
    stateId: Yup.number().required("State is required"),
    cityId: Yup.number().required("City is required"),
    talukaId: Yup.string(),
    pincode: Yup.string().required("Pincode is required"),
    gstin: Yup.string(),
    fiscalYearBeginMonth: Yup.string(),
    prefix: Yup.string(),
    companyLogo: Yup.string(),
    companyFooter: Yup.string(),
    companySignStamp: Yup.string(),
});

const UpdateCompany = ({ handleClose, companyId, setMessage, fetchData, }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!companyId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(GET_COMPANY_id(companyId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                setData(response.data.company);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [companyId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_COMPANY(companyId), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "Export updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                },);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    companyName: data?.companyName || '',
                    mobile: data?.mobile || '',
                    email: data?.email || '',
                    website: data?.website || '',
                    companyUrl: data?.companyUrl || '',
                    defaultCompany: false,
                    telephone: data?.telephone || '',
                    fax: data?.fax || '',
                    address: data?.address || '',
                    addressLineOne: data?.addressLineOne || '',
                    addressLineTwo: data?.addressLineTwo || '',
                    countryId: '',
                    stateId: '',
                    cityId: '',
                    talukaId: data?.talukaId || '',
                    pincode: data?.pincode || '',
                    gstin: data?.gstin || '',
                    fiscalYearBeginMonth: data?.fiscalYearBeginMonth || '',
                    prefix: data?.prefix || '',
                    companyLogo: data?.companyLogo || '',
                    companyFooter: data?.companyFooter || '',
                    companySignStamp: data?.companySignStamp || '',

                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, values, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={1} style={CustomStyle.container}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                                    Update Company
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={CustomStyle.buttonContainer}>
                                <Box style={CustomStyle.buttonBox}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid
                            container
                            spacing={1}
                            style={{ paddingLeft: "5%", paddingRight: "5%" }}
                        >
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="companyName"
                                    fieldLabel="Company Name"
                                    value={values.companyName}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Company Name"
                                />
                                {touched.companyName && errors.companyName ? (
                                    <p style={{ color: "red", fontSize: "12px" }}>
                                        {errors.companyName}
                                    </p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    type="number"
                                    name="mobile"
                                    fieldLabel="Mobile"
                                    value={values.mobile}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Mobile"
                                />
                                {touched.mobile && errors.mobile ? (
                                    <p style={{ color: "red", fontSize: "12px" }}>
                                        {errors.mobile}
                                    </p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="email"
                                    type="email"
                                    fieldLabel="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Email"
                                />
                                {touched.email && errors.email ? (
                                    <p style={{ color: "red", fontSize: "12px" }}>
                                        {errors.email}
                                    </p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="website"
                                    type="url"
                                    fieldLabel="Website"
                                    value={values.website}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Website"
                                />
                                {touched.website && errors.website ? (
                                    <p style={{ color: "red", fontSize: "12px" }}>
                                        {errors.website}
                                    </p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="companyUrl"
                                    type="url"
                                    fieldLabel="Company URL"
                                    value={values.companyUrl}
                                    onChange={handleChange}
                                    placeholder="Enter Company URL"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="telephone"
                                    type="number"
                                    fieldLabel="Telephone"
                                    value={values.telephone}
                                    onChange={handleChange}
                                    placeholder="Enter Telephone"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="fax"
                                    fieldLabel="Fax"
                                    value={values.fax}
                                    onChange={handleChange}
                                    placeholder="Enter Fax"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="address"
                                    fieldLabel="Address"
                                    value={values.address}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Address"
                                />
                                {touched.address && errors.address ? (
                                    <p style={{ color: "red", fontSize: "12px" }}>
                                        {errors.address}
                                    </p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="addressLineOne"
                                    fieldLabel="Address Line One"
                                    value={values.addressLineOne}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Address Line One"
                                />
                                {touched.addressLineOne && errors.addressLineOne ? (
                                    <p style={{ color: "red", fontSize: "12px" }}>
                                        {errors.addressLineOne}
                                    </p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="addressLineTwo"
                                    fieldLabel="Address Line Two"
                                    value={values.addressLineTwo}
                                    onChange={handleChange}
                                    placeholder="Enter Address Line Two"
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={6} md={6}>
                                <CustomAutocomplete
                                    options={countries}
                                    fieldLabel="Country"
                                    required={true}
                                    getOptionLabel={(option) => option?.title}
                                    onChange={(e, newValue) => {
                                        handleChangeCountry(newValue);
                                        handleChange({
                                            target: { name: "countryId", value: newValue.id },
                                        });
                                    }}
                                    placeholder="Select Country"
                                />
                                {touched.countryId && errors.countryId ? (
                                    <p style={{ color: "red", fontSize: "12px" }}>
                                        {errors.countryId}
                                    </p>
                                ) : null}
                            </Grid> */}
                            {/* <Grid item xs={12} sm={6} md={6}>
                                <CustomAutocomplete
                                    options={states}
                                    fieldLabel="State"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    // value={values.state}
                                    onChange={(e, newValue) => {
                                        handleChangeState(newValue);

                                        handleChange({
                                            target: { name: "stateId", value: newValue.id },
                                        });
                                    }}
                                    placeholder="Select State"
                                />
                                {touched.stateId && errors.stateId ? (
                                    <p style={{ color: "red", fontSize: "12px" }}>
                                        {errors.stateId}
                                    </p>
                                ) : null}
                            </Grid> */}
                            {/* <Grid item xs={12} sm={6} md={6}>
                                <CustomAutocomplete
                                    options={cities}
                                    fieldLabel="City"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    // value={values.state}
                                    onChange={(e, newValue) =>
                                        handleChange({
                                            target: { name: "cityId", value: newValue.id },
                                        })
                                    }
                                    placeholder="Select City"
                                />
                                {touched.city && errors.city ? (
                                    <p style={{ color: "red", fontSize: "12px" }}>
                                        {errors.city}
                                    </p>
                                ) : null}
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    type="number"
                                    name="pincode"
                                    fieldLabel="Pincode"
                                    value={values.pincode}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Pincode"
                                />
                                {touched.pincode && errors.pincode ? (
                                    <p style={{ color: "red", fontSize: "12px" }}>
                                        {errors.pincode}
                                    </p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="gstin"
                                    fieldLabel="GSTIN"
                                    value={values.gstin}
                                    onChange={handleChange}
                                    placeholder="Enter GSTIN"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="fiscalYearBeginMonth"
                                    fieldLabel="Fiscal Year Begin Month"
                                    value={values.fiscalYearBeginMonth}
                                    onChange={handleChange}
                                    placeholder="Enter Fiscal Year Begin Month"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="prefix"
                                    fieldLabel="Prefix"
                                    value={values.prefix}
                                    onChange={handleChange}
                                    placeholder="Enter Prefix"
                                />
                            </Grid>
                            {/* <Grid item xs={12} sm={6} md={6}>
                                <Box>
                                    <FormLabel style={fieldLabelstyle}>
                                        {"Company Logo"}
                                    </FormLabel>
                                    <input
                                        type="file"
                                        name="companyLogo"
                                        onChange={(e) => {
                                            let file = e.target.files[0];
                                            setFieldValue("companyLogo", file);

                                            convertFileToBase64(file, (base64String) => { });
                                        }}
                                    />
                                </Box>
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={6}>
                                <Box>
                                    <FormLabel style={fieldLabelstyle}>
                                        {"Company Footer"}
                                    </FormLabel>
                                    <input
                                        type="file"
                                        name="companyFooter"
                                        onChange={(e) => {
                                            let file = e.target.files[0];
                                            setFieldValue("companyFooter", file);
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Box>
                                    <FormLabel style={fieldLabelstyle}>
                                        {"Company Sign Stamp"}
                                    </FormLabel>
                                    <input
                                        type="file"
                                        name="companySignStamp"
                                        onChange={(e) => {
                                            let file = e.target.files[0];
                                            setFieldValue("companySignStamp", file);
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateCompany;


const fieldLabelstyle = {
    color: "#212529",
    fontWeight: "600",
    fontSize: "14px",
};
import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/AssignmentLateOutlined';

const ContentWrapper = styled('section')(({ theme }) => ({
    padding: theme.spacing(0),
}));

const CustomCard = styled(Card)(({ theme }) => ({
    minHeight: '325px',
    maxHeight: '400px',
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: theme.shadows[1],
    },
}));

const CardBody = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
}));

const CustomLink = styled(RouterLink)(({ theme }) => ({
    display: 'flex',
    fontSize: "12px",
    fontWeight: 600,
    alignItems: 'center',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    marginTop: theme.spacing(1),
    transition: 'color 0.2s',
    '&:hover': {
        color: '#0652DD',
        textDecoration: 'underline',
    },
}));


const cards = [
    {
        title: 'Import Shipment',
        links: [
            { to: '/app/reports/importshipmentreport', icon: <InsertDriveFileIcon />, text: 'Import Shipment Report' },
            { to: '/app/reports/freedaysreport', icon: <InsertDriveFileIcon />, text: 'Free Days Report' },
            { to: '/app/reports/batchnowiseimportshipmentreport', icon: <InsertDriveFileIcon />, text: 'Batch no. wise Imp. shipment Report' },

        ],
    },
    {
        title: 'Export Shipment',
        links: [
            { to: '/app/reports/proforminvreport', icon: <InsertDriveFileIcon />, text: 'Proform Inv. Report' },
            { to: '/app/reports/salescontractreport', icon: <InsertDriveFileIcon />, text: 'Sales Contract Report' },
            { to: '/app/reports/commercialinvoiceregister', icon: <InsertDriveFileIcon />, text: 'Commercial Invoice Register' },
            { to: '/app/reports/batchnowiseexportshipmentreport', icon: <InsertDriveFileIcon />, text: 'Batch no. wise Exp. shipment Report' },

        ],
    },
    {
        title: 'Ledger',
        links: [
            { to: '/app/reports/customerledger', icon: <InsertDriveFileIcon />, text: 'Customer Ledger' },
            { to: '/app/reports/supplierledger', icon: <InsertDriveFileIcon />, text: 'Supplier Ledger' },
        ],
    },
    {
        title: 'Register and Payment ',
        links: [
            { to: '/app/reports/paymentregister', icon: <InsertDriveFileIcon />, text: 'Payment Register' },
            { to: '/app/reports/receiptregister', icon: <InsertDriveFileIcon />, text: 'Receipt Register' },
            { to: '/app/reports/importpaymentreport', icon: <InsertDriveFileIcon />, text: 'Import payment Report' },
            { to: '/app/reports/exportpaymentreport', icon: <InsertDriveFileIcon />, text: 'Export payment Report' },
        ],
    },
    {
        title: 'Costing',
        links: [
            { to: '/app/reports/shipmentcostingreport', icon: <InsertDriveFileIcon />, text: 'Shipment Costing Report' },
            { to: '/app/reports/productwiseexportshipment', icon: <InsertDriveFileIcon />, text: 'Product wise Export shipment' },
            { to: '/app/reports/importshipmentcostingreport', icon: <InsertDriveFileIcon />, text: 'Import shipment costing Report' },
            { to: '/app/reports/exportshipmentcostingreport', icon: <InsertDriveFileIcon />, text: 'Export shipment costing Report' },
        ],
    },
    {
        title: 'Tracking',
        links: [
            { to: '/app/reports/importshipmenttracking', icon: <InsertDriveFileIcon />, text: 'Import Shipment Tracking' },
            { to: '/app/reports/exportshipmenttracking', icon: <InsertDriveFileIcon />, text: 'Export Shipment Tracking' },
        ],
    },


    {
        title: 'Report',
        links: [


            { to: '/app/reports/blreport', icon: <InsertDriveFileIcon />, text: 'B/L Report' },
            { to: '/app/reports/productwiseimportreport', icon: <InsertDriveFileIcon />, text: 'Product wise import Report' },
            { to: '/app/reports/productwiseexportreport', icon: <InsertDriveFileIcon />, text: 'Product wise export Report' },
        ],
    }

];



const ResponsiveCards = () => {
    return (
        <div>
            <ContentWrapper style={{ marginTop: '0px' }} variant="outlined">
                <CardContent>
                    <Heading variant="h4" style={{ paddingBottom: '10px' }}>
                        Reports
                    </Heading>
                    <Grid container spacing={2}>
                        {cards.map((card, index) => (
                            <Grid item key={index} xs={12} sm={6} md={3}>
                                <CustomCard variant="outlined">
                                    <CardBody>
                                        <Heading variant="h4">{card.title}</Heading>
                                        {card.links.map((link, linkIndex) => (
                                            <Typography key={linkIndex} variant="body2">
                                                <CustomLink to={link.to}>
                                                    <span style={{ fontSize: "15px", marginTop: "2px" }}> {link.icon}  </span> &nbsp; {link.text}
                                                </CustomLink>
                                            </Typography>
                                        ))}
                                    </CardBody>
                                    <br />
                                </CustomCard>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </ContentWrapper>
        </div>
    );
};

export default ResponsiveCards;

import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import { ClearRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import { READ_IMPORT_SHIPPING_BOOKING } from '../../../services/apiRoutes';
import CustomCardView from "../../common/CustomCardView";
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const ShippingBookingView = ({ handleClose, setMessage, fetchData, shippingBookingId }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            if (!shippingBookingId) return;

            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_IMPORT_SHIPPING_BOOKING(shippingBookingId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
                setData(response.data.shippingBooking);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [shippingBookingId]);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    // Define labels as an array of objects for all keys
    const labels = [
        { key: 'cifFobName', label: "Shipping Type", sm: 12, md: 6, },
        { key: 'portOfLoading', label: "Port of Loading", sm: 12, md: 6, },
        { key: 'portOfDestination', label: "Port of Destination", sm: 12, md: 6, },
        { key: 'directName', label: "Direct/Indirect", sm: 12, md: 6, },
        { key: 'shippingLineName', label: "Shipping Line", sm: 12, md: 6, },
        { key: 'currencyId', label: "Currency", sm: 12, md: 6, },
        { key: 'seaFreight', label: "Sea Freight", sm: 12, md: 6, },
        { key: 'etdDate', label: "ETD Date", sm: 12, md: 6, sm: 12, md: 6, },
        { key: 'cutOffDate', label: "Cut Off Date", sm: 12, md: 6, sm: 12, md: 6, },
        { key: 'destinationCharges', label: "Destination Charges", sm: 12, md: 6, },
        { key: 'cfsLocalCharges', label: "CFS Local Charges", sm: 12, md: 6, },
        { key: 'shippingLineCharges', label: "Shipping Line Charges", sm: 12, md: 6, },
        { key: 'blCharges', label: "BL Charges", sm: 12, md: 6, },
        { key: 'numberOfContainers', label: "Number of Containers", sm: 12, md: 6, },
        { key: 'freeDaysAtDestination', label: "Free Days at Destination", sm: 12, md: 6, },
        { key: 'fclName', label: "fcl Name" },
        { key: 'agencyName', label: "Freight Forward Agency", sm: 12, md: 6, },
        { key: 'chaName', label: "Custom Clearing Agency", sm: 12, md: 6, },
        { key: 'note', label: "Note", sm: 12, md: 6, },
    ];
    const values = {
        cifFobName: data?.cifFob?.cifFobName,
        portOfLoading: data?.portOfLoading,
        portOfDestination: data?.portOfDestination,
        directName: data?.direct?.directName,
        shippingLineName: data?.shippingLines?.shippingLineName,
        currencyId: data?.currency?.currency,
        etdDate: data?.etdDate,
        cutOffDate: data?.cutOffDate,
        etaDate: data?.etaDate,
        numberOfContainers: data?.numberOfContainers,
        freightForwardAgencyId: data?.freightForwardAgencyId,
        clearingAgencyId: data?.clearingAgencyId,
        freeDaysAtDestination: data?.freeDaysAtDestination,
        note: data?.note,
        fclName: data?.fcl?.fclName,
        agencyName: data?.freightForwardAgency?.agencyName,
        chaName: data?.customClearingAgency?.chaName,
        shippingLineCharges: `${data?.baseCurrency?.currency_symbol || ''} ${data?.shippingLineCharges}`,
        cfsLocalCharges: `${data?.baseCurrency?.currency_symbol || ''} ${data?.cfsLocalCharges}`,
        destinationCharges: `${data?.baseCurrency?.currency_symbol || ''} ${data?.destinationCharges}`,
        blCharges: `${data?.baseCurrency?.currency_symbol || ''} ${data?.blCharges}`,
        seaFreight: `${data?.currency?.currency_symbol || ''} ${parseFloat(data?.seaFreight || 0).toFixed(2)}`,
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={values}
                enableReinitialize
            >
                {() => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600 }} style={{ fontSize: "18px", paddingTop: "5px" }}>
                                    Shipping Booking
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>

                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="print">
                                        <LocalPrintshopOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close">
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} onClick={handleClose} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid item xs={12}>
                            <CustomCardView label={labels} data={values} />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ShippingBookingView;

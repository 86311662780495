import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, CircularProgress, Divider, Card, CardContent } from '@mui/material';
import { Formik, Form } from 'formik';
import { ClearRounded } from "@mui/icons-material";
import axios from 'axios';
import CustomButton from "../../common/CustomButton";
import { READ_IMPORT_SHIPPING_MARK } from '../../../services/apiRoutes';
import CustomCardView from "../../common/CustomCardView";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { renderHtmlContent } from "../../../services/apiServices";


const ShippingMarkView = ({ handleClose, shippingMarkId, productsList }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetching data
    useEffect(() => {
        if (!shippingMarkId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_IMPORT_SHIPPING_MARK(shippingMarkId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.shipment);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [shippingMarkId]);



    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    // Get the selected product based on the productId from the fetched data
    const selectedProduct = productsList.find(product => product.id === data?.productId);
    const labels = [
        { key: 'batchNo', label: "Batch No", sm: 12, md: 6, },
        { key: 'productName', label: "Product Name", sm: 12, md: 6, },
        { key: 'shippingMark', label: "Shipping Mark", sm: 12, md: 12, },
    ];

    const values = {
        batchNo: data?.batchNo,
        productName: selectedProduct?.title || '',
        shippingMark: renderHtmlContent(data?.shippingMark),
    };


    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={values}
                enableReinitialize
            >
                {() => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4}>
                                <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px", paddingTop: "5px" }}>
                                    Shipping Mark
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box>
                                    <IconButton aria-label="delete">
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: '20px', color: '#007bff' }} />
                                    </IconButton>
                                    <IconButton aria-label="close">
                                        <CloseIcon sx={{ fontSize: '20px', color: '#007bff' }} onClick={handleClose} />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />

                        {/* Shipment Information Display */}
                        <Grid item xs={12}>
                            <CustomCardView
                                label={labels}
                                data={values}
                            />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ShippingMarkView;

import React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import CustomAutocomplete from '../common/CustomAutoComplete';
import CustomButton from '../common/CustomButton';
import CustomMaster from '../common/CustomMaster';

const Template = ({ values, errors, touched, setFieldValue }) => {
    // Sample voucher data for the autocomplete options
    const templateData = [
        { id: 1, title: 'List' },
        { id: 2, title: 'Ganmtt' },
        { id: 3, title: 'Kanban' },
    ];
    const defaultView = 'template';
    const mainContent = (
        <>
            <Box sx={{ width: '300px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={10}
                    >
                        <CustomAutocomplete
                            options={templateData}
                            fieldLabel="Select Template"
                            required
                            getOptionLabel={(option) => option.title}
                            placeholder="Select Template"
                        />
                    </Grid>
                    <Grid item xs={12} md={2}
                    > <Box sx={{ pt: 3 }}>
                            <CustomButton
                                title="Save"
                                size="small"
                                variant="contained"
                                type="submit"
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Box >
        </>
    );

    return (
        <CustomMaster
            defaultView={defaultView}
            mainContent={mainContent} />
    );
};

export default Template;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DataTable from "../../components/common/DataTable";
import CustomModal from "../../components/common/CustomModal";
import { GET_clientCompanies, GET_PRODUCTS_LIST } from "../../services/apiRoutes"
import { genericPost } from '../../services/authServices';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomLoader from "../../components/common/CustomLoader";
import MessageBox from "../../components/common/CustomMessageBox";
import { AuthContext } from "../../context/AuthContext";
import CreateManageProducts from "./CreateMasters/CreateManageProducts";
import { genericGet } from "../../services/apiServices";
import { getUnitList } from "../../services/CommonServices";
import UpdateManageProducts from "./UpdateMaster/UpdateManageProducts";
import CustomMaster from "../common/CustomMaster";

const title = "Manage Product";

const ManageProducts = () => {
    const { token } = useContext(AuthContext)
    const [modal, setModal] = useState(false);
    const [clientCompanies, setClientCompanies] = useState([]);

    const [getAllProducts, setGetAllProducts] = useState([]);
    const [UnitList, setUnitList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })

    const handleUpdateClick = (productsId) => {
        setSelectedId(productsId);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };


    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: "productName", header: "product Name", tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 50
            },
            {
                accessorKey: "clientCompanyName", header: "Client Company", tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 50
            },
            {
                accessorKey: "hsn", header: "HSN", tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 50
            },
            {
                accessorKey: "unitName", header: "Unit", tableStyle: { textAlign: "center" }, size: 50
            },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                            <IconButton onClick={() => handleUpdateClick(row.original.productsId)}>
                                <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                            </IconButton>
                            <IconButton
                            >
                                <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                            </IconButton>
                        </div>
                    </>

                ),
            },

        ],
        []
    );

    const getProductList = async () => {
        setLoading(true);
        try {
            const response = await genericPost(GET_PRODUCTS_LIST, {});

            if (response.data.success && response.data.productsList) {
                const productData = response.data.productsList.map((product, index) => ({
                    productsId: product.productsId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    userId: product.userId || '',
                    companyId: product.companyId || '',
                    productName: product.productName || '',
                    clientCompanyName: product.clientCompany?.clientCompanyName || '',
                    hsn: product.hsn || '',
                    createdAt: product.createdAt || '',
                    updatedAt: product.updatedAt || '',
                    unitName: product.productsUnit?.unitName || '',
                }));

                setGetAllProducts(productData);
            } else {
                setError('No products found');
            }
        } catch (error) {
            setError('Error fetching products');
            console.error('Error fetching products', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchClientCompanies = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_clientCompanies });

            if (response.data.success && response.data.clientCompanies) {
                const clientCompanyData = response.data.clientCompanies.map((company) => ({
                    id: company.clientCompanyId || '',
                    title: company.clientCompanyName || '',

                }));
                setClientCompanies(clientCompanyData);
            } else {
                setError('No client companies found');
            }
        } catch (error) {
            setError('Error fetching client companies');
            console.error('Error fetching client companies', error);
        } finally {
            setLoading(false);
        }
    };
    const getCommonData = async () => {
        const UnitListData = await getUnitList();
        setUnitList(UnitListData);
    }

    useEffect(() => {
        getProductList()
        fetchClientCompanies()
        getCommonData()
    }, [token])

    const handleOpen = () => {
        setModal(true);
    };
    const handleClose = () => {
        setModal(false)
    }

    const defaultView = 'manageproducts';
    const mainContent = (
        <>
            <Box sx={{ padding: "10px" }}>
                <CustomLoader open={loading} />
                <DataTable
                    title={title}
                    columns={columns}
                    data={getAllProducts}
                    handleOpen={handleOpen}
                    buttonTitle="Add Product"
                />
                <CustomModal
                    modalStyles={{
                        maxHeight: '45vh',
                        top: "10%",
                    }}
                    open={modal} handleClose={handleClose}>
                    <CreateManageProducts
                        handleClose={handleClose}
                        clientCompanyList={clientCompanies}
                        fetchData={getProductList}
                        setMessage={setMessage}
                        UnitList={UnitList}
                    />
                </CustomModal>
                <CustomModal
                    modalStyles={{
                        maxHeight: '45vh',
                        top: "10%",
                    }}
                    open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateManageProducts
                        handleClose={handleCloseUpdate}
                        clientCompanyList={clientCompanies}
                        UnitList={UnitList}
                        productsId={selectedId}
                        fetchData={getProductList}
                        setMessage={setMessage}
                    />
                </CustomModal>
                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />

            </Box>
        </>
    );

    return (
        <CustomMaster
            defaultView={defaultView}
            mainContent={mainContent} />
    );
};
export default ManageProducts;


import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DataTable from "../../components/common/DataTable";
import { CREATE_TERM_CONDITIONS, GET_TERM_CONDITIONS } from "../../services/apiRoutes"
import { genericPost } from '../../services/authServices';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomLoader from "../../components/common/CustomLoader";
import MessageBox from "../../components/common/CustomMessageBox";
import { AuthContext } from "../../context/AuthContext";
import { genericGet } from "../../services/apiServices";
import { CircularProgress } from "@mui/material";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
    SaveRounded,
} from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import CustomButton from "../common/CustomButton";
import { getVoucher } from "../../services/CommonServices";
import CustomAutocomplete from "../common/CustomAutoComplete";
import CustomTextarea from "../common/CustomTextarea";
import CustomMaster from "../common/CustomMaster";

const title = "Term and Condition";

const ValidationSchema = Yup.object().shape({
    voucherName: Yup.string().required('voucherName is required'),
    termConditions: Yup.string().required('termConditions is required'),
});

const TermConditions = () => {
    const { token } = useContext(AuthContext)
    const [modal, setModal] = useState(false);

    const [data, setData] = useState([]);
    const [voucher, setVoucher] = useState([]);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);

    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })

    const columns = useMemo(
        () => [
            { accessorKey: "id", header: "SN", tableStyle: { textAlign: "center" }, size: 50 },
            {
                accessorKey: "voucherName", header: "Voucher", tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 50
            },
            {
                accessorKey: "termConditions", header: "Term and conditions", tableHeader: {
                    justifyContent: 'flex-start',
                }, size: 600
            },
            {
                accessorKey: "action",
                header: "Action",
                size: 10,
                Cell: ({ row }) => (
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                            <IconButton>
                                <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                            </IconButton>
                            <IconButton>
                                <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                            </IconButton>
                        </div>
                    </>
                ),
            },
        ],
        []
    );

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await genericGet({ url: GET_TERM_CONDITIONS });
            if (response.data) {
                const CustumData = response.data.termConditions.map((data, index) => ({
                    ...data,
                    id: data.id || '',
                    voucherName: data.voucher.voucherName || '',
                    termConditions: data.termConditions || '',

                }));
                setData(CustumData);
                setLoading(false)

            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error fetching ", error);
        }
    };

    const getCommonData = async () => {
        const voucherData = await getVoucher();
        setVoucher(voucherData);
    }

    useEffect(() => {
        fetchData()
        getCommonData()
    }, [token])

    const handleOpen = () => {
        setModal(true);
    };
    const handleClose = () => {
        setModal(false)
    }
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            const payload = {
                voucherName: values.voucherName,
                termConditions: values.termConditions,
            };
            const response = await genericPost(CREATE_TERM_CONDITIONS, payload);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "added successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                }, 1000);
                resetForm();
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        voucherName: '',
        termConditions: '',

    });
    const defaultView = 'termConditions';
    const mainContent = (
        <>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, setFieldValue, values, handleChange }) => (
                    <Form>
                        <Grid container spacing={2} sx={{ px: "0%" }}>
                            <Grid item xs={12} md={3}>
                                <CustomAutocomplete
                                    options={voucher}
                                    fieldLabel="Voucher"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={voucher.find((option) => option.id === values.voucherName) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('voucherName', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Voucher"
                                    error={touched.voucherName && !!errors.voucherName}
                                    helperText={touched.voucherName && errors.voucherName}
                                />
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <CustomTextarea
                                    placeholder="Enter Term Conditions"
                                    name="termConditions"
                                    fieldLabel="Term Conditions"
                                    required={true}
                                    multiline
                                    rows={4}
                                    value={values.termConditions}
                                    onChange={handleChange}
                                    error={touched.termConditions && !!errors.termConditions}
                                    helperText={touched.termConditions && errors.termConditions}
                                    sx={{ height: "150px !important" }}
                                />
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <Box sx={{ pt: 18 }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
                handleOpen={handleOpen}
            />

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />

        </>
    );
    return (
        <CustomMaster
            defaultView={defaultView}
            mainContent={mainContent} />
    );
};
export default TermConditions;


import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import DescriptionIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

const ContentWrapper = styled('section')(({ theme }) => ({
    padding: theme.spacing(0),
}));

const CustomCard = styled(Card)(({ theme }) => ({
    minHeight: '325px',
    maxHeight: '400px',
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: theme.shadows[1],
    },
}));

const CardBody = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
}));

const CustomLink = styled(RouterLink)(({ theme }) => ({
    display: 'flex',
    fontWeight: 600,
    fontSize: "12px",
    alignItems: 'center',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    marginTop: theme.spacing(1),
    transition: 'color 0.2s',
    '&:hover': {
        color: '#0652DD',
        textDecoration: 'underline',
    },
}));

const cards = [
    {
        title: 'GENERAL',
        links: [
            { to: '/app/personalsettings', icon: <PersonOutlineOutlinedIcon />, text: 'Personal Settings' },
            { to: '/app/mycompany', icon: <BusinessOutlinedIcon />, text: 'Company Settings' },
        ],
    },
    {
        title: 'USERS & CONTROL',
        links: [
            { to: '/app/companyusers', icon: <SupervisedUserCircleOutlinedIcon />, text: 'Company Users' },
            { to: '/app/securitycontrols', icon: <SecurityOutlinedIcon />, text: 'Security Controls' },
            { to: '/app/adminusers', icon: <PersonOutlineOutlinedIcon />, text: 'Admin Users' },
            { to: '/app/usermanagement', icon: <SecurityOutlinedIcon />, text: 'User Management' },
        ],
    },
    {
        title: 'Master',
        links: [
            { to: '/app/expenseledgergroup', icon: <DescriptionIcon />, text: 'Expense Ledger Group' },
            // { to: '/app/currencymaster', icon: <DescriptionIcon />, text: 'Currency Master' },
            { to: '/app/shippinglinemaster', icon: <DescriptionIcon />, text: 'Shipping Line Master' },
            { to: '/app/freightforwardmaster', icon: <DescriptionIcon />, text: 'Freight Forward Master' },
            { to: '/app/customclearingagency', icon: <DescriptionIcon />, text: 'Custom Clearing Agency' },
            // { to: '/app/financialyear', icon: <DescriptionIcon />, text: 'Financial Year' },
            // { to: '/app/prefixsuffix', icon: <DescriptionIcon />, text: 'Prefix Suffix' },
        ],
    },
    {
        title: 'Products',
        links: [
            { to: '/app/manageproducts', icon: <DescriptionIcon />, text: 'Manage Products' },
            { to: '/app/unitmaster', icon: <DescriptionIcon />, text: 'Unit Master' },
        ],
    },
    {
        title: 'Business Master',
        links: [
            { to: '/app/businessgroup', icon: <DescriptionIcon />, text: 'Business Group' },
            // { to: '/app/companygroup', icon: <StoreIcon />, text: 'Company Group' },
            { to: '/app/contactgroup', icon: <DescriptionIcon />, text: 'Contact Group' },
            // { to: '/app/companytype', icon: <DescriptionIcon />, text: 'Company Type' },
            { to: '/app/lossreasons', icon: <DescriptionIcon />, text: 'Loss Reasons' },
            { to: '/app/priorities', icon: <DescriptionIcon />, text: 'Priorities' },
            // { to: '/app/leadstages', icon: <DescriptionIcon />, text: 'Lead Stages' },
            // { to: '/app/fcl', icon: <DescriptionIcon />, text: 'FCL' },
            // { to: '/app/direct', icon: <DescriptionIcon />, text: 'Direct' },
            // { to: '/app/paymenttype', icon: <DescriptionIcon />, text: 'Payment Type' },
        ],
    },
    {
        title: 'Business Type',
        links: [
            { to: '/app/taskstatuses', icon: <DescriptionIcon />, text: 'Task Statuses' },
            { to: '/app/modules', icon: <DescriptionIcon />, text: 'Modules' },
            { to: '/app/contacttypes', icon: <DescriptionIcon />, text: 'Contact Types' },
            { to: '/app/ratings', icon: <DescriptionIcon />, text: 'Ratings' },
            { to: '/app/statusesleads', icon: <DescriptionIcon />, text: 'Lead Statuses' },
            { to: '/app/sourceslead', icon: <DescriptionIcon />, text: 'Lead Sources' },
            // { to: '/app/calloutcometypes', icon: <DescriptionIcon />, text: 'Call Outcome Types' },
            // { to: '/app/callpurposetypes', icon: <DescriptionIcon />, text: 'Call Purpose Types' },
            // { to: '/app/ownershiptypes', icon: <DescriptionIcon />, text: 'Ownership Type' },
            // { to: '/app/supplierslist', icon: <DescriptionIcon />, text: 'Suppliers List' },
            // { to: '/app/currencylist', icon: <DescriptionIcon />, text: 'Currency List' },
            // { to: '/app/shipmenttypes', icon: <DescriptionIcon />, text: 'Shipment Types' },
            // { to: '/app/ciffoblist', icon: <DescriptionIcon />, text: 'CIF/FOB List' },
        ],
    },
    {
        title: 'MODULES',
        links: [
            { to: '/app/companymodules', icon: <DescriptionIcon />, text: 'Module Settings' },
            { to: '/app/systemCompany', icon: <DescriptionIcon />, text: 'System Setting' },
            { to: '/app/termConditions', icon: <DescriptionIcon />, text: 'Terms & Conditions ' },
            { to: '/app/template', icon: <DescriptionIcon />, text: 'Template' },
        ],
    },
    {
        title: 'Subscription Plans',
        links: [
            { to: '/app/subscription', icon: <DescriptionIcon />, text: 'Subscription Plans' },
            { to: '/app/subscriptionHistory', icon: <DescriptionIcon />, text: 'Subscription History' },
            // { to: '/app/setUp', icon: <ViewModuleIcon />, text: 'SetUp' },
        ],
    },
];


const ResponsiveCards = () => {
    return (
        <div>

            <ContentWrapper style={{ marginTop: '0px' }}>
                <CardContent>
                    <Heading variant="h4" style={{ paddingBottom: '10px' }}>
                        Setup
                    </Heading>
                    <Grid container spacing={2}>
                        {cards.map((card, index) => (
                            <Grid item key={index} xs={12} sm={6} md={3}>
                                <CustomCard variant="outlined">
                                    <CardBody>
                                        <Heading variant="h5">{card.title}</Heading>
                                        {card.links.map((link, linkIndex) => (
                                            <Typography key={linkIndex} variant="body2">
                                                <CustomLink to={link.to}>
                                                    <span style={{ fontSize: "15px", marginTop: "2px" }}> {link.icon}  </span> &nbsp; {link.text}
                                                </CustomLink>
                                            </Typography>
                                        ))}
                                    </CardBody>
                                    <br />
                                </CustomCard>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
                <br />
            </ContentWrapper>
        </div>
    );
};

export default ResponsiveCards;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './layouts/Headers';
import Layout from './layouts/Index';
import Home from './Pages/Home';
import { authProtectedRoutes, publicRoutes, openRoutes } from "./routes";
// import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from './routes/route'
import './App.css'
import ScrollToTop from './layouts/Scrolltotop';


const hasViewPermission = (path, rolePermissions) => {
  
  if(!rolePermissions){
    return true
  }
  const permission = rolePermissions.find(
    (perm) => perm.companyModule.moduleUrl === path
  );
  
  if(!permission){
    return 1
  }
  return permission?.views === 1;
};



const App = () => {
  let rolePermisionsData =  JSON.parse(sessionStorage.getItem('userData')) || []
 
  return (
    <React.Fragment>
      <ScrollToTop />
      <Routes>

        {openRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              route.component
            }
            key={idx}
            exact={true}
          />
        ))}

        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              // route.component
              <Home>{route.component}</Home>
            }
            key={idx}
            exact={true}
          />
        ))}

{authProtectedRoutes.map((route, idx) => {
 const hasPermission = hasViewPermission(route.path, rolePermisionsData?.permissions);

        return (
          <Route
            path={route.path}
            key={idx}
            element={
              hasPermission ? (
                <Authmiddleware>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              ) : (
                <Authmiddleware>
                   <Layout><AccessDenied /></Layout>
                   </Authmiddleware>
              )
            }
            exact={true}
          />
        );
      })}
      </Routes>
    </React.Fragment>
  );
}

export default App;

const AccessDenied = () => {
  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <h1>Access Denied</h1>
      <p>You do not have permission to view this page.</p>
    </div>
  );
};
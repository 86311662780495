import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { ExportMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from '../../../components/common/CustomModal';
import CreateExpProformInv from '../CreateForm/CreateExpProformInv';
import UpdateExpProformInv from '../UpdateForm/UpdateExpProformInv';
import MessageBox from '../../common/CustomMessageBox';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import { LIST_PROFORMA_INVOICES_EXPORT, GET_PRODUCTS_LIST, GET_SUPPLIERS_LIST, LIST_SALES_CONTRACTS_EXPORT, CREATE_SALES_CONTRACT_EXPORT, CREATE_COMMERCIAL_INVOICE_EXPORT, LIST_COMMERCIAL_INVOICES_EXPORT } from '../../../services/apiRoutes';
import { genericGet } from '../../../services/apiServices';
import { genericPost } from '../../../services/authServices';
import axios from 'axios';
import ProformaInvoiceView from '../ViewDetails/ProformaInvoiceView';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';
import { getCurrencyList } from '../../../services/CommonServices';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import { Tooltip } from '@mui/material';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

const ProformaInvoice = () => {
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Proforma Invoice');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState({ open: false, text: '', type: '' });
    const [proformaInvExpId, setProformaInvExpId] = useState('');
    const [customerList, setCustomerList] = useState([]);
    const [products, setProducts] = useState([]);
    const [viewModal, setViewModal] = useState(false);
    const [newShippingId, setNewShippingId] = useState(null);
    const [newSalesShippingId, setNewSalesShippingId] = useState(null);
    const [proformaInvoiceList, setProformaInvoiceList] = useState([]);
    const [newComercialShippingId, setNewComercialShippingId] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const navigate = useNavigate();
    const { exportShipmentId } = useParams();
    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);
    let customerId, cifFob;
    const hasFetched = useRef(false);
    useEffect(() => {
        if (shipmentDetails) {
            customerId = shipmentDetails.customerId;
            cifFob = shipmentDetails.cifFob.cifFobName;
            getCommonData();
            fetchData();
            fetchProductsList();
            getCustomersList();
            fetchSalesContract();
            fetchComercial();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateModal, proformaInvExpId, token, modal]);
    const fetchProductsList = async () => {
        const authToken = sessionStorage.getItem('accessToken');
        const payload = {
            clientCompanyId: customerId,
            shipmentId: shipmentId,
        };
        try {
            const response = await axios.post(GET_PRODUCTS_LIST, payload, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200 && response.data.success) {
                const productData = response.data.productsList;
                console.log(productData);
                setProducts(productData);
            } else {
                console.error('Failed to fetch products list:', response.data);
            }
        } catch (error) {
            console.error('Error fetching products list:', error);
        }
    };

    const getCustomersList = async () => {
        try {
            const payload = {
                clientCompanyId: customerId,
            };
            const response = await genericPost(GET_SUPPLIERS_LIST, payload, {
            });
            if (response.data && response.data.success && response.data.clientCompanies) {
                const customersData = response.data.clientCompanies.map((customer) => ({
                    id: customer.clientCompanyId,
                    title: customer.clientCompanyName,
                }));
                setCustomerList(customersData);
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching customer list:', error);
            return [];
        }
    };
    const getCommonData = async () => {
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
    }
    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                customerNamesId: customerId,
                shipmentId: shipmentId
            };
            const response = await genericPost(LIST_PROFORMA_INVOICES_EXPORT, payload);
            if (response.data.success) {
                const proformaInvoices = response.data.data.map((invoice, index) => {
                    const particulars = invoice.particulars.map((particular) => ({
                        qty: particular.qty || 0,
                        rate: parseFloat(particular.rate) || 0,
                        amount: (particular.qty || 0) * (parseFloat(particular.rate) || 0),
                    }));
                    const totalAmount = particulars.reduce((total, { amount }) => total + amount, 0);
                    return {
                        proformaInvExpId: invoice.proformaInvExpId || '',
                        index: (index + 1).toString().padStart(2, '0'),
                        userId: invoice.userId || '',
                        companyId: invoice.companyId || '',
                        customerName: invoice.customer.clientCompanyName || '',
                        country: invoice.customer.mailingCountry.name || '',
                        proformaInvoiceNo: invoice.proformaInvoiceNo || '',
                        createdAt: invoice.createdAt || '',
                        updatedAt: invoice.updatedAt || '',
                        // particulars,
                        shipmentIdName: invoice.shipmentId || '',
                        totalAmount: `${invoice.currency?.currency_symbol || ''} ${totalAmount ? totalAmount.toFixed(2) : ""}`,

                        customerNamesId: invoice.customerNamesId || '',
                        currencyId: invoice.currencyId || '',
                        reference: invoice.reference || '',
                        shipmentId: invoice.shipmentId || '',
                        date: invoice.date || '',
                        dueDate: invoice.dueDate || '',
                        note: invoice.note || '',
                        particulars: invoice.particulars.map(particular => ({
                            particularName: parseInt(particular.particularName,),
                            qty: particular.qty,
                            rate: parseFloat(particular.rate).toFixed(2),
                            amount: parseFloat(particular.amount).toFixed(2),
                            unit: parseInt(particular.unit,),
                            description: particular.description,
                        }))
                    };
                });
                const { newShippingId } = response.data;
                setNewShippingId(newShippingId);
                setData(proformaInvoices);
            } else {
                setError('No proforma invoices found');
            }
        } catch (error) {
            setError('Error fetching proforma invoices');
            console.error('Error fetching proforma invoices', error);
        } finally {
            setLoading(false);
        }
    };
    // Table columns
    const columns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        {
            id: 'proformaInvoiceNo', label: 'Porform No.', accessor: 'proformaInvoiceNo', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'date', label: 'Date', accessor: 'date', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'shipmentIdName', label: 'Shipping Id', accessor: 'shipmentIdName', styleAccessor: {
                textAlign: 'center',
            },
        },
        { id: 'customerName', label: 'Customer Name', accessor: 'customerName' },
        {
            id: 'totalAmount', label: 'Amount', accessor: 'totalAmount', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <>
                        <Tooltip
                            title='PI convert Into SC'
                        >
                            <IconButton onClick={() => handleSubmitSales(row.proformaInvExpId)}
                                style={{
                                    color: '#123524',
                                }}>
                                <SyncAltOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="PI convert Into CI">
                            <IconButton style={{ marginRight: '1px' }}
                                onClick={() => handleSubmitCommercial(row.proformaInvExpId)}
                            >
                                <CachedOutlinedIcon style={{ color: '#001A6E' }} />
                            </IconButton>
                        </Tooltip>
                        <IconButton onClick={() => handleViewClick(row.proformaInvExpId)} style={{ marginRight: '1px' }}>
                            <VisibilityIcon style={{ color: '#059212' }} />
                        </IconButton>
                        <IconButton onClick={() => handleUpdateClick(row.proformaInvExpId)} style={{ marginRight: '1px' }}>
                            <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteAlert(row.sn)}>
                            <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                        </IconButton>
                    </>
                </div >
            ),
        },
    ];

    const fetchSalesContract = async () => {
        setLoading(true);
        try {
            const payload = {
                customerNamesId: customerId,
                shipmentId: shipmentId
            };
            const response = await genericPost(LIST_SALES_CONTRACTS_EXPORT, payload);
            if (response.data.success) {
                const salesContracts = response.data.data.map((contract, index) => {
                    return {
                        proformaInvExpId: contract.proformaInvExpId || '',
                    };
                });
                const { newShippingId } = response.data;
                setNewSalesShippingId(newShippingId);
                setProformaInvoiceList(salesContracts);
            } else {
                setError('No sales contracts found');
            }
        } catch (error) {
            setError('Error fetching sales contracts');
            console.error('Error fetching sales contracts', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchComercial = async () => {
        setLoading(true);
        try {
            const payload = {
                customerNamesId: customerId,
                shipmentId: shipmentId
            };
            const response = await genericPost(LIST_COMMERCIAL_INVOICES_EXPORT, payload);
            if (response.data.success) {
                const { newShippingId } = response.data;
                setNewComercialShippingId(newShippingId);
            } else {
                setError('No commercial found');
            }
        } catch (error) {
            setError('Error fetching commercial');
            console.error('Error fetching commercial', error);
        } finally {
            setLoading(false);
        }
    };


    const handleSubmitSales = async (proformaInvExpId) => {
        try {
            const selectedInvoice = data.find((item) => item.proformaInvExpId === proformaInvExpId);
            if (!selectedInvoice) {
                alert('Proforma Invoice not found');
                return;
            }
            const payload = {
                proformaInvExpId: selectedInvoice.proformaInvExpId,
                customerNamesId: selectedInvoice.customerNamesId,
                reference: selectedInvoice.reference,
                currencyId: selectedInvoice.currencyId,
                salesContractNo: newSalesShippingId,
                shipmentId: selectedInvoice.shipmentId,
                date: selectedInvoice.date,
                dueDate: selectedInvoice.dueDate,
                note: selectedInvoice.note,
                particulars: selectedInvoice.particulars.map((particular) => ({
                    particularName: particular.particularName,
                    qty: particular.qty,
                    rate: particular.rate,
                    unit: particular.unit,
                    amount: particular.amount,
                    description: particular.description,
                })),
            };

            const response = await genericPost(CREATE_SALES_CONTRACT_EXPORT, payload);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: 'Sales Contract created successfully',
                    type: 'success',
                });
                fetchData();
            }
            else if (response.status === 409) {
                setMessage({
                    open: true,
                    text: response.message || 'already exists ',
                    type: 'error',
                });
            }
            else {
                setMessage({
                    open: true,
                    text: 'Error creating Sales Contract',
                    type: 'error'
                });
            }
        } catch (error) {
            console.error('Error converting Proforma Invoice:', error);
            setMessage({ open: true, text: 'Error occurred during the process', type: 'error' });
        }
    };
    const handleSubmitCommercial = async (proformaInvExpId) => {
        try {
            const selectedInvoice = data.find((item) => item.proformaInvExpId === proformaInvExpId);
            if (!selectedInvoice) {
                alert('Proforma Invoice not found');
                return;
            }
            const payload = {
                proformaInvExpId: selectedInvoice.proformaInvExpId,
                customerNamesId: selectedInvoice.customerNamesId,
                reference: selectedInvoice.reference,
                currencyId: selectedInvoice.currencyId,
                commercialInvoiceNo: newComercialShippingId,
                shipmentId: selectedInvoice.shipmentId,
                date: selectedInvoice.date,
                dueDate: selectedInvoice.dueDate,
                note: selectedInvoice.note,
                particulars: selectedInvoice.particulars.map((particular) => ({
                    particularName: particular.particularName,
                    qty: particular.qty,
                    rate: particular.rate,
                    unit: particular.unit,
                    amount: particular.amount,
                    description: particular.description,
                })),
            };
            const response = await genericPost(CREATE_COMMERCIAL_INVOICE_EXPORT, payload);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: 'commercial Invoice created successfully',
                    type: 'success',
                });
                fetchData();
            }
            else if (response.status === 409) {
                setMessage({
                    open: true,
                    text: response.message || 'already exists ',
                    type: 'error',
                });
            }
            else {
                setMessage({ open: true, text: 'Error creating commercial Invoice', type: 'error' });
            }
        } catch (error) {
            console.error('Error converting Proforma Invoice:', error);
            setMessage({ open: true, text: 'Error occurred during the process', type: 'error' });
        }
    };

    const handleUpdateClick = (id) => {
        setProformaInvExpId(id);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setProformaInvExpId('');
    };


    const handleViewClick = (id) => {
        setProformaInvExpId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setProformaInvExpId('');
    };

    const handleBackClick = () => {
        navigate('/app/export/shipement');
    };

    if (!shipmentDetails) {
        return <p>    <CustomLoader open={shipmentLoading} /> </p>;

    }
    const customerName = shipmentDetails.customerId;
    const shipmentId = shipmentDetails.shipmentId;
    const currencyId = shipmentDetails?.currency?.id ?? '';

    const companyName = shipmentDetails.customer.clientCompanyName;
    const phoneNumber = shipmentDetails.customer.mobile;
    const currencySymbol = shipmentDetails?.currency?.currency_symbol ?? '';
    const currencyShort = shipmentDetails?.currency?.currency ?? '';

    // JSX Structure
    return (
        <div>
            {/* <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={ExportMenu(exportShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />

            <Main open={open}> */}
            <CustomLoader open={loading} />

            <DetailsPageCard
                columns={columns}
                title="Proforma Invoice"
                buttonTitle="Create Proforma Invoice"
                handleOpen={handleOpen}
                data={data}
                loading={loading}
                error={error}
            />
            <CustomModal open={modal} handleClose={handleClose}>
                <CreateExpProformInv
                    fetchData={fetchData}
                    customerList={customerList}
                    productsList={products}
                    customerName={customerName}
                    setMessage={setMessage}
                    newShippingId={newShippingId}
                    shipmentId={shipmentId}
                    currencyList={currencyList}
                    currencyId={currencyId}
                    currencySymbol={currencySymbol}
                    handleClose={handleClose} />
            </CustomModal>

            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateExpProformInv
                    fetchData={fetchData}
                    customerList={customerList}
                    productsList={products}
                    setMessage={setMessage}
                    handleClose={handleCloseUpdate}
                    proformaInvExpId={proformaInvExpId}
                    currencyList={currencyList}
                />
            </CustomModal>
            <CustomModal open={viewModal} handleClose={handleCloseView}>
                <ProformaInvoiceView
                    fetchData={fetchData}
                    customerList={customerList}
                    productsList={products}
                    setMessage={setMessage}
                    handleClose={handleCloseView}
                    proformaInvExpId={proformaInvExpId}
                />
            </CustomModal>

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
        </div>
    );
};

export default ProformaInvoice;

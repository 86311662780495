import React, { useContext, useEffect, useState } from "react";
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { AppBarCompany, } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from "../../../components/common/CustomModal";
import CreateContacts from '../../contacts/createcontacts';
import { getCompanies, getLeadSource, getCountries, getBusinessGroup, getContactDepartments, getOwner, getTitles } from "../../../services/CommonServices";
import { Delete_Contact_contactId, GET_contact } from "../../../services/apiRoutes";
import { genericGet } from "../../../services/apiServices";
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import UpdateContacts from "../../contacts/UpdateContacts";
import MessageBox from "../../common/CustomMessageBox";
import CustomDelete from '../../../components/common/CustomDeleteAlert'
import axios from "axios";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate, useParams } from "react-router-dom";
import { useClientCompanyDetails } from "./UseClientCompanyDetails";
import { genericPost } from "../../../services/authServices";
import ContactsView from "../../contacts/ContactsView";
import { AuthContext } from "../../../context/AuthContext";
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import CustomLoader from "../../common/CustomLoader";

export default function ContactsMenu() {
    const { token } = useContext(AuthContext)

    const navigate = useNavigate();  // Initialize useNavigate
    const { clientCompanyId } = useParams();
    const { companyDetail, loading: loadingClient } = useClientCompanyDetails(clientCompanyId);
    const [open, setOpen] = useState(true);
    const [leadModal, setLeadModal] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [activeTab, setActiveTab] = useState('Contacts');
    const [modal, setModal] = useState(false);
    const [leadSource, setLeadSource] = useState([]);
    const [countries, setCountries] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [businessGroup, setBusinessGroup] = useState([]);
    const [contactDepartments, setContactDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updateModalOpen, setUpdateModalOpen] = useState(false); // Correct name
    const [rowId, setRowId] = useState('')
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [titles, setTitles] = useState([]);
    const [conutries, setConutries] = useState([]);
    const [owner, setOwner] = useState([]);
    const [selectedId, setSelectedId] = useState(null); // State to hold selected ID
    const [viewModal, setViewModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const fetchContacts = async () => {
        setLoading(true);
        try {
            const payload = {
                selectCompanyNameId: clientCompanyId,
            };
            const response = await genericPost(GET_contact, payload, {});

            if (response.data.success && response.data.contacts) {
                const contactData = response.data.contacts.map((contact, index) => ({
                    contactId: contact.contactId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    mobile: contact.mobile || '',
                    email: contact.email || '',
                    company: contact.clientCompany?.clientCompanyName || 'N/A',
                    millingCountry: contact.millingCountry?.name || 'N/A',
                    contactName: contact?.firstName ? `${contact.firstName} ${contact.lastName}` : 'N/A',
                    title: contact.title || '',
                    description: contact.description || '',
                    createdAt: contact.createdAt || '',
                    updatedAt: contact.updatedAt || '',
                    contactOwner: contact.contactOwner || 'N/A',
                    leadSource: contact.leadSource?.leadSourceName || 'N/A',
                    contactGroup: contact.contactGroup?.contactGroupName || 'N/A',
                    contactType: contact.contactType?.contactTypeName || 'N/A',
                    clientCompany: contact.clientCompany || 'N/A',
                    department: contact.department || 'N/A',
                }));
                setContacts(contactData);
            } else {
                setError('No contacts found');
            }
        } catch (error) {
            setError('Error fetching contacts');
            console.error('Error fetching contacts', error);
        } finally {
            setLoading(false);
        }
    };
    const getCommonData = async () => {
        const leadSourceData = await getLeadSource();
        setLeadSource(leadSourceData);
        const countriesData = await getCountries();
        setCountries(countriesData);
        const companiesData = await getCompanies();
        setCompanies(companiesData);
        const businessGroupData = await getBusinessGroup();
        setBusinessGroup(businessGroupData);
        const contactDepartmentsData = await getContactDepartments();
        setContactDepartments(contactDepartmentsData);
        const owner = await getOwner();
        setOwner(owner);
        const getConutriesData = await getCountries()
        setConutries(getConutriesData);
        const titlesData = await getTitles()
        setTitles(titlesData)
    };


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };


    const handleDelete = async (contactId) => {
        setLoading(true)
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(Delete_Contact_contactId(contactId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                fetchContacts()
                setDeleteAlert(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error deleting contactId", error);
        }
    };


    const handleUpdateClick = (contactId) => {
        setSelectedId(contactId);
        setUpdateModal(true);
    };

    const handleCloseUpdateModal = () => {
        setUpdateModal(false);
    };
    const handleOpenView = (contactId) => {
        setSelectedId(contactId);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setSelectedId('');
    };

    const handleViewClick = (contactId) => {
        handleOpenView(contactId);
        setSelectedId(contactId);

    };

    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true);
        setRowId(rowId);
    };


    const CompanyContactColumn = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        { accessor: "contactName", label: "Contact Name", id: "contactName" },
        {
            accessor: "mobile", label: "Contact No.", id: 'mobile', styleAccessor: {
                textAlign: 'center',
            },
        },
        { accessor: "email", label: "Email ID", id: "email" },
        { accessor: "company", label: "Company", id: 'company' },
        { accessor: "millingCountry", label: "Country", id: "millingCountry" },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.contactId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: "#059212" }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.contactId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.contactId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                    </IconButton>
                </div>
            ),
        },
    ];



    useEffect(() => {
        fetchContacts();
        getCommonData();
    }, [token]);


    if (!companyDetail) {
        return <Box> <CustomLoader open={loadingClient} />
        </Box>;
    }

    const clientCompany = [
        {
            id: Number(clientCompanyId),
            title: companyDetail?.clientCompanyName || 'N/A',
        },
    ];
    const companyName = companyDetail.clientCompanyName;
    const phoneNumber = companyDetail.mobile;
    const initial = companyDetail.clientCompanyName ? companyDetail.clientCompanyName.charAt(0).toUpperCase() : '';
    const handleBackClick = () => {
        navigate("/app/company");
    };

    return (
        <div>
            {/* <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={AppBarCompany(clientCompanyId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick} />
            <Main open={open}> */}

            <DetailsPageCard
                Icon={ContactPhoneOutlinedIcon}
                columns={CompanyContactColumn}
                title={'Contacts'}
                buttonTitle={'Create Contacts'}
                handleOpen={handleOpen}
                data={contacts}
            />
            <CustomModal open={modal} handleClose={handleClose}>
                <CreateContacts
                    handleClose={handleClose}
                    leadSource={leadSource}
                    owner={owner}
                    titles={titles}
                    conutries={conutries}
                    contacts={contacts}
                    clientCompany={clientCompany}
                    fetchContacts={fetchContacts}
                    setMessage={setMessage}
                    companies={companies}
                    businessGroups={businessGroup}
                    contactDepartments={contactDepartments}
                />
            </CustomModal>
            <CustomModal
                open={updateModal}
                handleClose={handleCloseUpdateModal}>
                <UpdateContacts
                    handleClose={handleCloseUpdateModal}
                    contacts={contacts.find((v) => v.contactId === selectedId)}
                    contactId={selectedId}
                    fetchContacts={fetchContacts}
                    setMessage={setMessage}
                    leadSource={leadSource}
                    reportTo={contacts}
                    titles={titles}
                    companies={companies}
                    owner={owner}
                    conutries={conutries}
                    clientCompany={clientCompany}
                    businessGroups={businessGroup}
                    contactDepartments={contactDepartments}
                />
            </CustomModal>
            <CustomModal
                open={viewModal}
                modalStyles={{
                    maxHeight: '45vh',
                    top: "10%",
                }}
                handleClose={handleCloseView}>
                <ContactsView
                    handleClose={handleCloseView}
                    contacts={contacts.find((v) => v.contactId === selectedId)}
                    contactId={selectedId}
                    fetchContacts={fetchContacts}
                    setMessage={setMessage}
                    leadSource={leadSource}
                    companies={companies}
                    owner={owner}
                    conutries={conutries}
                    clientCompany={clientCompany}
                    businessGroups={businessGroup}
                    contactDepartments={contactDepartments}
                />
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}
            />
            {/* </Main> */}
        </div>
    );
}

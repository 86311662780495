import React, { useMemo, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import { genericGet } from "../../services/apiServices";
import { GET_SUPPLIER_LEDGER } from "../../services/apiRoutes";
import { AuthContext } from "../../context/AuthContext";
import CustomLoader from "../common/CustomLoader";
import CustomReport from "../common/CustomReport";
const title = "Supplier Ledger"
const SupplierLedger = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { token } = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = useMemo(
        () => [
            { accessorKey: "index", header: "SN.", size: 50, tableHeader: { alignItems: 'center', }, tableStyle: { textAlign: "center" }, enableColumnFilter: false },
            {
                accessorKey: 'clientCompanyName', header: 'Supplier Name', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'country', header: 'Country', size: 100, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            {
                accessorKey: 'Contacts', header: 'Contact Person', size: 150, tableHeader: {
                    justifyContent: 'flex-start',
                },
            },
            { accessorKey: 'Billing', header: 'Billing Amount', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'Receipt', header: 'Receipt Amount', size: 50, tableStyle: { textAlign: "center" }, },
            { accessorKey: 'Balance', header: 'Balance Amount', size: 50, tableStyle: { textAlign: "center" }, },

            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                        <IconButton onClick={() => handleViewClick(row.original.clientCompanyId)}>
                            <VisibilityIcon />
                        </IconButton>
                    </div>
                ),
            },
        ],
        []
    );
    const handleViewClick = (clientCompanyId) => {
        navigate(`/app/reports/supplierledger/${clientCompanyId}`);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: GET_SUPPLIER_LEDGER });
            if (response.data.success && response.data.clientCompanies) {
                const clientCompanyData = response.data.clientCompanies.map((company, index) => ({
                    clientCompanyId: company.clientCompanyId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    clientCompanyName: company.clientCompanyName || '',
                    phone: company.phone || '',
                    mobile: company.mobile || '',
                    email: company.email || '',
                    address: company.address || '',
                    country: company.mailingCountry?.name || '',
                    // Contacts: company.companyAdmin?.firstName || '',
                    Contacts: `${company.companyAdmin?.firstName || ''} ${company.companyAdmin?.lastName || ''}`,

                }));
                setData(clientCompanyData);
            } else {
                setError('No client companies found');
            }
        } catch (error) {
            setError('Error fetching client companies');
            console.error('Error fetching client companies', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData()
    }, [token])

    const defaultView = 'supplierledger';
    const mainContent = (
        <>
            <CustomLoader open={loading} />
            <DataTable
                title={title}
                columns={columns}
                data={data}
            />
        </>
    );

    return (
        <>
            <CustomReport
                defaultView={defaultView}
                mainContent={mainContent} />

        </>
    );
};
export default SupplierLedger;

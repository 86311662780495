import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Divider, CircularProgress } from "@mui/material";
import { Card } from "@mui/material";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import axios from "axios";
import CustomButton from "../../common/CustomButton";
import { GET_SUPPLIERS_LIST, Get_task_taskId, Update_task } from '../../../services/apiRoutes';
import { genericPost } from '../../../services/authServices';
import { getContactPersonList, getOwner } from '../../../services/CommonServices';

// Define the validation schema
const ValidationSchema = Yup.object().shape({
    taskOwnerId: Yup.number().required('Task Owner ID is required'),
    assignToId: Yup.number().required('Assign To ID is required'),
    contactId: Yup.number().required('Contact ID is required'),
    clientCompanyId: Yup.number().required('Client Company ID is required'),
    taskName: Yup.string().required('Task Name is required'),
    subject: Yup.string().required('Subject is required'),
    dueDate: Yup.date().required('Due Date is required'),
    taskStatusId: Yup.number().required('Task Status ID is required'),
    priorityId: Yup.number().required('Priority ID is required'),
    description: Yup.string().required('Description is required'),
});

// Define a taskId if needed or pass it as a prop

const UpdateImportTask = ({ handleClose, setMessage, taskId, fetchTaskData, taskStatuses, priorities, dataUpdate }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [suppliersList, setSuppliersList] = useState([]);
    const [leadOwner, setLeadOwner] = useState([]);
    const [contactPersonList, setContactPersonList] = useState([]);


    const getCommonData = async () => {
        const leadOwner = await getOwner()
        setLeadOwner(leadOwner);
        const contactPersonList = await getContactPersonList();
        setContactPersonList(contactPersonList);
    }


    const getSuppliersList = async () => {
        try {
            const payload = {
                clientCompanyId: data.supplierId,
            };
            const response = await genericPost(GET_SUPPLIERS_LIST, payload, {
            });
            if (response.data && response.data.success && response.data.clientCompanies) {
                const suppliersData = response.data.clientCompanies.map((supplier) => ({
                    id: supplier.clientCompanyId,
                    title: supplier.clientCompanyName,
                }));
                setSuppliersList(suppliersData);
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching suppliers list:', error);
            return [];
        }
    };

    useEffect(() => {
        getSuppliersList();
        getCommonData();
    }, []);
    useEffect(() => {
        if (!taskId) return;
        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(Get_task_taskId(taskId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.task);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [taskId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(Update_task(taskId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "call added successfully",
                    type: "success",
                });
                fetchTaskData()
                setTimeout(() => {
                    handleClose()
                }, 3000)
                handleClose()
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };



    // useEffect(() => {
    //     getSuppliersList();
    //     getCommonData();
    // }, []);


    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    taskOwnerId: data?.taskOwnerId || '',
                    assignToId: data?.assignToId || '',
                    contactId: data?.contactId || '',
                    clientCompanyId: data?.clientCompanyId || '',
                    taskName: data?.taskName || '',
                    subject: data?.subject || '',
                    dueDate: data?.dueDate || '',
                    taskStatusId: data?.taskStatusId || '',
                    priorityId: data?.priorityId || '',
                    description: data?.description || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4} md={2}>
                                <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                                    Update Task
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={10} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ px: "5%" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="taskOwnerId"
                                    required={true}
                                    fieldLabel="Task Owner"
                                    placeholder="Select"
                                    options={leadOwner}
                                    value={leadOwner.find((option) => option.id === values.taskOwnerId) || null}
                                    getOptionLabel={(option) => option.title}
                                    onChange={(e, newValue) => setFieldValue('taskOwnerId', newValue ? newValue.id : '')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={suppliersList}
                                    fieldLabel="Client Company"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={suppliersList.find((option) => option.id === values.clientCompanyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('clientCompanyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Client Company"
                                />
                                {touched.clientCompanyId && errors.clientCompanyId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.clientCompanyId}</p> : null}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="assignToId"
                                    fieldLabel="Assign To"
                                    required={true}
                                    placeholder="Select"
                                    options={leadOwner}
                                    value={leadOwner.find((option) => option.id === values.assignToId) || null}
                                    getOptionLabel={(option) => option.title}
                                    onChange={(e, newValue) => setFieldValue('assignToId', newValue ? newValue.id : '')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="contactId"
                                    fieldLabel="Select Contact Person"
                                    required={true}
                                    options={contactPersonList}
                                    placeholder="Select"
                                    value={contactPersonList.find((option) => option.id === values.contactId) || null}
                                    getOptionLabel={(option) => option.title}
                                    onChange={(e, newValue) => setFieldValue('contactId', newValue ? newValue.id : '')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="taskName"
                                    fieldLabel="Task"
                                    required={true}
                                    value={values.taskName}
                                    onChange={handleChange}
                                    error={touched.taskName && !!errors.taskName}
                                    helperText={touched.taskName && errors.taskName}
                                    placeholder="Enter Task"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <CustomTextField
                                    name="subject"
                                    fieldLabel="Subject"
                                    required
                                    value={values.subject}
                                    onChange={handleChange}
                                    error={touched.subject && !!errors.subject}
                                    helperText={touched.subject && errors.subject}
                                    placeholder="Enter Subject"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="dueDate"
                                    fieldLabel="Due Date"
                                    type="datetime-local"
                                    required
                                    value={values.dueDate}
                                    onChange={handleChange}
                                    error={touched.dueDate && !!errors.dueDate}
                                    helperText={touched.dueDate && errors.dueDate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={taskStatuses}
                                    fieldLabel="Status"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={taskStatuses.find((option) => option.id === values.taskStatusId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('taskStatusId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Status"
                                />
                                {touched.taskStatusId && errors.taskStatusId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.taskStatusId}</p> : null}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={priorities}
                                    fieldLabel="Priority"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={priorities.find((option) => option.id === values.priorityId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('priorityId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select"
                                />
                                {touched.priorityId && errors.priorityId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.priorityId}</p> : null}
                            </Grid>
                            <Grid item xs={8}>
                                <CustomTextarea
                                    sx={{ height: "100px !important" }}
                                    name="description"
                                    fieldLabel="Description"
                                    required
                                    value={values.description}
                                    onChange={handleChange}
                                    error={touched.description && !!errors.description}
                                    helperText={touched.description && errors.description}
                                    placeholder="Enter Description"
                                    rows={3}
                                />
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateImportTask;


import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const CustomPrintModel = ({ contentId, children, sx, }) => {
    return (
        <Box
            id={contentId}
            sx={{
                ...sx,
                display: "none",
            }}
        >
            {children}
        </Box>
    );
};

CustomPrintModel.propTypes = {
    children: PropTypes.node.isRequired,
    sx: PropTypes.object,
    bgColor: PropTypes.string,  // Added bgColor prop for custom background
};

export default CustomPrintModel;

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { ExportMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from '../../../components/common/CustomModal';
import CreatePackingList from '../CreateForm/CreatePackingList';
import UpdatePackingList from '../UpdateForm/UpdatePackingList';
import MessageBox from '../../common/CustomMessageBox';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import { LIST_COMMERCIAL_INVOICES_EXPORT, LIST_PACKING_LISTS_EXPORT } from '../../../services/apiRoutes';
import { genericGet } from '../../../services/apiServices';
import { genericPost } from '../../../services/authServices';
import PackingListView from '../ViewDetails/PackingListView';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

const PackingList = () => {
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Packing List');
    const [data, setData] = useState([]);
    const [invoiceList, setInvoiceList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState({ open: false, text: '', type: '' });
    const [packingListId, setPackingListId] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const navigate = useNavigate();
    const { exportShipmentId } = useParams();
    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);
    const [newShippingId, setNewShippingId] = useState(null);

    let customerId, cifFob;
    const hasFetched = useRef(false);
    useEffect(() => {
        if (shipmentDetails) {
            customerId = shipmentDetails.customerId;

            cifFob = shipmentDetails.cifFob.cifFobName;
            fetchData();
            fetchDataCommercialInvoiceList();
            hasFetched.current = true;
        }
    }, [shipmentDetails, updateModal, packingListId, token, modal]);

    const fetchDataCommercialInvoiceList = async () => {
        try {
            const payload = {
                customerNamesId: customerId,
                shipmentId: shipmentId,
            };
            const response = await genericPost(LIST_COMMERCIAL_INVOICES_EXPORT, payload, {
            });
            if (response.data && response.data.success && response.data.data) {
                const customersData = response.data.data.map((customer) => ({
                    id: customer.commercialInvoiceNo,
                    title: customer.commercialInvoiceNo,
                }));
                setInvoiceList(customersData);
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching customer list:', error);
            return [];
        }
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: customerId,
                shipmentId: shipmentId,
            };

            const response = await genericPost(LIST_PACKING_LISTS_EXPORT, payload);
            if (response.data.success) {
                const packingLists = response.data.data.map((item, index) => ({
                    packingListId: item.packingListId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    userId: item.userId || '',
                    companyId: item.companyId || '',
                    clientCompanyId: item.clientCompanyId || '',
                    addressTo: item.addressTo || '',
                    invNo: item.invNo || '',
                    date: item.date || '',
                    loadingPort: item.loadingPort || '',
                    destinationPort: item.destinationPort || '',
                    mark: item.mark || '',
                    description: item.description || '',
                    quantity: item.quantity || '',
                    pkg: item.pkg || '',
                    netWeight: item.nw || '',
                    tareWeight: item.tw || '',
                    measurement: item.measurement || '',
                    totalPackedIn: item.totalPackedIn || '',
                    totalGrossWeight: item.totalGrossWeight || '',
                    totalNetWeight: item.totalNetWeight || '',
                    casNo: item.casNo || '',
                    iupac: item.iupac || '',
                    createdAt: item.createdAt || '',
                    updatedAt: item.updatedAt || '',
                    customerName: item.customer?.clientCompanyName || '',
                    customerCountry: item.customer?.mailingCountry?.name || '',
                    shipmentId: item.shipmentId || '',
                }));
                const { newShippingId } = response.data;
                setNewShippingId(newShippingId);
                setData(packingLists);
            } else {
                setError('No packing list data found');
            }
        } catch (error) {
            setError('Error fetching packing list data');
            console.error('Error fetching packing list data', error);
        } finally {
            setLoading(false);
        }
    };


    // Table columns
    const columns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        {
            id: 'shipmentId', label: 'Shipping Id', accessor: 'shipmentId', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'packingListNo', label: 'Packing List No.', accessor: 'packingListNo', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'date', label: 'Date', accessor: 'date', styleAccessor: {
                textAlign: 'center',
            },
        },
        { id: 'customerName', label: 'Customer Name', accessor: 'customerName' },
        {
            id: 'totalNetWeight', label: 'Net Weight', accessor: 'totalNetWeight', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'totalGrossWeight', label: 'Gross Weight', accessor: 'totalGrossWeight', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'measurement', label: 'Measurement', accessor: 'measurement', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.packingListId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.packingListId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.packingListId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];


    const handleUpdateClick = (id) => {
        setPackingListId(id);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setPackingListId('');
    };

    const handleViewClick = (id) => {
        setPackingListId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setPackingListId('');
    };

    const handleBackClick = () => {
        navigate('/app/export/shipement');
    };

    if (!shipmentDetails) {
        return <p>  <CustomLoader open={shipmentLoading} /> </p>;
    }

    const addressToClient = `${shipmentDetails.customer.billingStreet || ''}, ${shipmentDetails.customer.billingCity.name || ''}, ${shipmentDetails.customer.billingState.name || ''}, ${shipmentDetails.customer.billingCountry.name || ''}, ${shipmentDetails.customer.billingPincode || ''}.`.trim();
    const companyName = shipmentDetails.customer.clientCompanyName;
    const addressTo = `${companyName || ''}, ${addressToClient || ''}`.trim();
    const phoneNumber = shipmentDetails.customer.mobile;
    const customerName = shipmentDetails.customerId;
    const shipmentId = shipmentDetails.shipmentId;
    const initial = shipmentDetails.customer.clientCompanyName ? shipmentDetails.customer.clientCompanyName.charAt(0).toUpperCase() : '';


    // JSX Structure
    return (
        <div>
            {/* <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={ExportMenu(exportShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />

            <Main open={open}> */}
            <CustomLoader open={loading} />
            <DetailsPageCard
                Icon={InventoryOutlinedIcon}
                columns={columns}
                title="Packing List"
                buttonTitle="Create Packing List"
                handleOpen={handleOpen}
                data={data}
                loading={loading}
                error={error}
            />
            <CustomModal open={modal} handleClose={handleClose}>
                <CreatePackingList
                    fetchData={fetchData}
                    customerName={customerName}
                    addressTo={addressTo}
                    phoneNumber={phoneNumber}
                    companyName={companyName}
                    shipmentId={shipmentId}
                    newShippingId={newShippingId}
                    invoiceList={invoiceList}
                    setMessage={setMessage}
                    handleClose={handleClose}
                />
            </CustomModal>

            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdatePackingList
                    fetchData={fetchData}
                    setMessage={setMessage}
                    phoneNumber={phoneNumber}
                    companyName={companyName}
                    handleClose={handleCloseUpdate}
                    packingListId={packingListId}
                    invoiceList={invoiceList}
                />
            </CustomModal>

            <CustomModal open={viewModal} handleClose={handleCloseView}>
                <PackingListView
                    fetchData={fetchData}
                    setMessage={setMessage}
                    phoneNumber={phoneNumber}
                    companyName={companyName}
                    handleClose={handleCloseView}
                    packingListId={packingListId}
                />
            </CustomModal>

            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            {/* </Main> */}
        </div>
    );
};

export default PackingList;

import React, { useEffect, useState } from "react";
import { Box, Grid, Table, TableHead, TableRow, TableCell, TableBody, Switch, Card, Typography, Divider, Button } from "@mui/material";
import CustomModal from '../common/CustomModal';
import RolesAdd from "./RolesAdd";
import { GET_ROLE } from "../../services/apiRoutes";
import { genericGet } from "../../services/apiServices";
import MessageBox from '../common/CustomMessageBox'
import CustomMaster from "../common/CustomMaster";

const options = ["Option 1 a", "Option 2 b"];
const leadOwnerOptions = [
  { id: 1, title: "John Doe" },
  { id: 2, title: "Jane Smith" },
  { id: 3, title: "Alex Johnson" },
  // Add more options as needed
];

const leadSourceOptions = [
  { id: 1, title: "Online" },
  { id: 2, title: "Referral" },
  { id: 3, title: "Cold Call" },
  // Add more options as needed
];

const contactNameOptions = [
  { id: 1, title: "Alice Brown" },
  { id: 2, title: "Bob Martin" },
  { id: 3, title: "Charlie Clark" },
  // Add more options as needed
];

const styles = {
  tableCell: {
    border: '1px solid #dee2e6',
    color: '#212529',
    fontWeight: 600,
    fontSize: '14px',
    textAlign: 'center',
    p: '4px',
  },
};

const Roles = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    profile_name: "",
    modules: [
      {
        module_name: "Report",
        permission_create: true,
        permission_update: false,
        permission_view: true,
        permission_delete: false
      },
      {
        module_name: "Master",
        permission_create: false,
        permission_update: true,
        permission_view: false,
        permission_delete: true
      }
    ]
  });
  const [loading, setLoading] = useState()
  const [companyRoles, setCompanyRoles] = useState([])
  const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  })
  const getRoles = async () => {
    setLoading(true);
    try {
      const response = await genericGet({ url: GET_ROLE });
      if (response.status == 200) {
        let reponseData = response?.data?.companyRoles.map((v) => ({
          id: v?.roleId,
          title: v?.roleName,
          name: v?.roleName,
          roleDescription: v?.roleDescription,
          parentRole: v?.parentRole?.roleName
        }))
        setCompanyRoles(reponseData)
      }
    } catch (error) {

    } finally {
    }
  };

  useEffect(() => {
    getRoles()
  }, [])


  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form data:", formData);
    // You can handle form submission logic here
  };
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const defaultView = 'roles';
  const mainContent = (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} style={{ position: "sticky" }}>
          <Grid item xs={4} md={6} sx={{ my: 1 }}>
            <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px", }}>
              Roles
            </Typography>

          </Grid>
          <Grid item xs={4} md={6} sx={{ my: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button size="small"
              variant="contained"
              onClick={() => setOpen(true)}
            >
              Add Roles
            </Button>
          </Grid>

        </Grid>
        <Divider />

        <Box sx={{ pt: 2 }}>
          <Box sx={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow sx={{ height: '40px', backgroundColor: '#e7f0f7' }}>
                  <TableCell rowSpan={2}>Role</TableCell>
                  <TableCell rowSpan={2}>Report To</TableCell>
                  <TableCell rowSpan={2}> Description</TableCell>
                  <TableCell rowSpan={2}>Created By</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companyRoles && companyRoles.map((v) =>
                  <TableRow key={v.roleId} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                    <TableCell>{v?.name}</TableCell>
                    <TableCell>{v?.parentRole}</TableCell>

                    <TableCell>{v?.roleDescription}</TableCell>

                  </TableRow>)}
              </TableBody>
            </Table>
          </Box>
        </Box>
        <Grid item xs={8} md={6} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", mt: 4 }}>
            <Button
              size="small"
              variant="contained"
            >
              Save
            </Button>

          </Box>
        </Grid>
      </form>
      <CustomModal
        open={open}
        handleClose={() => setOpen(false)}
        modalStyles={{
          maxHeight: '60vh',
          top: "10%",
        }}
      >
        <Box sx={{ p: 2 }}>
          <RolesAdd handleClose={() => setOpen(false)} companyRoles={companyRoles} setMessage={setMessage} getRoles={getRoles} />
        </Box>
      </CustomModal>
      <MessageBox
        open={message.open}
        onClose={() => setMessage({ ...message, open: false })}
        message={message.text}
        type={message.type}
      />
    </>
  );
  return (
    <>
      <CustomMaster
        defaultView={defaultView}
        mainContent={mainContent} />
    </>
  );
};

export default Roles;

import React, { useState,useEffect } from "react";
import { Box, Grid, Table, TableHead, TableRow, TableCell, TableBody, Switch, Card, Typography, Divider } from "@mui/material";
import CustomButton from "../../common/CustomButton";
import { ClearRounded, SaveRounded, Replay10Rounded } from "@mui/icons-material";
import { CREATE_PROFILE, GET_MODULE, POST_PERMISIONS } from "../../../services/apiRoutes";
import { genericGet } from "../../../services/apiServices";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import {genericPost} from '../../../services/authServices'
const options = ["Option 1 a", "Option 2 b"];
const leadOwnerOptions = [
  { id: 1, title: "John Doe" },
  { id: 2, title: "Jane Smith" },
  { id: 3, title: "Alex Johnson" },
  // Add more options as needed
];

const leadSourceOptions = [
  { id: 1, title: "Online" },
  { id: 2, title: "Referral" },
  { id: 3, title: "Cold Call" },
  // Add more options as needed
];

const contactNameOptions = [
  { id: 1, title: "Alice Brown" },
  { id: 2, title: "Bob Martin" },
  { id: 3, title: "Charlie Clark" },
  // Add more options as needed
];

const styles = {
  tableCell: {
    border: '1px solid #dee2e6',
    color: '#212529',
    fontWeight: 600,
    fontSize: '14px',
    textAlign: 'center',
    p: '4px',
  },
};

const CreateCompanyModules = ({ handleClose, setMessage, rating }) => {
  const [formData, setFormData] = useState({
    profileName: "",
    modules:[],
    profileDescription:''
  });
  const [loading,setLoading] =useState(false)
 



   const handleSubmit = async () => {
    let payload = {
      profileName:formData.profileName,
      profileDescription:formData?.profileDescription
    }
     try {
       const response = await genericPost(CREATE_PROFILE, payload);
       if (response.status == 201) {
        let profileId = response.data.profile?.profileId
        let Permissions = formData?.modules.map((v)=>({
          companyModuleId:v?.moduleId,
          profileId:profileId,
          creates:v?.create == true ? 1 :0,
          updates:v?.update == true ? 1 :0,
          views:v?.view == true ? 1:0,
          deletes:v?.delete == true ? 1:0,
          exports:v?.export == true ? 1 :0,
         }))
         const responseData = await genericPost(POST_PERMISIONS, Permissions);
         if (responseData.status == 201) {
         setMessage({
           open: true,
           text: "Profile added successfully",
           type: "success",
         });
         handleClose()
        }
       else {
        setMessage({
            open: true,
            text: 'Something went wrong',
            type: "error",
          });
       }
      }
     } catch (error) {
        setMessage({
            open: true,
            text: 'Something went wrong',
            type: "error",
          });
     } 
   };

  const handleChange = (event, moduleIndex = null) => {
    const { name, type, checked, value } = event.target;

    if (moduleIndex !== null) {
      const newModules = [...formData.modules];
      newModules[moduleIndex] = {
        ...newModules[moduleIndex],
        [name]: type === 'checkbox' ? checked : value
      };
      setFormData({ ...formData, modules: newModules });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const getModules = async () => {
    setLoading(true);
    try {
      const response = await genericGet({ url: GET_MODULE });
    if(response.status == 200 ) {
        let reponseData = response?.data?.companyModules.map((v)=>({
          moduleId: v?.moduleId,
            moduleName:v?.moduleName,
            name:v?.roleName,
            create:false,
            update:false,
            view:false,
            delete:false
        }))
        setFormData((prev)=>({
          ...prev,
          modules:reponseData
        }))
    }
     } catch (error) {
      
    } finally {
    }
  };

useEffect(()=>{
  getModules()
},[])

  return (
    <>
    
        <Grid container spacing={1} style={{ position: "sticky" }}>
          <Grid item xs={4} md={6}>
            <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
              Create Profile 
            </Typography>
          </Grid>
          

          <Grid item xs={8} md={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Box style={{ display: "flex" }}>
              <CustomButton
                startIcon={<SaveRounded />}
                title="Save"
                size="small"
                variant="contained"
                onClick={handleSubmit}
              />
              <CustomButton
                startIcon={<ClearRounded />}
                title="Cancel"
                size="small"
                variant="contained"
                onClick={handleClose}
              />

            </Box>
          </Grid>
        </Grid>
        <Divider sx={{my:2}}/>
        <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField
                                name="profileName"
                                type="text"
                                fieldLabel="Profile Name"
                                value={formData.profileName}
                                onChange={(e)=>setFormData((prev)=>({
                                  ...prev,
                                  profileName:e.target.value
                                }))}
                                placeholder="Enter Profile Name "
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTextarea 
                             fieldLabel="Profile Description"
                            name='profileDescription'
                            value={formData.profileDescription}
                            onChange={(e)=>setFormData((prev)=>({
                              ...prev,
                              profileDescription:e.target.value
                            }))}
                            />
                        </Grid>

              </Grid>
        <Box sx={{ pt: 2 }}>
          <Box sx={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow sx={{ height: '40px', backgroundColor: '#e7f0f7' }}>
                  <TableCell sx={{ ...styles.tableCell, width: '200px' }} rowSpan={2}>Module Name</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Create</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Update</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>View</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.modules.map((module, index) => (
                  <TableRow key={index} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                    <TableCell sx={styles.tableCell}>{module.moduleName}</TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="create"
                        checked={module.create}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="update"
                        checked={module.update}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="view"
                        checked={module.view}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="delete"
                        checked={module.delete}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>

    </>
  );
};

export default CreateCompanyModules;

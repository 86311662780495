import React,{useState,useEffect,} from "react";
import { Box, Grid, Divider, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomButton from "../../common/CustomButton";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import {genericPost} from '../../../services/authServices'
import {ADD_ORG_USERS} from '../../../services/apiRoutes'

const CreateAdmin = ({ handleClose,companyRoles,profile }) => {
    const validationSchema = Yup.object({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        email: Yup.string().email("Invalid email address").required("Email is required"),
        mobile: Yup.string().required('Mobile Number is required'),
        password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
        roleId: Yup.string().required("User Level is required"),
        profileId: Yup.string().required("Profile is required"),
    });
 
 const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  })


    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        mobile:"",
        password: "",
        roleId: null,
        profileId: null,
    };

    const handleSubmit = async (values) => {
        try {
             const response = await genericPost(ADD_ORG_USERS, values, {
             });
       
             if (response.status === 201) {
               setMessage({
                 open: true,
                 text: response?.data?.message || "Lead added successfully",
                 type: "success",
               });
            //    getLeadList()
               setTimeout(() => {
                 handleClose()
               }, 3000)
               handleClose()
             } else {
             }
           } catch (error) {
             alert('Something went wrong');
           } finally {
           }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                <Form>
                    <Grid container spacing={1} style={{ position: "sticky" }}>
                        <Grid item xs={6}>
                            <Typography
                                sx={{ fontWeight: 600 }}
                                style={{ color: "#212529", fontSize: "18px" }}
                            >
                                Create User
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            style={{ display: "flex", justifyContent: "flex-end", marginBottom: 5 }}
                        >
                            <Box style={{ display: "flex" }}>
                                <CustomButton
                                    startIcon={<SaveRounded />}
                                    title="Save"
                                    size="small"
                                    variant="contained"
                                    type="submit"
                                />
                                <CustomButton
                                    startIcon={<ClearRounded />}
                                    title="Cancel"
                                    size="small"
                                    variant="contained"
                                    onClick={handleClose}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 1 }} />
                    <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField
                                name="firstName"
                                type="text"
                                fieldLabel="First Name"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.firstName && Boolean(errors.firstName)}
                                helperText={touched.firstName && errors.firstName}
                                placeholder="Enter First Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField
                                name="lastName"
                                type="text"
                                fieldLabel="Last Name"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.lastName && Boolean(errors.lastName)}
                                helperText={touched.lastName && errors.lastName}
                                placeholder="Enter Last Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CustomTextField
                                name="email"
                                type="email"
                                fieldLabel=" Email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                placeholder="Enter Email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>

                        <CustomTextField
                    name="mobile"
                    type="text"         
                    fieldLabel="mobile"
                    value={values.mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter Mobile Number"
                    error={touched.mobile && !!errors.mobile}
                    helperText={touched.mobile && errors.mobile}
                  />
                  </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomTextField
                                name="password"
                                type="password"
                                fieldLabel="Password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.password && Boolean(errors.password)}
                                helperText={touched.password && errors.password}
                                placeholder="Enter Password"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomAutocomplete
                                options={companyRoles}
                                fieldLabel="User Role"
                                getOptionLabel={(option) => option.title}
                                value={companyRoles.find((v)=> v.id == values.roleId)}
                                onChange={(e, newValue) => setFieldValue("roleId", newValue.id)}
                                onBlur={handleBlur}
                                name="roleId"
                                error={touched.role && Boolean(errors.roleId)}
                                helperText={touched.roleId && errors.roleId}
                                placeholder="Select User role"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CustomAutocomplete
                                options={profile}
                                fieldLabel="Profile"
                                getOptionLabel={(option) => option.title}
                                value={profile.find((v)=> v.id == values.profileId)}
                                name="profileId"
                                onChange={(e, newValue) => setFieldValue("profileId", newValue.id)}
                                onBlur={handleBlur}
                                error={touched.profileId && Boolean(errors.profileId)}
                                helperText={touched.profileId && errors.profileId}
                                placeholder="Select Profile"
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default CreateAdmin;

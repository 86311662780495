import React, { useEffect, useState } from "react";
import axios from 'axios';
import { TextField, Button, Box, Typography, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Checkbox, Divider, Grid, CircularProgress } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CustomButton from "../common/CustomButton";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from '../common/CustomAutoComplete';
import { genericPost } from '../../services/authServices';
import { Creact_LEADS } from '../../services/apiRoutes';
import { getStates, getCities } from "../../services/CommonServices";
import CustomTextarea from "../common/CustomTextarea";
import { ClearRounded, Replay10Rounded, SaveRounded } from "@mui/icons-material";
import CustomStyle from "../common/CustomStyle";



const leadValidationSchema = Yup.object().shape({
  leadOwner: Yup.string().required('Lead Owner is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  annualRevenue: Yup.number().required('Annual Revenue is required'),
  title: Yup.string().required('Title is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
    .required('Phone is required'),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
    .required('Mobile is required'),
  leadSourceId: Yup.string().required('Lead Source ID is required'),
  leadStatusId: Yup.string().required('Lead Status ID is required'),
  industry: Yup.string().required('Industry is required'),
  numberOfEmployees: Yup.number().required('Number of Employees is required'),
  rating: Yup.string().required('Rating is required'),
  expectedDate: Yup.date().required('Expected Date is required'),
  emailOptOut: Yup.number().required('Email Opt-Out is required'),
  businessGroup: Yup.string().required('Business Group is required'),
  secondaryEmail: Yup.string().email('Invalid email'),
  street: Yup.string().required('Street is required'),
  countryId: Yup.string().required('Country ID is required'),
  stateId: Yup.string().required('State ID is required'),
  cityId: Yup.string().required('City ID is required'),
  clientCompanyId: Yup.string().required('clientCompanyId  is required'),
  zipCode: Yup.string()
    .matches(/^[0-9]{6}$/, 'Zip Code number must be exactly 6 digits')
    .required('Zip Code is required'), description: Yup.string().required('Description is required'),
});

// const LeadForm = () => {
const LeadForm = ({ handleClose, businessGroup, industryType, rating, leadSource, leadStatus, conutries, getLeadList, setMessage, getAllLead, clientCompany, leadOwner, titles }) => {

  const handleSubmit = async (values, { setSubmitting }) => {

    try {
      const response = await genericPost(Creact_LEADS, values, {
      });

      if (response.status === 201) {
        setMessage({
          open: true,
          text: response?.data?.message || "Lead added successfully",
          type: "success",
        });
        getLeadList()
        setTimeout(() => {
          handleClose()
        }, 3000)
        handleClose()
      } else {
        alert('Something went wrong');
      }
    } catch (error) {
      alert('Something went wrong');
    } finally {
      setSubmitting(false);
    }
  };

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    leadOwner: '',
    firstName: '',
    lastName: '',
    annualRevenue: '',
    title: '',
    email: '',
    phone: '',
    mobile: '',
    leadSourceId: '',
    leadStatusId: '',
    industry: '',
    numberOfEmployees: '',
    rating: '',
    expectedDate: '',
    emailOptOut: 0,
    businessGroup: '',
    secondaryEmail: '',
    street: '',
    countryId: '',
    stateId: '',
    cityId: '',
    clientCompanyId: '',
    zipCode: '',
    description: '',
  })


  const handleChangeCountry = async (newValue) => {
    const countryId = newValue ? newValue.id : '';
    if (countryId) {
      const stateData = await getStates({ id: countryId });
      setStates(stateData);
      setCities([]); // Clear cities when a new country is selected
    } else {
      setStates([]);
      setCities([]);
    }
  };

  const handleChangeState = async (newValue) => {
    const stateId = newValue ? newValue.id : '';
    if (stateId) {
      const cityData = await getCities({ id: stateId });
      setCities(cityData);
    } else {
      setCities([]);
    }
  };


  useEffect(() => {
    setFormData({
      leadOwner: '',
      firstName: getAllLead?.user?.firstName,
      lastName: '',
      annualRevenue: '',
      title: '',
      email: '',
      phone: '',
      mobile: '',
      leadSourceId: '',
      leadStatusId: '',
      industry: '',
      numberOfEmployees: '',
      rating: '',
      expectedDate: '',
      emailOptOut: 0,
      businessGroup: '',
      secondaryEmail: '',
      street: '',
      countryId: '',
      stateId: '',
      cityId: '',
      zipCode: '',
      clientCompanyId: '',
      description: '',
    })
  }, [])

  return (
    <>
      <Box sx={{ mx: 'auto', mt: 0 }}>
        <Formik
          initialValues={formData}
          validationSchema={leadValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
            <Form>
              <Grid container spacing={1} style={CustomStyle.container}>
                <Grid item xs={4} md={2}>
                  <Typography sx={{ fontWeight: 600 }} style={CustomStyle.title}>
                    Create Leads
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10} style={CustomStyle.buttonContainer}>
                  <Box style={CustomStyle.buttonBox}>
                    <CustomButton
                      startIcon={<SaveRounded />}
                      title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                      size="small"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    />
                    <CustomButton
                      startIcon={<SaveRounded />}
                      title=" Save and New"
                      size="small"
                      variant="contained"
                    />
                    <CustomButton
                      startIcon={<ClearRounded />}
                      title="Cancel"
                      size="small"
                      variant="contained"
                      onClick={handleClose}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomAutocomplete
                    options={leadOwner}
                    fieldLabel="Lead Owner"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={leadOwner.find((option) => option.id === values.leadOwner) || null}
                    onChange={(e, newValue) => setFieldValue('leadOwner', newValue ? newValue.id : '')}
                    placeholder="Select Lead Owner"
                    error={touched.leadOwner && !!errors.leadOwner}
                    helperText={touched.leadOwner && errors.leadOwner}
                  />

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomAutocomplete
                    options={clientCompany}
                    fieldLabel="Select Company"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={clientCompany.find((option) => option.id === values.clientCompanyId) || null}
                    onChange={(e, newValue) =>
                      setFieldValue('clientCompanyId', newValue ? newValue.id : '')
                    }
                    placeholder="Select Company"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomAutocomplete
                    options={titles}
                    fieldLabel="Title"
                    getOptionLabel={(option) => option.title}
                    value={titles.find((option) => option.id === values.title) || null}
                    onChange={(e, newValue) => setFieldValue('title', newValue ? newValue.id : '')}
                    placeholder="Select Title"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    name="firstName"
                    fieldLabel="First Name"
                    required={true}
                    value={values.firstName}
                    onChange={handleChange}
                    placeholder="Enter First Name"
                    error={touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    name="lastName"
                    fieldLabel="Last Name"
                    required={true}
                    value={values.lastName}
                    onChange={handleChange}
                    placeholder="Enter Last Name"
                    error={touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    name="email"
                    fieldLabel="Email"
                    required={true}
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Enter Email"
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    type="number"
                    name="phone"
                    fieldLabel="Phone"
                    required={true}
                    value={values.phone}
                    onChange={handleChange}
                    placeholder="Enter Phone"
                    error={touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    type="number"
                    name="mobile"
                    fieldLabel="Mobile"
                    required={true}
                    value={values.mobile}
                    onChange={handleChange}
                    placeholder="Enter Mobile"
                    error={touched.mobile && !!errors.mobile}
                    helperText={touched.mobile && errors.mobile}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomAutocomplete
                    options={leadSource}
                    fieldLabel="Select Source"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={leadSource.find((option) => option.id === values.leadSourceId) || null}
                    onChange={(e, newValue) =>
                      setFieldValue('leadSourceId', newValue ? newValue.id : '')
                    }
                    placeholder="Select Source"

                  />
                  {touched.leadSourceId && errors.leadSourceId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.leadSourceId}</p> : null}

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomAutocomplete
                    options={leadStatus}
                    fieldLabel="Select Lead Status"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={leadStatus.find((option) => option.id === values.leadStatusId) || null}
                    onChange={(e, newValue) =>
                      setFieldValue('leadStatusId', newValue ? newValue.id : '')
                    }
                    placeholder="Select Lead Status"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomAutocomplete
                    options={industryType}
                    fieldLabel="Select Industry"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={industryType.find((option) => option.id === values.industry) || null}
                    onChange={(e, newValue) =>
                      setFieldValue('industry', newValue ? newValue.id : '')
                    }
                    placeholder="Select Industry"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    name="numberOfEmployees"
                    fieldLabel="Number of Employees"
                    required={true}
                    type="number"
                    value={values.numberOfEmployees}
                    onChange={handleChange}
                    placeholder="Enter Number of Employees"
                    error={touched.numberOfEmployees && !!errors.numberOfEmployees}
                    helperText={touched.numberOfEmployees && errors.numberOfEmployees}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    name="annualRevenue"
                    fieldLabel="Annual Revenue"
                    required={true}
                    type="number"
                    value={values.annualRevenue}
                    onChange={handleChange}
                    placeholder="Enter Annual Revenue"
                    error={touched.annualRevenue && !!errors.annualRevenue}
                    helperText={touched.annualRevenue && errors.annualRevenue}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomAutocomplete
                    options={rating}
                    fieldLabel="Select Rating"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={rating.find((option) => option.id === values.rating) || null}
                    onChange={(e, newValue) =>
                      setFieldValue('rating', newValue ? newValue.id : '')
                    }
                    placeholder="Select Rating"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    name="expectedDate"
                    fieldLabel="Expected Date"
                    required={true}
                    type="date"
                    value={values.expectedDate}
                    onChange={handleChange}
                    placeholder="Enter Expected Date"
                    error={touched.expectedDate && !!errors.expectedDate}
                    helperText={touched.expectedDate && errors.expectedDate}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="emailOptOut"
                        checked={values.emailOptOut === 1}
                        onChange={(e) => setFieldValue('emailOptOut', e.target.checked ? 1 : 0)}
                      />
                    }
                    label="Email Opt-Out"
                    error={touched.emailOptOut && !!errors.emailOptOut}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                  <CustomAutocomplete
                    options={businessGroup}
                    fieldLabel="Business Group"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={businessGroup.find((option) => option.id === values.businessGroup) || null}
                    onChange={(e, newValue) =>
                      setFieldValue('businessGroup', newValue ? newValue.id : '')
                    }
                    placeholder="Enter Business Group"
                    error={touched.businessGroup && !!errors.businessGroup}
                    helperText={touched.businessGroup && errors.businessGroup}
                  />

                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    name="secondaryEmail"
                    fieldLabel="Secondary Email"
                    value={values.secondaryEmail}
                    onChange={handleChange}
                    placeholder="Enter Secondary Email"
                    error={touched.secondaryEmail && !!errors.secondaryEmail}
                    helperText={touched.secondaryEmail && errors.secondaryEmail}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    name="street"
                    fieldLabel="Street"
                    required={true}
                    value={values.street}
                    onChange={handleChange}
                    placeholder="Enter Street"
                    error={touched.street && !!errors.street}
                    helperText={touched.street && errors.street}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomAutocomplete
                    options={conutries}
                    fieldLabel="Country"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={conutries.find((option) => option.id === values.countryId) || null}
                    onChange={(e, newValue) => {
                      setFieldValue('countryId', newValue ? newValue.id : '');
                      handleChangeCountry(newValue);
                    }}
                    placeholder="Select Country"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                  <CustomAutocomplete
                    options={states}
                    fieldLabel="State"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={states.find((option) => option.id === values.stateId) || null}
                    onChange={(e, newValue) => {
                      setFieldValue('stateId', newValue ? newValue.id : '');
                      handleChangeState(newValue);
                    }}
                    placeholder="Select State"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomAutocomplete
                    options={cities}
                    fieldLabel="City"
                    required={true}
                    getOptionLabel={(option) => option.title}
                    value={cities.find((option) => option.id === values.cityId) || null}
                    onChange={(e, newValue) => setFieldValue('cityId', newValue ? newValue.id : '')}
                    placeholder="Select City"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <CustomTextField
                    type="number"
                    name="zipCode"
                    fieldLabel="Zip Code"
                    required={true}
                    value={values.zipCode}
                    onChange={handleChange}
                    placeholder="Enter Zip Code"
                    error={touched.zipCode && !!errors.zipCode}
                    helperText={touched.zipCode && errors.zipCode}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <CustomTextarea
                    name="description"
                    fieldLabel="Note"
                    required={true}
                    multiline
                    rows={4}
                    value={values.description}
                    onChange={handleChange}
                    placeholder="Enter Note"
                    error={touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>


      </Box>
    </>
  );
};

export default LeadForm;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DataTable from "../../components/common/DataTable";
import CustomModal from "../../components/common/CustomModal";
import { GET_CURRENCY_LIST } from "../../services/apiRoutes"
import { genericPost } from '../../services/authServices';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomLoader from "../../components/common/CustomLoader";
import MessageBox from "../../components/common/CustomMessageBox";
import { AuthContext } from "../../context/AuthContext";
import CreateManageProducts from "./CreateMasters/CreateManageProducts";
import { genericGet } from "../../services/apiServices";

const title = "Currency Master";

const CurrencyMaster = () => {
    const { token } = useContext(AuthContext)
    const [modal, setModal] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);

    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })

    const columns = useMemo(
        () => [
            { accessorKey: "id", header: "SN.", size: 50 },
            { accessorKey: "currency", header: "Currency", size: 50 },
            { accessorKey: "name", header: "Country ", size: 50 },
            { accessorKey: "currency_name", header: "Currency Name", size: 50 },
            { accessorKey: "currency_symbol", header: "Currency Symbol", size: 50 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <>
                        <IconButton
                        >
                            <VisibilityIcon style={{ color: "#059212" }} />
                        </IconButton>
                        <IconButton
                        >
                            <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                        <IconButton
                        >
                            <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                        </IconButton>
                    </>

                ),
            },

        ],
        []
    );


    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await genericGet({ url: GET_CURRENCY_LIST });
            if (response.data) {
                const CustumData = response.data.currencyList.map((data, index) => ({
                    ...data,

                }));
                setData(CustumData);
                setLoading(false)

            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error fetching ", error);
        }
    };




    useEffect(() => {
        fetchData()
    }, [token])

    const handleOpen = () => {
        setModal(true);
    };
    const handleClose = () => {
        setModal(false)
    }

    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <CustomLoader open={loading} />
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                    handleOpen={handleOpen}
                    buttonTitle="Create Currency Master"
                />
                <CustomModal open={modal} handleClose={handleClose}>
                    <CreateManageProducts
                        handleClose={handleClose}
                        fetchData={fetchData}
                        setMessage={setMessage}

                    />
                </CustomModal>

                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />

            </Box>
        </>
    );
};
export default CurrencyMaster;


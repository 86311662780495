
import { ExportMenu, } from '../../../constants/DetailsPage'
import CreateExpenseVoucher from "../CreateForm/CreateExpenseVoucher";
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CustomModal from '../../../components/common/CustomModal';
import { LIST_EXPENSE_VOUCHERS } from '../../../services/apiRoutes';
import { Main } from '../../../constants/DetailsMain';
import { genericPost } from '../../../services/authServices';
import MessageBox from '../../common/CustomMessageBox';
import { getCurrencyList, getExpenseLedgerList, getCommanExportShipmentId } from '../../../services/CommonServices';
import UpdateExpenseVoucher from '../UpdateForm/UpdateExpenseVoucher';
import ExpenseVoucherView from '../ViewDetails/ExpenseVoucherView';
import CustomLoader from '../../common/CustomLoader';
import { AuthContext } from '../../../context/AuthContext';

export default function ExpenseVoucher() {
    const { token } = useContext(AuthContext)
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Expense');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const [expenseLedger, setExpenseLedger] = useState([]);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [expenseVoucherId, setExpenseVoucherId] = useState('');
    const [viewModal, setViewModal] = useState(false);
    const navigate = useNavigate();
    const { exportShipmentId } = useParams();
    const { shipmentDetails, loading: shipmentLoading } = useExportShipmentDetails(exportShipmentId);
    const [newShippingId, setNewShippingId] = useState(null);
    const customerId = useRef(null);
    const shipmentId = useRef(null);
    const cifFob = useRef(null);
    const currencyId = useRef(null);
    const baseCurrencyId = useRef(null);
    const currencySymbol = useRef(null);
    const baseCurrencySymbol = useRef(null);

    const hasFetched = useRef(false);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const payload = {
                clientCompanyId: customerId.current,
                shipmentId: shipmentId.current,
                commanShipmentId: getCommanExportShipmentId,

            };
            const response = await genericPost(LIST_EXPENSE_VOUCHERS, payload);
            if (response.data.success && response.data.expenseVoucher) {
                const expenseVoucherData = response.data.expenseVoucher.map((voucher, index) => ({
                    sn: voucher.expenseVoucherId || '',
                    index: (index + 1).toString().padStart(2, '0'),
                    date: voucher.date || '',
                    voucherNo: voucher.voucherNo || '',
                    ledgerName: voucher.expenseLedger?.ledgerName || '',
                    currency: voucher.currency?.currency || '',
                    baseCurrency: voucher.baseCurrency?.currency || '',
                    amount1: `${voucher?.currency?.currency_symbol || ''} ${voucher.amount || ''}`,
                    conversionRate: `${voucher?.baseCurrency?.currency_symbol || ''} ${voucher.conversionRate || ''}`,
                    amount2: `${voucher?.baseCurrency?.currency_symbol || ''} ${voucher.amountInBaseCurrency || ''}`,
                }));
                const { newShippingId } = response.data;
                setNewShippingId(newShippingId);
                setData(expenseVoucherData);
            } else {
                setError('No expense vouchers found');
            }
        } catch (error) {
            setError('Error fetching expense vouchers');
            console.error('Error fetching expense vouchers', error);
        } finally {
            setLoading(false);
        }
    }, []);

    const getCommonData = useCallback(async () => {
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
        const expenseLedger = await getExpenseLedgerList();
        setExpenseLedger(expenseLedger);
    }, []);

    useEffect(() => {
        if (shipmentDetails && !hasFetched.current) {
            customerId.current = shipmentDetails.customerId;
            shipmentId.current = shipmentDetails.shipmentId;
            cifFob.current = shipmentDetails.cifFob.cifFobName;
            currencyId.current = shipmentDetails.currencyId;
            baseCurrencyId.current = shipmentDetails?.company?.baseCurrencyData?.id ?? '';
            currencySymbol.current = shipmentDetails?.currency?.currency_symbol ?? '';
            baseCurrencySymbol.current = shipmentDetails?.company?.baseCurrencyData?.currency_symbol ?? '';
            fetchData();
            getCommonData();
            hasFetched.current = true;
        }
    }, [shipmentDetails, fetchData, token, getCommonData]);


    const handleUpdateClick = (id) => {
        setExpenseVoucherId(id);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setExpenseVoucherId('');
    };

    const handleViewClick = (id) => {
        setExpenseVoucherId(id);
        setViewModal(true);
    };

    const handleCloseView = () => {
        setViewModal(false);
        setExpenseVoucherId('');
    };


    const handleBackClick = () => {
        navigate("/app/export/shipement");
    };

    const expenseVoucherColumns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        {
            id: 'date', label: 'Date', accessor: 'date', styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            id: 'voucherNo', label: 'Voucher No.', accessor: 'voucherNo', styleAccessor: {
                textAlign: 'center',
            },
        },
        { id: 'ledgerName', label: 'Expense Ledger', accessor: 'ledgerName' },
        { id: 'currency', label: 'Currency', accessor: 'currency' },
        {
            id: 'amount1', label: 'Amount', accessor: 'amount1', styleAccessor: {
                textAlign: 'right',
            },
        },
        { id: 'baseCurrency', label: 'Base Currency', accessor: 'baseCurrency' },
        {
            id: 'conversionRate', label: 'conversion Rate', accessor: 'conversionRate', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'amount2', label: 'Amount in Base Currency', accessor: 'amount2', styleAccessor: {
                textAlign: 'right',
            },
        },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.sn)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.sn)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.sn)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <div>
            {/* <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={ExportMenu(exportShipmentId)}
                companyName={shipmentDetails?.customer.clientCompanyName || ''}
                phoneNumber={shipmentDetails?.customer.mobile || ''}
                initial={shipmentDetails?.customer.clientCompanyName?.charAt(0).toUpperCase() || ''}
                onBackClick={handleBackClick}
            />

            <Main open={open}> */}

            <CustomLoader open={loading} />
            <DetailsPageCard
                columns={expenseVoucherColumns}
                title={'Expense Voucher'}
                buttonTitle={'Create Expense'}
                handleOpen={handleOpen}
                data={data}
                loading={loading}
                error={error}
            />
            <CustomModal open={modal} handleClose={handleClose}>
                <CreateExpenseVoucher
                    fetchData={fetchData}
                    commanShipmentId={getCommanExportShipmentId}
                    setMessage={setMessage}
                    handleClose={handleClose}
                    currencyList={currencyList}
                    expenseLedger={expenseLedger}
                    newShippingId={newShippingId}
                    shipmentId={shipmentId.current}
                    clientCompanyId={customerId.current}
                    currencyId={currencyId.current}
                    baseCurrencyId={baseCurrencyId.current}
                    currencySymbol={currencySymbol.current}
                    baseCurrencySymbol={baseCurrencySymbol.current}
                />
            </CustomModal>
            <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                <UpdateExpenseVoucher
                    fetchData={fetchData}
                    setMessage={setMessage}
                    handleClose={handleCloseUpdate}
                    ExpenseVoucherId={expenseVoucherId}
                    currencyList={currencyList}
                    expenseLedger={expenseLedger}
                />
            </CustomModal>

            <CustomModal
                modalStyles={{
                    maxHeight: '50vh',
                    top: "10%",
                }}
                open={viewModal} handleClose={handleCloseView}>
                <ExpenseVoucherView
                    fetchData={fetchData}
                    setMessage={setMessage}
                    handleClose={handleCloseView}
                    ExpenseVoucherId={expenseVoucherId}
                    currencyList={currencyList}
                    expenseLedger={expenseLedger}
                />
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            {/* </Main> */}
        </div>
    );
}

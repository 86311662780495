import React, { useContext, useEffect, useState } from "react";
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { AppBarCompany, } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MessageBox from "../../common/CustomMessageBox";
import CustomDelete from '../../../components/common/CustomDeleteAlert';
import axios from "axios";
import CustomModal from "../../../components/common/CustomModal";
import { getCIFFOBList, getCurrencyList, getShipmentTypes, getSuppliersList } from '../../../services/CommonServices';
import { genericGet } from "../../../services/apiServices";
import { Box, IconButton } from "@mui/material";
import { DELETE_EXPORT_SHIPMENT, LIST_ALL_EXPORT_SHIPMENT } from "../../../services/apiRoutes";
import Createexportshipement from "../../exportshipement/createexportshipement";
import UpdateExportshipement from "../../exportshipement/UpdateExportshipement";
import { useNavigate, useParams } from "react-router-dom";
import { useClientCompanyDetails } from "./UseClientCompanyDetails";
import { genericPost } from "../../../services/authServices";
import { AuthContext } from "../../../context/AuthContext";
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import CustomLoader from "../../common/CustomLoader";

export default function ExportMenu() {
    const { token } = useContext(AuthContext)
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const { clientCompanyId } = useParams();
    const { companyDetail, loading: loadingClient } = useClientCompanyDetails(clientCompanyId);
    const [open, setOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('Export Shipment');
    const [leadModal, setLeadModal] = useState(false);
    const [exportShipments, setExportShipments] = useState([]);
    const [cifFobList, setCIFFOBList] = useState([]);
    const [shipmentTypes, setShipmentTypes] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [rowId, setRowId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [newShippingId, setNewShippingId] = useState(null);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });

    useEffect(() => {
        fetchExportShipments();
        getCommonData();
    }, [token]);

    const handleUpdateClick = (exportShipmentId) => {
        setSelectedId(exportShipmentId);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };
    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };
    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true);
        setRowId(rowId);
    };

    const handleDelete = async (exportShipmentId) => {
        setLoading(true);
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(DELETE_EXPORT_SHIPMENT(exportShipmentId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                fetchExportShipments();
                setDeleteAlert(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error deleting export shipment", error);
        }
    };

    const fetchExportShipments = async () => {
        setLoading(true);
        try {
            // const response = await genericGet({ url: LIST_ALL_EXPORT_SHIPMENT });
            const payload = {
                customerId: clientCompanyId,
            };
            const response = await genericPost(LIST_ALL_EXPORT_SHIPMENT, payload, {});

            if (response.data.success && response.data.shipments) {
                const { newShippingId } = response.data;

                const shipmentData = response.data.shipments.map((shipment, index) => ({
                    index: (index + 1).toString().padStart(2, '0'),
                    exportShipmentId: shipment.exportShipmentId || '',
                    expectedEtd: shipment.expectedEtd || '',
                    shipmentId: shipment.shipmentId || '',
                    customerName: shipment.customer.clientCompanyName || '',
                    mailingCountry: shipment.customer.mailingCountry.name || '',
                    currency: shipment.currency.currency || '',
                    noOfContainer: shipment.noOfContainers || '',
                    cifFob: shipment.cifFob.cifFobName || '',
                }));
                setExportShipments(shipmentData);
                setNewShippingId(newShippingId);

            } else {
                setError('No export shipments found');
            }
        } catch (error) {
            setError('Error fetching export shipments');
            console.error('Error fetching export shipments', error);
        } finally {
            setLoading(false);
        }
    };

    const getCommonData = async () => {
        const cifFobListData = await getCIFFOBList();
        setCIFFOBList(cifFobListData);
        const shipmentTypesData = await getShipmentTypes();
        setShipmentTypes(shipmentTypesData);
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
    }

    const exportShipmentColumns = [
        {
            accessor: 'index', label: 'SN', id: 'index', styleAccessor: {
                textAlign: 'center', maxWidth: '200px', width: "50px"
            },
        },
        {
            accessor: "expectedEtd", label: "Date", id: "expectedEtd", styleAccessor: {
                textAlign: 'center',
            },
        },
        {
            accessor: "shipmentId", label: "Shipment ID", id: "shipmentId", styleAccessor: {
                textAlign: 'center',
            },
        },
        { accessor: "customerName", label: "Customer Name", id: "customerName" },
        { accessor: "mailingCountry", label: "Country", id: "mailingCountry" },
        {
            accessor: "noOfContainer", label: "No. Of Containers", id: "noOfContainer", styleAccessor: {
                textAlign: 'center',
            },
        },
        { accessor: "currency", label: "Currency", id: "currency" },
        { accessor: "cifFob", label: "CIF/FOB", id: "cifFob" },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1px' }}>
                    <IconButton onClick={() => handleViewClick(row.exportShipmentId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: "#059212" }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.exportShipmentId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.exportShipmentId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                    </IconButton>
                </div>
            ),
        },
    ];
    const handleViewClick = (exportShipmentId) => {
        // navigate(`/app/export/shipement/${exportShipmentId}`);
        navigate(`/app/export/shipementview/${exportShipmentId}`);
    };

    if (!companyDetail) {
        return <Box><CustomLoader open={loadingClient} /> </Box>;
    }



    const custumersList = [
        {
            id: Number(clientCompanyId),
            title: companyDetail?.clientCompanyName || 'N/A',
        },
    ];
    const companyName = companyDetail.clientCompanyName;
    const phoneNumber = companyDetail.mobile;
    const initial = companyDetail.clientCompanyName ? companyDetail.clientCompanyName.charAt(0).toUpperCase() : '';
    const handleBackClick = () => {
        navigate("/app/company");
    };

    return (
        <>
            <div>
                {/* <DetailsPageAppbar
                    handleScrollTo={setActiveTab}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={() => setOpen(true)}
                    handleDrawerClose={() => setOpen(false)}
                    AppBarMenu={AppBarCompany(clientCompanyId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}
                />
                <Main open={open}> */}

                <DetailsPageCard
                    columns={exportShipmentColumns}
                    Icon={RocketLaunchOutlinedIcon}
                    title={'Export Shipment'}
                    buttonTitle={'Create Export Shipment'}
                    handleOpen={handleOpen}
                    data={exportShipments}
                />

                <CustomModal open={modal} handleClose={handleClose}>
                    <Createexportshipement
                        handleClose={handleClose}
                        fetchExportShipments={fetchExportShipments}
                        setMessage={setMessage}
                        cifFobList={cifFobList}
                        shipmentTypes={shipmentTypes}
                        currencyList={currencyList}
                        custumersList={custumersList}
                        newShippingId={newShippingId}
                    />
                </CustomModal>

                <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateExportshipement
                        handleClose={handleCloseUpdate}
                        exportShipmentId={selectedId}
                        fetchExportShipments={fetchExportShipments}
                        setMessage={setMessage}
                        cifFobList={cifFobList}
                        shipmentTypes={shipmentTypes}
                        currencyList={currencyList}
                        custumersList={custumersList}

                    />
                </CustomModal>

                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />

                <CustomDelete
                    handleDelete={handleDelete}
                    open={deleteAlert}
                    rowId={rowId}
                    handleClose={() => setDeleteAlert(false)}
                />
                {/* </Main> */}
            </div>
        </>
    );
}
